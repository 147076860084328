import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { dateFormat } from '../../../const/dateFormat';

import { AdminListDiv } from '../../../components/admin';
import { BlackBtn } from '../../../components/common/BlackBtn';

const APayment = () => {
    const navigate = useNavigate();

    const [data, setData] = useState(Array);
    const [page, setPage] = useState(0);

    useEffect(() => {
        data.length === 0 &&
            axios
                .get(`/api/order?page=${page}&size=10`)
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto.content);
                        console.log(res.data.dto);
                    }
                })
                .catch((e) => alert('다시 시도해주세요.\n' + e));
    }, []);

    return (
        <AdminListDiv>
            <h5>주문 리스트</h5>
            <div className="right-btn">
                <BlackBtn
                    type="button"
                    onClick={() => window.open('https://start.nicepay.co.kr/merchant/dashboard/main.do')}
                >
                    <p>매출내역 확인</p>
                </BlackBtn>
            </div>
            <div className="admin-payment">
                <p>주문번호</p>
                <p>주문자</p>
                <p>결제금액</p>
                <p>결제방법</p>
                <p>요청사항</p>
                <p>운송장번호</p>
            </div>
            {data.length > 0 &&
                data.map((v, i) => (
                    <div
                        key={i}
                        className={`admin-payment ${v.payments[0].cancled === 'Y' ? 'red' : ''}`}
                        onClick={() => navigate('/admin/payment/' + v.id)}
                    >
                        <p>{v.payments[0].paymentId}</p>
                        <p>{v.recipient}</p>
                        <p>{v.payments[0].price}</p>
                        <p>{v.payments[0].method}</p>
                        <p>{v.request}</p>
                        <p>{v.trackingNumber}</p>
                    </div>
                ))}
        </AdminListDiv>
    );
};

export default APayment;
