import React, { useEffect, useState } from 'react';
import { AdminListDiv } from '../../../components/admin';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ABanners = () => {
    const navigate = useNavigate();

    const [data, setData] = useState(Array);

    useEffect(() => {
        data.length === 0 &&
            axios
                .get('/api/banner')
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto);
                    }
                })
                .catch((e) => alert('다시 시도해주세요.\n' + e));
    }, []);
    return (
        <AdminListDiv>
            <h5>배너 리스트</h5>

            <div className="admin-banner">
                <p>번호</p>
                <p>이미지</p>
                <p>텍스트1</p>
                <p>텍스트2</p>
            </div>
            {data.length > 0 &&
                data.map((v, i) => (
                    <div key={i} className="admin-banner" onClick={() => navigate('/admin/banners/' + v.id)}>
                        <p>{v.id}</p>
                        <p>{v.img}</p>
                        <p>{v.txt1}</p>
                        <p>{v.txt2}</p>
                    </div>
                ))}
        </AdminListDiv>
    );
};

export default ABanners;
