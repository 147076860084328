import React from "react";

import styled from "styled-components";
import { CommonBtn } from "../payment/ShipInfo";

const Popup = ({ open, set }) => {
  return (
    <PopupWrap>
      <div
        className={open ? "popup-bg opened" : "popup-bg closed"}
        onClick={() => set(false)}
      ></div>
      <div className={open ? "popup opened" : "popup closed"}>
        <img alt="popup" src="/images/event.png" />
        <div className="popup-text">
          <h5>블랙 진 파우치 선물포장 혜택</h5>
          <p>
            온라인 구매 고객님들께 블랙 진 파우치
            <br />
            선물 포장 서비스를 제공하고 있습니다.
            <br />
            고객님을 위한 특별한 서비스를 경험해보세요.
          </p>
          <div className="blank"></div>
          <CommonBtn
            property="var(--main-color)"
            color="white"
            onClick={() => set(false)}
          >
            확인했습니다.
          </CommonBtn>
        </div>
      </div>
    </PopupWrap>
  );
};

export default Popup;

const PopupWrap = styled.div`
  .popup-bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;

    &.opened {
      display: grid;
    }
    &.closed {
      display: none;
    }
  }
  .popup {
    position: fixed;
    top: -100%;
    left: calc(50vw - 275px);
    z-index: 51;

    width: 550px;
    height: fit-content;
    background-color: white;

    text-align: center;

    // border-radius: 30px;
    // overflow: hidden;

    img {
      position: relative;
      width: 100%;
      height: 360px;
      object-fit: cover;
    }

    transition: all 1s ease;

    &.opened {
      top: 85px;
    }
    &.closed {
      top: -100%;
    }

    .popup-text {
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 40px;
      padding-bottom: 30px;
      padding-left: 40px;
      padding-right: 40px;

      h5 {
        font-size: 18pt;
        margin-bottom: 20px;
      }

      p {
        font-weight: 500;
        line-height: 1.3rem;
        font-size: var(--font-size-sm);
      }

      .blank {
        height: 40px;
      }
    }

    @media screen and (max-width: 700px) {
      width: 100%;
      top: 0;
      left: 0;
      right: 0;

      &.opened {
        top: 0;
      }

      .popup-text {
        padding-top: 40px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;

        h5 {
          font-size: 15pt;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }

      img {
        height: auto;
      }
    }
  }
`;
