import styled from 'styled-components';

const ShipInfo = () => {
    return;
};

export default ShipInfo;

export const PaymentWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 406px;

    @media screen and (max-width: 700px) {
        .title {
            font-weight: 600;
            font-size: 14pt;
        }
    }
`;

export const MainInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    padding: 40px 0;

    .mobile-price {
        display: none;
    }

    select {
        position: relative;
        width: 100%;
        height: 45px;

        padding: 0 10px;
    }

    @media screen and (max-width: 700px) {
        padding-bottom: 30px;

        .mobile-price {
            display: grid;
            gap: 10px;
            margin-bottom: 20px;
            padding: 20px 0;
            border-top: 1px solid var(--light-color);
            border-bottom: 1px solid var(--light-color);

            small {
                font-weight: 500;
            }
        }
    }
`;

export const InputWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    margin-bottom: 20px;

    label {
        font-size: var(--font-size-xs);
        font-weight: 500;
        margin-bottom: 10px;
    }

    .empty {
        color: var(--light-color);
    }

    @media screen and (max-width: 700px) {
        label {
            margin-bottom: 5px;
        }
    }
`;

export const TitleFlex = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    button {
        font-size: var(--font-size-xs);
        font-weight: 500;
        text-decoration: underline;
    }
`;

export const CommonBtn = styled.button`
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 14px 0;
    text-align: center;
    background-color: ${(props) => props.property};
    color: ${(props) => props.color};
    border: 1px solid ${(props) => props.border};
    border-radius: 3px;

    font-size: var(--font-size-sm);

    font-weight: 600;
`;

export const FlexButton = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    margin-bottom: 20px;
`;

export const PaymentInput = styled.input`
    position: relative;
    width: 100%;
    height: 45px;

    padding: 0 10px;

    border: 1px solid var(--main-color);
    font-weight: 500;

    background-color: ${(props) => props.backgroundcolor};
`;

export const PaymentSelect = styled.select`
    position: relative;
    width: 100%;
    height: 45px;
    border: 1px solid var(--main-color);
    padding: 0 10px;

    font-weight: 500;

    option {
        font-weight: 500;
        border-radius: 0px;
        color: var(--main-color);
    }
`;

export const CheckBox = styled.input`
    position: relative;
    width: 12px;
    height: 12px;
`;

export const CheckBoxAndLabel = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: flex;
    gap: 5px;

    font-size: var(--font-size-xs);
    font-weight: 500;

    // p {
    //   font-size: var(--font-size-xs);
    //   font-weight: 500;
    // }
`;

export const RadioInput = styled.input`
    position: relative;
    width: 14px;
    height: 14px;
    vertical-align: middle;

    appearance: none;

    transition: border 0.2s ease-in-out;
    border: 1px solid (--main-color);
    border-radius: 50%;
`;

export const RadioWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    padding: 13px;
    border: 1px solid var(--main-color);
    border-radius: 3px;

    display: flex;
    align-items: center;
    gap: 10px;

    label {
        postition: relative;
        width: 100%;
        font-size: var(--font-size-sm);
        margin: 0;

        cursor: pointer;
    }

    input[type='radio']:checked {
        background-color: var(--main-color);
        border: 3px solid white;
        box-shadow: 0 0 0 1px var(--main-color);
    }
`;

export const RadioInner = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    padding: 30px 0;

    display: grid;
    gap: 10px;

    @media screen and (max-width: 700px) {
        padding-top: 10px;
    }
`;

export const AddressGrid = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;

    margin-bottom: 10px;
`;
