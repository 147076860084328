import React, { useEffect, useState } from 'react';

import deliveryReqList from '../../../../../const/deliveryReq';

import { FlexDiv } from '../../../../../components/cart/Cart';
import { FreeDiv } from '../../../../../components/cart/CartPrice';
import { ContetnsWrap, FlexWrap, TitleWrap } from '../../../../../components/payment/Complete';
import {
    BetweenFlex,
    Border,
    MainContents,
    MypageOrderGoods,
    MypageOrderInfo,
    MypagePaymentInfo,
    MypagePriceInfo,
} from '../../../../../components/user/UserComponent';
import formPrice from '../../../../../const/formPrice';
import {
    AddressGrid,
    CommonBtn,
    PaymentInput,
    PaymentSelect,
    TitleFlex,
} from '../../../../../components/payment/ShipInfo';
import { NoticeWrap } from '../../../../../components/payment/BottomNotice';
import { InputWrap } from '../../../../../components/payment/OrderInfo';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const MyPageOrderDetail = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [name, setName] = useState('김대원');
    const [addressDetail, setAddressDetail] = useState('701');
    const [deliveryReq, setDeliveryReq] = useState('없음');

    const [data, setData] = useState('');

    const [changeShipInfo, setChangeShipInfo] = useState(false);

    useEffect(() => {
        !data &&
            params.orderId &&
            axios
                .get('/api/order/one/' + params.orderId)
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto);
                    } else {
                        alert('잘못된 경로입니다.');
                    }
                })
                .catch((e) => console.log(e));
    }, [data]);

    return (
        <div>
            <p>주문내역/배송현황 </p>
            {data && (
                <MainContents>
                    <BetweenFlex>
                        <small>주문번호</small>
                        <small>{data.payments[0].paymentId}</small>
                    </BetweenFlex>
                    <Border />
                    {data.goods.map((v, i) => (
                        <MypageOrderGoods key={i}>
                            <div className="img">
                                <img alt="thumb" src={'https://www.competoseoul.com' + v.thumb_src} />
                            </div>
                            <div className="text">
                                <p style={{ fontWeight: '500' }}>{v.goodsName}</p>
                                <p>
                                    ₩ {v.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ㅣ 수량{' '}
                                    {data.orderItems[i].amount}개
                                </p>
                                <p style={{ position: 'absolute', bottom: '0' }}>
                                    {data.oStatus === '0' ? '상품준비중' : data.oStatus === '1' ? '배송중' : '배송완료'}
                                </p>
                                {data.oStatus === '2' && (
                                    <button
                                        type="button"
                                        className="post-review"
                                        onClick={() => navigate('/mypage/review/' + data.id + '/' + v.id)}
                                    >
                                        <p>리뷰등록</p>
                                    </button>
                                )}
                            </div>
                        </MypageOrderGoods>
                    ))}
                    <Border />
                    <MypageOrderInfo>
                        <BetweenFlex className="title">
                            <p>배송 정보</p>
                            {/* {data.oStatus === '0' && (
                                <button onClick={() => setChangeShipInfo(true)} className="lineBtn">
                                    변경하기
                                </button>
                            )} */}
                        </BetweenFlex>
                        {changeShipInfo ? (
                            <form>
                                <InputWrap>
                                    <TitleFlex>
                                        <label htmlFor="name">
                                            <p>수령인</p>
                                        </label>
                                    </TitleFlex>
                                    <PaymentInput
                                        id="name"
                                        type={'text'}
                                        value={name}
                                        placeholder="이름"
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </InputWrap>
                                <div style={{ height: '20px' }} />
                                <InputWrap>
                                    <TitleFlex>
                                        <label htmlFor="phone">
                                            <p>휴대폰 번호</p>
                                        </label>
                                    </TitleFlex>
                                    <PaymentInput
                                        id="phone"
                                        type={'text'}
                                        value={'010-5606-9803'}
                                        style={{
                                            backgroundColor: '#EBEBEB',
                                            color: 'var(--main-color)',
                                        }}
                                        disabled
                                    />
                                </InputWrap>
                                <div style={{ height: '20px' }} />
                                <InputWrap>
                                    <TitleFlex>
                                        <label htmlFor="addressDetail">
                                            <p>배송지</p>
                                        </label>
                                    </TitleFlex>
                                    <AddressGrid>
                                        <PaymentInput
                                            id="address"
                                            type={'text'}
                                            value={'서울 강서구 화곡로68길 116'}
                                            style={{
                                                backgroundColor: '#EBEBEB',
                                                color: 'var(--main-color)',
                                            }}
                                            disabled={true}
                                        />
                                        <CommonBtn type="button" property="var(--main-color)" color="white" disabled>
                                            주소 찾기
                                        </CommonBtn>
                                    </AddressGrid>
                                    <PaymentInput
                                        id="addressDetail"
                                        type={'text'}
                                        value={addressDetail}
                                        placeholder="상세주소"
                                        onChange={(e) => setAddressDetail(e.target.value)}
                                    />
                                </InputWrap>
                                <div style={{ height: '20px' }} />
                                <InputWrap>
                                    <TitleFlex>
                                        <label htmlFor="deliveryReq">
                                            <p>배송 요청사항</p>
                                        </label>
                                    </TitleFlex>
                                    <PaymentSelect onChange={(e) => setDeliveryReq(e.target.value)}>
                                        {deliveryReqList.map((v, i) => (
                                            <option value={v.value}>{v.placeholder}</option>
                                        ))}
                                    </PaymentSelect>
                                    {/* {deliveryReq === "직접입력" && (
                  <PaymentInput
                    type={"text"}
                    value={writeReq}
                    style={{ marginTop: "10px" }}
                    placeholder="요청사항을 입력해주세요."
                    onChange={(e) => setWriteReq(e.target.value)}
                  />
                )} */}
                                </InputWrap>
                            </form>
                        ) : (
                            <FlexWrap className="normal">
                                <TitleWrap>
                                    <li>
                                        <p>수령인</p>
                                    </li>
                                    <li>
                                        <p>휴대폰&nbsp;번호</p>
                                    </li>
                                    <li>
                                        <p>배송지</p>
                                    </li>
                                    <li></li>
                                    <li>
                                        <p>배송&nbsp;요청사항</p>
                                    </li>
                                </TitleWrap>
                                <ContetnsWrap>
                                    <li>
                                        <p>{data.recipient}</p>
                                    </li>
                                    <li>
                                        <p>{data.phone}</p>
                                    </li>
                                    <li>
                                        <p>{data.address}</p>
                                    </li>
                                    <li>
                                        <p>{data.adrDetail}</p>
                                    </li>
                                    <li>
                                        <p>{data.request}</p>
                                    </li>
                                </ContetnsWrap>
                            </FlexWrap>
                        )}
                    </MypageOrderInfo>
                    <Border />
                    <MypagePaymentInfo>
                        <p className="title">결제 정보</p>
                        <FlexWrap className="normal">
                            <TitleWrap>
                                <li>
                                    <p>결제수단</p>
                                </li>
                            </TitleWrap>
                            <ContetnsWrap>
                                <li>
                                    <p>
                                        {data.payments[0].method === 'card'
                                            ? '신용카드'
                                            : data.payments[0].method === 'naverpay'
                                            ? '네이버페이'
                                            : '카카오페이'}
                                    </p>
                                </li>
                            </ContetnsWrap>
                        </FlexWrap>
                    </MypagePaymentInfo>
                    <Border />
                    <MypagePriceInfo>
                        <FlexDiv>
                            <small>주문금액</small>
                            <small>
                                ₩&nbsp;
                                {data.payments[0].price > 30000
                                    ? formPrice(data.payments[0].price)
                                    : formPrice(data.payments[0].price - 3000)}
                            </small>
                        </FlexDiv>
                        <div style={{ height: '10px' }} />
                        <FlexDiv>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <small>배송비</small>
                                <FreeDiv>3만 원 이상 구매 시 무료</FreeDiv>
                            </div>
                            <small>
                                ₩&nbsp;
                                {data.payments[0].price > 30000 ? 0 : formPrice(3000)}
                            </small>
                        </FlexDiv>
                        <div style={{ height: '10px' }} />
                        <FlexDiv>
                            <p style={{ fontSize: 'var(--font-size-p)' }}>총 주문금액</p>
                            <p>₩&nbsp;{formPrice(data.payments[0].price) || 0}</p>
                        </FlexDiv>
                    </MypagePriceInfo>
                    <div style={{ height: '40px' }} />
                    {/* <CommonBtn>주문&nbsp;취소하기</CommonBtn> */}
                    <div style={{ height: '20px' }} />
                    <NoticeWrap>
                        <ul>
                            <li>
                                ·&nbsp;
                                <p>
                                    상품 준비 단계에서는 배송을 위한 출고 작업이 시작되므로, 주문 취소 및 주문
                                    정보(상품, 옵션, 배 송지) 변경이 불가합니다.
                                </p>
                            </li>
                            <li>
                                ·&nbsp;
                                <p>
                                    반품(환불) 및 교환은 수령일로부터 7일 이내 온라인 공식 홈페이지를 통해서 접수
                                    가능합니다.
                                </p>
                            </li>
                            <li>
                                ·&nbsp;
                                <p>
                                    단순 변심 또는 주문 실수로 인한 교환은 불가합니다. 반품 후, 원하시는 상품으로 재
                                    구매를 요청드 립니다. (단, 제품 하자 시 동일 상품으로 교환 가능)
                                </p>
                            </li>
                        </ul>
                    </NoticeWrap>
                </MainContents>
            )}
        </div>
    );
};

export default MyPageOrderDetail;
