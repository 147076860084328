import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

const SubCard = ({ link, img, title, price }) => {
    return (
        <Link to={`${link}`} style={{ position: 'relative', width: '100%', maxWidth: '100vw' }}>
            <CardDiv>
                <div className="img-wrap">
                    <img alt="icon" src={process.env.REACT_APP_DOMAIN + img} />
                </div>
                <div className="title-div">
                    <p style={{ fontWeight: '500' }}>{title}</p>
                </div>
                <p>₩ {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
            </CardDiv>
        </Link>
    );
};

export default SubCard;

export const CardDiv = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 60px;

    .img-wrap {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 128%;
        margin-bottom: 20px;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        left: 0;
        top: 0;
    }

    .title-div {
        position: relative;
        width: 95%;
        height: fit-content;
        overflow: hidden;
        display: grid;
    }

    p {
        display: block;
        font-size: var(--font-size-sm);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (max-width: 700px) {
        display: ${(props) => (props.display ? 'none' : 'block')};

        p {
            font-size: var(--font-size-p);
            line-height: 1.5em;
        }
    }
`;
