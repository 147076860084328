import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAdmin } from '../../../store/AdminSlice';
import { LoginWrapper } from '../../../components/login/LoginInput';
import axios from 'axios';
import LoginLogo from '../../../components/login/LoginLogo';
import { Input } from '../../../components/common/Input';
import { BlackBtn } from '../../../components/common/BlackBtn';
import { useNavigate } from 'react-router-dom';

const ALogin = () => {
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const adminData = useSelector((state) => state.admin);

    const [email, setemail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        adminData.id > 0 && navigator('/admin/member');
    }, []);

    const onLogin = async () => {
        console.log(email, password);

        try {
            await axios
                .post('/api/admin/login', {
                    email: email,
                    password: password,
                })
                .then((res) => {
                    console.log(res.data);

                    dispatch(
                        setAdmin({
                            id: res.data.id,
                            email: res.data.email,
                            adminName: res.data.name,
                        })
                    );
                })
                .then(() => (window.location.href = '/admin/member'))
                .catch((err) => console.log(err));
        } catch (error) {}
    };

    return (
        <LoginWrapper>
            <LoginLogo />
            <Input type="text" value={email} onChange={(e) => setemail(e.target.value)} placeholder="아이디(이메일)" />
            <div style={{ height: '10px' }}></div>
            <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="비밀번호"
            />
            <div style={{ height: '10px' }}></div>
            <div className="mobile-hidden" style={{ height: '20px' }}></div>
            <BlackBtn type="button" onClick={onLogin}>
                <p>관리자 로그인</p>
            </BlackBtn>
        </LoginWrapper>
    );
};

export default ALogin;
