const deliveryReqList = [
  {
    value: "없음",
    placeholder: "배송 시 요청사항을 선택해주세요",
  },
  {
    value: "부재시 경비실에 맡겨주세요",
    placeholder: "부재시 경비실에 맡겨주세요",
  },
  {
    value: "집앞에 놔주세요",
    placeholder: "집앞에 놔주세요",
  },
  {
    value: "택배함에 놔주세요",
    placeholder: "택배함에 놔주세요",
  },
  {
    value: "배송전에 꼭 연락주세요",
    placeholder: "배송전에 꼭 연락주세요",
  },
  {
    value: "직접입력",
    placeholder: "직접입력",
  },
];

export default deliveryReqList;
