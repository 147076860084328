import React from 'react';

import LoginButton from '../../../components/login/LoginButton';
import { LoginWrapper, SignupText } from '../../../components/login/LoginInput';

const Complete = () => {
    return (
        <LoginWrapper>
            <p>회원가입 완료</p>
            <SignupText>
                <small>
                    안녕하세요 {sessionStorage.getItem('name')}님.
                    <br />
                    우리가 사랑한 블랙, 콤페토에 함께해주셔서 감사합니다.
                </small>
                <br />
                <br />
                <small>콤페토 회원만을 위한 온라인 혜택을 경험해보세요.</small>
            </SignupText>
            <LoginButton type="shopping" />
        </LoginWrapper>
    );
};

export default Complete;
