const categoryName = (categoryId) => {
    let name = '';
    switch (categoryId) {
        case 1:
            name = '가방';

            break;
        case 2:
            name = '지갑';

            break;
        case 3:
            name = '악세서리';

            break;
        case 4:
            name = '베스트아이템';

            break;
        default:
            name = '모든아이템';
            break;
    }
    return name;
};

export default categoryName;
