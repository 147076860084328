import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import {
  AdminListDiv,
  AdminPostDiv,
  AdminUserListDiv,
} from "../../../../components/admin";
import { Input } from "../../../../components/common/Input";

const AUsersDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState("");
  const [page, setPage] = useState(0);
  const [pageble, setPageble] = useState(Array);
  const [order, setOrder] = useState(Array);

  useEffect(() => {
    params.userId &&
      !data &&
      axios
        .get("/api/member/" + params.userId)
        .then((res) => {
          if (res.data.result === "0000") {
            setData(res.data.dto);
            console.log(res.data.dto);
          } else {
            alert(res.data.message);
          }
        })
        .catch((e) => alert("다시 시도해주세요.\n" + e));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/order/${params.userId}?page=${page}&size=10`)
      .then((res) => {
        if (res.data.result === "0000") {
          setOrder(res.data.dto.content);
          setPageble(res.data.dto);
          console.log(res.data.dto.content);
        }
      })
      .catch((e) => console.log(e));
  }, [page]);
  return (
    <AdminUserListDiv>
      <h5>회원 조회</h5>
      <AdminPostDiv className="user-wrap">
        <p>이름</p>
        <Input disabled value={data.memberName} />
        <p>전화번호</p>
        <Input disabled value={data.phone} />
        <p>주소</p>
        {data.shipmentDtos &&
          data.shipmentDtos.length > 0 &&
          data.shipmentDtos.map((v, i) => (
            <Input
              disabled
              value={`[${v.zipCode}] ${v.address}, ${v.adrDetail} ${
                v.baseAddress === "Y" ? " (기본 배송지)" : ""
              }`}
            />
          ))}
        <p style={{ fontSize: "medium", marginBottom: "20px" }}>보유쿠폰</p>
        <select>
          {data.couponDtos &&
            data.couponDtos.length > 0 &&
            data.couponDtos.map((c, i) => <option>{c.couponCode}</option>)}
        </select>
        <p style={{ fontSize: "medium", marginBottom: "20px" }}>주문내역</p>
        <AdminListDiv>
          {order &&
            order.length > 0 &&
            order.map((v, i) => (
              <div className="admin-user-payment" key={i}>
                <p>{v.payments[0] && v.payments[0].paymentId}</p>
                <p>{v.address + ", " + v.adrDetail}</p>
                <div>
                  {v.goods.map((j, k) => (
                    <p>{j.goodsName + " / " + v.orderItems[k].amount + "개"}</p>
                  ))}
                </div>
                <p>{v.payments[0] && v.payments[0].method}</p>
                <p>
                  {"결제금액" + v.payments[0] && v.payments[0].price + "원"}
                </p>
                <p>
                  {v.oStatus === "0"
                    ? "상품준비중"
                    : v.oStatus === "1"
                    ? "배송중"
                    : "배송완료"}
                </p>
              </div>
            ))}
        </AdminListDiv>
      </AdminPostDiv>
    </AdminUserListDiv>
  );
};

export default AUsersDetail;
