import React from 'react';

import styled from 'styled-components';

const UserComponent = ({ data }) => {
    return (
        <BlackBox>
            {/* <BlackInnerBox>
                <div>
                    <small>입금대기</small>
                    <p>0</p>
                </div>
            </BlackInnerBox> */}
            <BlackInnerBox>
                <div>
                    <small>상품준비중</small>
                    <p>{data.before}</p>
                </div>
            </BlackInnerBox>
            <BlackInnerBox>
                <div
                    onClick={() =>
                        window.open(
                            'https://st.sweettracker.co.kr/#/',
                            '_blank',
                            'width=700, height=400, top=50, left=50, scrollbars=no'
                        )
                    }
                >
                    <small>배송중</small>
                    <p>{data.after}</p>
                </div>
            </BlackInnerBox>
            <BlackInnerBox style={{ border: '0px solid white' }}>
                <div>
                    <small>배송완료</small>
                    <p>{data.end}</p>
                </div>
            </BlackInnerBox>
            <MobileBlackBox>
                <div className="inner">
                    <small>취소:</small>
                    <small>{data.cancel}</small>
                </div>
                <div className="inner">
                    <small>교환:</small>
                    <small>{data.change}</small>
                </div>
                <div className="inner" style={{ border: 'none' }}>
                    <small>반품:</small>
                    <small>{data.refund}</small>
                </div>
            </MobileBlackBox>
        </BlackBox>
    );
};

export default UserComponent;

const MobileBlackBox = styled.div`
    border-top: 1px solid white;
    grid-column: 1 / span 4;

    background-color: var(--main-color);
    color: white;

    display: none;

    @media screen and (max-width: 700px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .inner {
            display: flex;
            justify-content: space-around;

            border-right: 1px solid white;
            padding: 10px 0;

            small {
                font-weight: 500;
            }
        }
    }
`;

export const MainContents = styled.div`
    position: relative;
    width: 100%;
    max-width: 406px;
    height: 100%;

    padding: 40px 0;

    p {
        font-size: var(--font-size-sm);
    }

    .title {
        margin-bottom: 20px;
    }

    .input-div {
        position: relative;
        width: 100%;
        height: fit-content;

        margin-bottom: 20px;

        input[type='text'],
        input[type='password'] {
            position: relative;
            font-weight: 500;
            width: 100%;
            height: 45px;
            border: 1px solid var(--main-color);
            padding: 0 10px;
        }
    }

    .input-wrap {
        position: relative;
        width: 100%;
        height: fit-content;
    }

    label {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    .blank {
        height: 10px;
    }

    @media screen and (max-width: 700px) {
        padding: 20px 0;

        .input-wrap {
            .blank {
                height: 5px;
            }
        }
    }
`;

export const BlackBox = styled.div`
    position: relative;
    width: 100%;
    height: 80px;
    background-color: var(--main-color);
    color: white;
    padding: 10px 0;

    text-align: center;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    @media screen and (max-width: 700px) {
        height: 100%;
        padding: 0;
    }
`;

export const BlackInnerBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    border-right: 1px solid white;

    text-align: center;

    display: grid;
    align-items: center;

    small {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    p {
        padding-top: 5px;
        font-size: var(--font-size-sm);
        font-weight: 500;
    }

    @media screen and (max-width: 700px) {
        small {
            font-size: var(--font-size-sm);
        }
        min-height: 70px;
    }
`;

export const MonthBtnWrap = styled.div`
    position: relative;
    width: fit-content;
    height: fit-content;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

export const MonthBtn = styled.button`
    position: relative;
    width: 38px;
    height: 21px;
    border: 1px solid var(--main-color);

    p {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    &.checkBtn {
        background-color: var(--main-color);
        color: white;
    }
`;

export const InputWrap = styled.div`
    position: relative;
    width: fit-content;
    height: fit-content;

    display: flex;
    gap: 10px;

    @media screen and (max-width: 700px) {
        margin-left: auto;
    }
`;

export const MonthInput = styled.input`
    position: relative;
    width: 93px;
    height: 21px !important;
    border: 1px solid var(--main-color);
    border-radius: 0 !important;

    font-size: var(--font-size-xs);
    font-weight: 500;
    text-align: center;
`;

export const BlackBtn = styled.button`
    position: relative;
    width: 38px;
    height: 21px;
    border-radius: 3px;
    background-color: var(--main-color);
    color: white;
    p {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }
`;

export const BetweenFlex = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: space-between;
    align-items: center;

    text-align: center;

    margin: 10px 0;

    small {
        font-size: var(--font-size-sm);
        font-weight: 500;
    }

    @media screen and (max-width: 700px) {
        flex-wrap: wrap;
        gap: 10px;
    }
`;

export const OrderDataWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    min-height: 100px;

    margin-top: 40px;

    display: grid;
    align-items: center;

    text-align: center;

    p {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    .order-wrap {
        margin-bottom: 20px;

        text-align: left;

        p {
            font-size: var(--font-size-sm);
            font-weight: 500;
        }
    }

    .flex-top {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-bottom: 20px;
        border-bottom: 1px solid var(--light-color);
    }

    .flex-middle {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 20px 0;
        gap: 10px;

        .light {
            font-weight: 400;
        }

        .img-div {
            position: relative;
            width: 104px;
            height: 129px;

            img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .text-div {
        position: relative;
        height: 100%;
        height: 129px;
    }
`;

export const PagenationDiv = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    div {
        display: flex;
        gap: 20px;
    }
`;

export const CouponInputWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    padding-bottom: 40px;

    small {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    @media screen and (max-width: 700px) {
        padding-bottom: 20px;
    }
`;

export const CouponInput = styled.input`
    position: relative;
    width: 100%;
    height: 45px;
    border: 1px solid var(--main-color);
    padding: 10px;

    font-size: var(--font-size-sm);

    margin-bottom: 10px;
`;

export const UnUsedCouponWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 80px 0;

    display: grid;
    gap: 20px;

    @media screen and (max-width: 700px) {
        padding: 40px 0;
    }
`;

export const CouponCard = styled.div`
    position: relative;
    width: 100%;
    height: 160px;
    border: 1px solid var(--main-color);
    border-radius: 3px;
    padding: 0 19px;
    padding-bottom: 10px;

    p {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }
`;

export const CouponTitle = styled.div`
    padding: 20px 0;

    border-bottom: 1px solid var(--main-color);
`;

export const UserInfoWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 406px;
    height: fit-content;

    padding: 40px 0;

    p {
        font-size: var(--font-size-xs);
        margin-bottom: 10px;
    }
`;

export const PasswordGrid = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
`;

export const Border = styled.div`
    position: relative;
    width: 100%;
    height: 1px;
    background-color: var(--light-color);
    margin: 20px 0;
`;

export const MypageOrderGoods = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 10px;

    .img {
        position: relative;
        width: 104px;
        height: 129px;

        img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text {
        position: relative;
        width: 100%;
        height: 100%;
        line-height: 19px;
    }

    .absoluteP {
        position: absolute;
        bottom: 1px;
    }

    .post-review {
        position: absolute;
        right: 0;
        border: 1px solid var(--main-color);
        width: fit-content;
        height: fit-content;
        border-radius: 3px;
        padding: 5px 15px;
        bottom: 0;
    }
`;

export const MypageOrderInfo = styled.div`
    .lineBtn {
        font-size: var(--font-size-xs);
        border-radius: 3px;
        border: 1px solid var(--main-color);
        padding: 2px 7px;
    }

    .normal p {
        font-weight: 500;
    }
`;

export const MypagePaymentInfo = styled.div``;
export const MypagePriceInfo = styled.div``;
