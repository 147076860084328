import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styled from 'styled-components';

const Nav = () => {
    const { pathname } = useLocation();

    return (
        <FindInfoNav>
            <Link to={'/find-id'} className={pathname === '/find-id' ? 'on' : ''}>
                <p>아이디&nbsp;찾기</p>
            </Link>
            <Link to={'/find-password'} className={pathname === '/find-password' ? 'on' : ''}>
                <p>비밀번호&nbsp;찾기</p>
            </Link>
        </FindInfoNav>
    );
};

export default Nav;

const FindInfoNav = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 20px;

    color: var(--light-color);

    .on {
        color: var(--main-color);
    }

    @media screen and (max-width: 700px) {
        p {
            font-weight: 600;
        }
    }
`;

export const FindInfoInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    padding: 40px 0;

    small {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    @media screen and (max-width: 700px) {
        padding: 20px 0;
        span p {
            font-size: var(--font-size-sm);
            font-weight: 500;
            margin-bottom: 20px;
        }
    }
`;

export const FindInfoGridInput = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: 10px;

    input {
        position: relative;
        height: 45px;
        padding: 10px;

        font-size: var(--font-size-sm);
        font-weight: 500;
    }

    padding-top: 10px;
`;

export const FindInfoFlex = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;
