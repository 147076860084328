import React from 'react';

import styled from 'styled-components';

const BottomNotice = () => {
    return (
        <NoticeWrap>
            <p className="small-text">
                · 쇼핑백 구매는 추가상품에서 가능하며 제품 개수만큼 구매하실 수 있습니다.
                <br />· 모든 제품은 핸드메이드 제품으로 주문일로부터 2~3일 후 출고됩니다.
            </p>
        </NoticeWrap>
    );
};

export default BottomNotice;

export const NoticeWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 406px;
    height: fit-content;

    background-color: #f5f5f5;

    font-size: var(--font-size-xs);
    font-weight: 500;

    padding: 10px;
    line-height: 1.5;

    li {
        display: flex;
    }

    .small-text {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    ul p {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    @media screen and (max-width: 700px) {
        .small-text {
            font-size: 7.5pt;
            font-weight: 500;
        }
    }
`;
