import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortList from '../../../const/SortList';
import axios from 'axios';
import { AdminListDiv } from '../../../components/admin';
import { BlackBtn } from '../../../components/common/BlackBtn';

const AAdmins = () => {
    const navigate = useNavigate();

    const [data, setData] = useState(Array);

    useEffect(() => {
        data.length === 0 &&
            axios
                .get('/api/admins')
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto);
                        console.log(res.data);
                    }
                })
                .catch((e) => alert('다시 시도해주세요.\n' + e));
    }, []);

    return (
        <AdminListDiv>
            <h5>관리자 리스트</h5>
            <div className="right-btn">
                <BlackBtn type="button" onClick={() => navigate('/admin/member/post')}>
                    <p>관리자 추가</p>
                </BlackBtn>
            </div>
            <div className="admin-card">
                <p>No</p>
                <p>이름</p>
                <p>이메일</p>
                <p>생성일</p>
                <p>수정일</p>
                <p>사용여부</p>
            </div>
            {data.map((v, i) => (
                <div key={i} className="admin-card" onClick={() => navigate('/admin/member/' + v.id)}>
                    <p>{v.id}</p>
                    <p>{v.adminName}</p>
                    <p>{v.email}</p>
                    <p>{v.created}</p>
                    <p>{v.updated}</p>
                    <p>{v.useYn === 'Y' ? '사용중' : '사용불가'}</p>
                </div>
            ))}
        </AdminListDiv>
    );
};

export default AAdmins;
