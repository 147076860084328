import React from 'react';

import styled from 'styled-components';
import { dateFormat } from '../../const/dateFormat';

const Reviews = ({ data }) => {
    console.log(data);
    return (
        <ReviewDiv>
            <div className="reviewwrap">
                <p className="top-text">
                    <span>리뷰</span>&nbsp;&nbsp;{data.reviewDtos.length}
                </p>
                <div className="review-top">
                    <div className="rating-left" style={{ position: 'relative', height: 'fit-content' }}>
                        <div className="flex-center">
                            <img alt="icon" src="/images/icon_star_black.svg" width={32} height={'auto'} />
                            <h5>{data.reviewDtos && data.reviewDtos[0].score}</h5>
                        </div>
                        <div className="star-bottom">
                            <p>{data.reviewDtos && data.reviewDtos.length}개의 리뷰</p>
                        </div>
                    </div>
                    <div className="hr" />
                    <div className="rating-right">
                        <div className="flex-start">
                            <img alt="icon" src="/images/icon_star_black.svg" width={22} height={'auto'} />
                            <img alt="icon" src="/images/icon_star_black.svg" width={22} height={'auto'} />
                            <img alt="icon" src="/images/icon_star_black.svg" width={22} height={'auto'} />
                            <img alt="icon" src="/images/icon_star_black.svg" width={22} height={'auto'} />
                            <img alt="icon" src="/images/icon_star_black.svg" width={22} height={'auto'} />
                        </div>
                        {/* <p>구매자의 100%가 이 상품에 만족했어요</p> */}
                    </div>
                </div>
                {/* <ImageWrap>
          <p>동영상/사진 모아보기</p>

          <div className="review-imgs-grid">
            {data.reviewDtos.map((v, i) => (
              <div key={i}>
                {v.reviewImgs[0] && (
                  <img alt="review" src={v.reviewImgs[0].src} />
                )}
              </div>
            ))}
          </div>
        </ImageWrap> */}
                <SoltedDiv>
                    <select>
                        <option>최신순</option>
                        <option>추천순</option>
                        <option>별점순</option>
                    </select>
                </SoltedDiv>
                {data.reviewDtos &&
                    data.reviewDtos.map((v, i) => (
                        <ReviewCard key={i}>
                            <div className="inner-wrap">
                                <div className="left-div">
                                    <div className="flex-div">
                                        <p>
                                            {v.member.memberName[0] +
                                                '*'.repeat(v.member.memberName.length - 2) +
                                                v.member.memberName[v.member.memberName.length - 1]}
                                        </p>
                                        <small className="date">{dateFormat(v.created)}</small>
                                    </div>
                                </div>
                                <div className="right-div">
                                    <div>
                                        <img alt="icon" src="/images/icon_star_black.svg" width={15} height={'auto'} />
                                        <img alt="icon" src="/images/icon_star_black.svg" width={15} height={'auto'} />
                                        <img alt="icon" src="/images/icon_star_black.svg" width={15} height={'auto'} />
                                        <img alt="icon" src="/images/icon_star_black.svg" width={15} height={'auto'} />
                                        <img alt="icon" src="/images/icon_star_black.svg" width={15} height={'auto'} />
                                    </div>
                                    <p>{v.contents}</p>
                                </div>
                            </div>
                        </ReviewCard>
                    ))}
            </div>
        </ReviewDiv>
    );
};

export default Reviews;

const ReviewDiv = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    padding: 0 var(--wrapper-padding);

    .reviewwrap {
        position: relative;
        width: 100%;
        max-width: 1140px;
        margin: 30px auto;
    }

    .top-text {
        display: flex;
        align-items: center;
        color: rgb(123, 132, 141);
        padding: 16px 0;

        span {
            font-size: 24px;
            color: var(--main-color);
        }
    }

    .review-top {
        position: relative;
        width: 100%;
        height: 196px;

        border-top: 2px solid var(--main-color);
        border-bottom: 1px solid var(--light-color);

        display: flex;
        align-items: center;

        padding: 40px 0;
    }

    .flex-center {
        display: flex;
        width: 268px;
        height: fit-content;
        justify-content: center;
        align-items: center;
        gap: 10px;

        margin-bottom: 5px;
    }

    .star-bottom {
        position: relative;
        width: fit-content;
        height: fit-content;
        background-color: #f1f3f5;
        color: #4d5359;
        margin: 0 auto;
        padding: 2px 10px;
        border-radius: 3px;
        font-size: var(--font-size-sm);
    }

    .hr {
        position: relative;
        width: 1px;
        height: 100%;
        background-color: var(--light-color);
    }

    .rating-right {
        padding: 0 72px;
    }

    .flex-start {
        display: flex;
        gap: 5px;
        margin-bottom: 10px;
    }

    p {
        font-size: var(--font-size-sm);
    }

    @media screen and (max-width: 700px) {
        .review-top {
            flex-wrap: wrap;
            justify-content: center;
        }

        .hr {
            display: none;
        }

        h5 {
            font-size: 30pt;
        }

        .review-top {
            height: 100%;
            border-bottom: none;
        }

        .rating-right {
            padding: 0;

            .flex-start {
                justify-content: center;
                margin-top: 50px;
            }
        }
    }
`;

const ImageWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    padding-top: 30px;
    padding-bottom: 48px;

    p {
        padding-bottom: 16px;
    }

    .review-imgs-grid {
        display: flex;
        gap: 10px;

        overflow: auto;

        img {
            position: relative;
            width: 226px;
            height: 375px;

            border-radius: 3px;
            object-fit: cover;
        }
    }

    @media screen and (max-width: 700px) {
        .review-imgs-grid img {
            width: 150px;
            height: 250px;
        }
    }
`;

const SoltedDiv = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    gap: 16px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    flex-shrink: 0;

    select {
        position: relative;
        width: 90px;
        height: 40px;
        padding: 10px;
        border: 1px solid var(--light-color);
    }

    @media screen and (max-width: 700px) {
        margin-top: 20px;
    }
`;

const ReviewCard = styled.div`
    display: flex;
    flex-direction: column;

    position: relative;
    width: 100%;
    height: 100%;
    border-top: 1px solid var(--light-color);

    .inner-wrap {
        display: flex;
        padding: 32px 0px;
        flex-direction: row;
        gap: 32px;
    }

    .left-div {
        width: 260px;
        min-height: 178px;
        gap: 16px;
        padding: 24px;

        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 400;
        line-height: 160%;

        background: rgb(248, 249, 250);
        border-radius: 4px;
        flex-shrink: 0;
    }

    .flex-div {
        display: flex;
        flex-direction: row;
        -webkit-box-pack: justify;
        justify-content: space-between;
        gap: 12px;

        .date {
            font-weight: 400;
            color: rgb(123, 132, 141);
            font-size: var(--font-size-xs);
        }
    }

    .right-div {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 16px;
        gap: 24px;

        p {
            font-weight: 500;
        }
    }

    @media screen and (max-width: 700px) {
        border-top: none;

        .inner-wrap {
            flex-wrap: wrap;

            border-bottom: 1px solid var(--light-color);

            .left-div {
                width: 100%;
                height: fit-content;
                min-height: 0;
                padding: 10px;
            }

            .right-div {
                gap: 14px;
            }
        }
    }
`;
