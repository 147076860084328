import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { BlackBtn } from "../../../../components/common/BlackBtn";
import { AdminPostDiv } from "../../../../components/admin";
import { Input } from "../../../../components/common/Input";
import { dateFormat } from "../../../../const/dateFormat";

const AcouponDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { adminData } = useOutletContext();

  const [data, setData] = useState("");

  const [name, setName] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [forNew, setForNew] = useState("false");
  const [user, setUser] = useState(Array);

  params.couponId &&
    !data &&
    axios
      .get("/api/coupon/" + params.couponId)
      .then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.dto);
          console.log(res.data.dto);
        } else {
          alert(res.data.message);
        }
      })
      .catch((e) => alert("다시 시도해주세요.\n" + e));

  useEffect(() => {
    if (data) {
      setName(data.couponName);
      setMinPrice(data.minPrice);
      setDiscountRate(data.discountRate);
      setDiscountPrice(data.discountPrice);
      setStartDate(dateFormat(data.startDate));
      setExpirationDate(dateFormat(data.expirationDate));
      setForNew(data.forNew);
    }
  }, [data]);

  useEffect(() => {
    data.length === 0 &&
      axios
        .get(`/api/member?page=0&size=0&sort=0`)
        .then((res) => {
          if (res.data.result === "0000") {
            setUser(res.data.dto);
            console.log(res.data.dto);
          } else {
            alert("다시 시도해주세요.");
          }
        })
        .catch((e) => alert("다시 시도해주세요.\n" + e));
  }, []);

  const savedCouponHandler = async () => {
    if (!name || !adminData.id) return false;

    if (discountRate > 0 && discountPrice > 0) return false;

    await axios
      .post("/api/coupon", {
        couponName: name,
        minPrice: minPrice,
        discountRate: discountRate,
        discountPrice: discountPrice,
        startDate: startDate + "T00:00:00.000Z",
        expirationDate: expirationDate + "T00:00:00.000Z",
        forNew: forNew,
        adminId: adminData.id,
      })
      .then((res) => {
        if (res.data.result === "0000") {
          alert("정상적으로 등록되었습니다.");
          navigate("/admin/coupon");
        }
      })
      .catch((e) => alert("다시 시도해주세요.\n" + e));
  };

  const updateCouponHandler = async () => {
    if (!name || !adminData.id) return false;

    if (discountRate > 0 && discountPrice > 0) return false;

    await axios
      .put("/api/coupon", {
        couponName: name,
        minPrice: minPrice,
        discountRate: discountRate,
        discountPrice: discountPrice,
        startDate: startDate + "T00:00:00.000Z",
        expirationDate: expirationDate + "T00:00:00.000Z",
        forNew: forNew,
        adminId: adminData.id,
      })
      .then((res) => {
        if (res.data.result === "0000") {
          alert("정상적으로 수정되었습니다.");
          navigate("/admin/coupon");
        }
      })
      .catch((e) => alert("다시 시도해주세요.\n" + e));
  };
  return (
    <div>
      <h5>쿠폰 {data ? "수정" : "추가"}</h5>

      <AdminPostDiv>
        <p>쿠폰 이름</p>
        <Input
          type="text"
          placeholder="쿠폰 이름"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="br" />
        <p>최소 주문 금액</p>
        <Input
          type="number"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
        />
        <div className="br" />
        <p>할인 비율 (할인 비율과 금액 중 하나만 입력해주세요.)</p>
        <Input
          type="number"
          value={discountRate}
          onChange={(e) => setDiscountRate(e.target.value)}
        />
        <div className="br" />
        <p>할인 금액 (할인 비율과 금액 중 하나만 입력해주세요.)</p>
        <Input
          type="number"
          value={discountPrice}
          onChange={(e) => setDiscountPrice(e.target.value)}
        />
        <div className="br" />
        <p>쿠폰 시작일</p>
        <Input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <div className="br" />
        <p>쿠폰 만료일</p>
        <Input
          type="date"
          value={expirationDate}
          onChange={(e) => setExpirationDate(e.target.value)}
        />
        <div className="br" />
        <p>대상 회원</p>
        {data &&
          (data.forNew === "true" ? (
            <select onChange={(e) => setForNew(e.target.value)}>
              <option value={"true"}>신규회원</option>
              <option value={"false"}>전체회원</option>
            </select>
          ) : (
            <select onChange={(e) => setForNew(e.target.value)}>
              <option value={"false"}>전체회원</option>
              <option value={"true"}>신규회원</option>
            </select>
          ))}
        {!data && (
          <select onChange={(e) => setForNew(e.target.value)}>
            <option value={"false"}>전체회원</option>
            <option value={"true"}>신규회원</option>
            {user.length > 0 &&
              user.map((v, i) => (
                <option
                  value={v.id}
                >{`${v.email} | ${v.memberName} | ${v.phone}`}</option>
              ))}
          </select>
        )}
      </AdminPostDiv>
      {data ? (
        <BlackBtn type="button" onClick={updateCouponHandler}>
          수정
        </BlackBtn>
      ) : (
        <BlackBtn type="button" onClick={savedCouponHandler}>
          저장
        </BlackBtn>
      )}
    </div>
  );
};

export default AcouponDetail;
