import styled from 'styled-components';

export const BlackBtn = styled.button`
    position: relative;
    width: 100%;
    height: 46px;

    display: grid;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-size: var(--font-size-sm);

    color: white;
    background-color: var(--main-color);

    border: 1px solid var(--main-color);
    border-radius: 3px;

    .npay {
        position: relative;
        width: auto;
        height: 100%;
        max-height: 15px;
        margin: 0;
    }

    @media screen and (max-width: 700px) {
        height: 17px;

        padding: 14px 0;

        font-weight: 600;
    }
`;
