import React from 'react';

import styled from 'styled-components';

const LoginLogo = () => {
    return (
        <LoginTitle onClick={() => (window.location.href = '/')}>
            <img alt="logo" src="/images/logo.svg" />
        </LoginTitle>
    );
};

export default LoginLogo;

const LoginTitle = styled.div`
    position: relative;
    width: 100%;
    height: auto;

    max-width: 131px;

    padding-bottom: 30px;

    margin: 0 auto;

    img {
        position: relative;
        width: 100%;
        height: auto;
    }

    @media screen and (max-width: 700px) {
        display: none;
    }
`;
