import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        insertGoods: (state, action) => {
            let num = state.findIndex((obj) => {
                return obj.id === action.payload.id;
            });

            if (num === -1) {
                state.push(action.payload);
            } else {
                state[num].count += action.payload.count;
            }
        },
        increaseCount(state, action) {
            let num = state.findIndex((obj) => {
                return obj.id === action.payload;
            });
            console.log(num);
            state[num].count += 1;
        },
        decreaseCount(state, action) {
            let num = state.findIndex((obj) => {
                return obj.id === action.payload;
            });
            console.log(num);
            state[num].count -= 1;
        },
        deleteGoods(state, action) {
            let num = state.findIndex((obj) => {
                return obj.id === action.payload;
            });
            state.splice(num, 1);
        },
        initialGoods(state) {
            state.splice(0, state.length);
            state = [];
            state.length = 0;
        },
    },
});

export let { insertGoods, increaseCount, decreaseCount, deleteGoods, initialGoods } = cartSlice.actions;
export default cartSlice.reducer;
