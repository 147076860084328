import React from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

const Layout = () => {
    const { pathname } = useLocation();

    console.log(pathname);

    if (pathname.includes('admin')) {
        return (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <Outlet />
            </div>
        );
    }
    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
};

export default Layout;
