import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import AHeader from './Header';
import { useSelector } from 'react-redux';

const ALayout = () => {
    const navigate = useNavigate();

    const adminData = useSelector((state) => state.admin);

    if (adminData.id === 0 || adminData.id === null) {
        navigate('/login/admin');

        return <></>;
    } else {
        return (
            <AdminWrap>
                <AHeader />
                <ACommonWrap>
                    <Outlet
                        context={{
                            adminData,
                        }}
                    />
                </ACommonWrap>
            </AdminWrap>
        );
    }
};

export default ALayout;

const AdminWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    overflow-x: hidden;

    @media screen and (max-width: 1000px) {
        overflow-x: auto;
    }
`;

const ACommonWrap = styled.div`
    position: relative;
    height: 100%;
    min-height: 70vh;
    padding: 120px var(--wrapper-padding);

    h5 {
        margin-bottom: 30px;
    }

    input,
    select {
        position: relative;
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        padding: 10px;
    }

    margin-left: 170px;

    .right-btn {
        position: relative;
        width: 200px;
        height: fit-content;
        margin-left: auto;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 600px) {
        min-width: 800px;
        overflow-x: auto;
    }
`;
