import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { dateFormat } from '../../const/dateFormat';

import LoginButton from '../login/LoginButton';

import { InfoWrap } from './OrderInfo';

const Complete = ({ orders, payment }) => {
    let member = useSelector((state) => state.member);

    return (
        <InfoWrap>
            <InnerWrap>
                <p className="title" style={{ paddingBottom: '20px' }}>
                    주문 정보
                </p>
                <FlexWrap>
                    <TitleWrap>
                        <li>주문일자</li>
                        <li>주문번호</li>
                        <li>주문자</li>
                        <li>휴대폰 번호</li>
                    </TitleWrap>
                    {payment && (
                        <ContetnsWrap>
                            <li>{dateFormat(payment.paidAt)}</li>
                            <li>{payment.paymentId}</li>
                            <li>{member.memberName}</li>
                            <li>{member.phone}</li>
                        </ContetnsWrap>
                    )}
                </FlexWrap>
                <Line />
                <p className="title">배송 정보</p>
                <FlexWrap>
                    <TitleWrap>
                        <li>수령인</li>
                        <li>휴대폰 번호</li>
                        <li>배송지</li>
                        <li>　</li>
                        <li>배송&nbsp;요청사항</li>
                    </TitleWrap>
                    {orders && (
                        <ContetnsWrap>
                            <li>{orders.shipment.recipient}</li>
                            <li>{orders.id}</li>
                            <li>{orders.shipment.address}</li>
                            <li>{orders.shipment.adrDetail}</li>
                            <li>{orders.request}</li>
                        </ContetnsWrap>
                    )}
                </FlexWrap>
                <Line />
                <p className="title">결제 정보</p>
                <FlexWrap>
                    <TitleWrap>
                        <li>결제수단</li>
                    </TitleWrap>
                    <ContetnsWrap>
                        <li>
                            {payment.method === 'card'
                                ? '신용카드'
                                : payment.method === 'naverPay'
                                ? '네이버페이'
                                : '카카오페이'}
                        </li>
                    </ContetnsWrap>
                </FlexWrap>
                <div style={{ display: 'flex', gap: '10px', paddingTop: '20px' }}>
                    <LoginButton type="main" />
                    <LoginButton type="select" />
                </div>
            </InnerWrap>
        </InfoWrap>
    );
};

export default Complete;

const InnerWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    font-size: var(--font-size-sm);

    .title {
        padding: 20px 0;
    }

    @media screen and (max-width: 700px) {
        p {
            font-weight: 600;
        }

        li {
            font-weight: 500;
        }

        .title {
            font-size: var(--font-size-p);
        }
    }
`;

export const TitleWrap = styled.ul`
    position: relative;
    width: 74px;
    height: 100%;

    line-height: 2;

    padding-bottom: 20px;

    @media screen and (max-width: 700px) {
        width: 55px;
    }
`;

export const ContetnsWrap = styled.ul`
    position: relative;
    width: fit-content;
    height: 100%;

    line-height: 2;

    padding-bottom: 20px;

    li {
        font-weight: 500;
    }
`;

export const FlexWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: flex;

    gap: 50px;
`;

const Line = styled.div`
    position: relative;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--light-color);
`;
