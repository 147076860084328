import React, { useState } from 'react';

import styled from 'styled-components';

const CheckCondition = ({ check, set }) => {
    const [open, setOpen] = useState(false);

    const checkAll = (e) => {
        e.target.checked
            ? set({
                  age: true,
                  terms: true,
                  privacy: true,
                  marketing: true,
                  news: true,
              })
            : set({
                  age: false,
                  terms: false,
                  privacy: false,
                  marketing: false,
                  news: false,
              });
    };
    return (
        <TermsWrap>
            <div className="flex">
                <CheckAllTermsAndConditions>
                    <CheckBox
                        type="checkbox"
                        name="all"
                        id="all"
                        onChange={checkAll}
                        checked={
                            check.age && check.terms && check.privacy && check.marketing && check.news ? true : false
                        }
                    />
                    <label htmlFor="all">
                        <label className="checkbox"></label>모두 동의합니다.
                    </label>
                </CheckAllTermsAndConditions>

                <button onClick={() => setOpen(!open)}>
                    {open ? (
                        <img alt="icon" src="/images/icon_minus.svg" style={{ paddingTop: '19px' }} />
                    ) : (
                        <img alt="icon" src="/images/icon_plus.svg" style={{ paddingTop: '19px' }} />
                    )}
                </button>
            </div>
            <div className={open ? 'opend' : 'closed'}>
                <CheckTerms>
                    <CheckBox
                        type="checkbox"
                        name="age"
                        id="age"
                        onChange={(e) => set({ ...check, age: !check.age })}
                        checked={check.age}
                    />
                    <label htmlFor="age">
                        <label className="checkbox"></label>(필수)본인은 만 14세 미만이 아닙니다.
                    </label>
                </CheckTerms>
                <CheckTerms>
                    <CheckBox
                        type="checkbox"
                        name="terms"
                        id="terms"
                        onChange={(e) => set({ ...check, terms: !check.terms })}
                        checked={check.terms}
                    />
                    <label htmlFor="terms">
                        <label className="checkbox"></label>(필수)이용약관에 동의합니다.
                    </label>
                </CheckTerms>
                <CheckTerms>
                    <CheckBox
                        type="checkbox"
                        name="privacy"
                        id="privacy"
                        onChange={(e) => set({ ...check, privacy: !check.privacy })}
                        checked={check.privacy}
                    />
                    <label htmlFor="privacy">
                        <label className="checkbox"></label>(필수)개인정보처리방침에 동의합니다.
                    </label>
                </CheckTerms>
                <CheckTerms>
                    <CheckBox
                        type="checkbox"
                        name="marketing"
                        id="marketing"
                        onChange={(e) => set({ ...check, marketing: !check.marketing })}
                        checked={check.marketing}
                    />
                    <label htmlFor="marketing">
                        <label className="checkbox"></label>
                        (선택)마케팅 목적의 개인정보처리방침에 동의합니다.
                    </label>
                </CheckTerms>
                <CheckTerms>
                    <CheckBox
                        type="checkbox"
                        name="news"
                        id="news"
                        onChange={(e) => set({ ...check, news: !check.news })}
                        checked={check.news}
                    />
                    <label htmlFor="news">
                        <label className="checkbox"></label>
                        (선택)콤페토에서 제공하는 소식을 SMS, 이메일로 받겠습니다.
                    </label>
                </CheckTerms>
            </div>
        </TermsWrap>
    );
};

export default CheckCondition;

export const TermsWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    label {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            position: relative;
            width: fit-content;
            height: fit-content;
        }
    }

    .opend {
        height: fit-content;
        max-height: 200px;
    }

    .closed {
        height: 0;
        max-height: 0;
        overflow: hidden;
    }

    @media screen and (max-width: 700px) {
        .flex {
            img {
                position: relative;
                width: 15px;
                height: auto;
            }
        }
    }
`;

export const CheckBox = styled.input`
    position: relative;
    width: 12px;
    height: 12px;
`;

export const CheckAllTermsAndConditions = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: flex;
    align-items: center;
    gap: 5px;

    padding-top: 41px;
    padding-bottom: 23px;

    input {
        margin-top: -2px;
    }
`;

export const CheckTerms = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    display: flex;
    gap: 5px;

    margin-bottom: 12px;
`;
