import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import axios from 'axios';

import { setMember } from '../../store/memberSlice';

import styled from 'styled-components';

import LoginButton from '../../components/login/LoginButton';
import { IconWrap, LoginLinkTag, LoginWrapper, WarningText } from '../../components/login/LoginInput';
import LoginLogo from '../../components/login/LoginLogo';
import { InputWrap, PaymentInput } from '../../components/payment/ShipInfo';

const Login = () => {
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [checkEmail, setCheckEmail] = useState(true);
    const [display, setDisplay] = useState(false);

    const onLogin = async () => {
        try {
            await axios
                .post('/api/member/login', {
                    email: username,
                    password: password,
                })
                .then((res) => {
                    console.log(res.data);

                    dispatch(
                        setMember({
                            id: res.data.id,
                            email: res.data.email,
                            memberName: res.data.memberName,
                            birth: res.data.birth,
                            phone: res.data.phone,
                        })
                    );
                })
                .then(() => (window.location.href = '/'))
                .catch((err) => console.log(err));
        } catch (error) {}
    };

    const NAVER_CLIENT_ID = 'qMZXmTn55NOFqEvABsjG'; // 발급 받은 Client ID 입력
    const REDIRECT_URI = `https://www.competoseoul.com/oauth/login/naver`; // Callback URL
    const STATE = 'flase';
    const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;

    const NaverLogin = async () => {
        window.location.href = NAVER_AUTH_URL;
    };

    return (
        <LoginWrapper>
            <LoginLogo />
            <form>
                <PaymentInput
                    type={'text'}
                    value={username}
                    onChange={(e) => {
                        e.target.value.includes('@') && e.target.value.includes('.')
                            ? setCheckEmail(true)
                            : setCheckEmail(false);
                        setUsername(e.target.value);
                    }}
                    placeholder="아이디(이메일)"
                />
                {!checkEmail && (
                    <WarningText>
                        <img alt="icon" src="/images/icon_warning.svg" />
                        이메일 형식에 맞게 입력해 주세요
                    </WarningText>
                )}
                <div style={{ height: '10px' }}></div>
                {/* <LoginInput type="password" data={password} set={setPassword} /> */}
                <InputWrap>
                    <PaymentInput
                        type={display ? 'text' : 'password'}
                        id={'password'}
                        value={password}
                        placeholder={'비밀번호'}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    ></PaymentInput>
                    <IconWrap onClick={() => setDisplay(!display)}>
                        <img alt="logo" src={display ? '/images/icon_display.svg' : '/images/icon_hidden.svg'} />
                    </IconWrap>
                </InputWrap>
                <div className="mobile-hidden" style={{ height: '20px' }}></div>
                <LoginButton type="login" func={onLogin} />
                <LoginLinkTag>
                    <a href="/find-id">아이디(이메일) 찾기</a>ㅣ<a href="/find-password">비밀번호 찾기</a>
                </LoginLinkTag>
            </form>
            <SnsLoginBtn type="button" style={{ backgroundColor: '#FEE500' }}>
                <img alt="icon" src="/images/login_kakao.svg" />
            </SnsLoginBtn>
            <SnsLoginBtn type="button" style={{ backgroundColor: '#2DB400' }} onClick={NaverLogin}>
                <img alt="icon" src="/images/login_naver.svg" />
            </SnsLoginBtn>
            <LoginButton type="singup" />
            <LoginLinkTag>
                <small className="small-text">
                    회원가입 시 월컴 쿠폰 지급과 등급 혜택 및 다양한 소식을 받아볼 수 있습니다.
                </small>
            </LoginLinkTag>
        </LoginWrapper>
    );
};

export default Login;

const SnsLoginBtn = styled.button`
    position: relative;
    width: 100%;
    height: 44px;
    margin-bottom: 10px;

    border-radius: 3px;

    img {
        position: relative;
        width: 100%;
        height: 44px;
    }

    @media screen and (max-width: 700px) {
        margin-bottom: 10px;
    }
`;
