import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AdminPostDiv } from '../../../../components/admin';
import { BlackBtn } from '../../../../components/common/BlackBtn';
import { Input } from '../../../../components/common/Input';

const AOptDetail = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState('');

    const [categoryName, setCategoryName] = useState('');
    const [require, setRequire] = useState('N');
    const [optName, setOptName] = useState(Array);
    const [optPrice, setOptPrice] = useState(Array);

    params.optionId &&
        !data &&
        axios
            .get('/api/opt/category/' + params.optionId)
            .then((res) => {
                if (res.data.result === '0000') {
                    setData(res.data.dto);
                    console.log(res.data.dto);
                } else {
                    alert(res.data.message);
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));

    useEffect(() => {
        if (data) {
            setCategoryName(data.categoryName);
            setRequire(data.requireYn);
            setOptName([
                ...data.opts.map((v) => {
                    return v.optName;
                }),
            ]);
            setOptPrice([
                data.opts.map((v) => {
                    return v.price;
                }),
            ]);
        }
    }, [data]);

    const updateOptionHandler = async () => {
        if (optName.length !== optPrice.length) return false;
        await axios
            .put('/api/opt/category', {
                id: data.id,
                categoryName: categoryName,
                requireYn: require,
                optName: optName,
                price: optPrice,
                optCategoryId: data.id,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate('/admin/option');
                } else {
                    alert('다시 시도해주세요.');
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));
    };

    const saveOptionHandler = async () => {
        if (optName.length !== optPrice.length) return false;
        await axios
            .post('/api/opt/category', {
                categoryName: categoryName,
                requireYn: require,
                optName: optName,
                price: optPrice,
                optCategoryId: data.id,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate('/admin/option');
                } else {
                    alert('다시 시도해주세요.');
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));
    };

    return (
        <div>
            <h5>옵션 {data ? '수정' : '추가'}</h5>
            <AdminPostDiv>
                <p>옵션 카테고리명</p>
                <Input
                    type="text"
                    placeholder="카테고리명"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />
                <p>필수 선택 여부</p>
                {data && data.requireYn === 'Y' ? (
                    <select onChange={(e) => setRequire(e.target.value)}>
                        <option value={'Y'}>필수</option>
                        <option value={'N'}>선택</option>
                    </select>
                ) : (
                    <select onChange={(e) => setRequire(e.target.value)}>
                        <option value={'N'}>선택</option>
                        <option value={'Y'}>필수</option>
                    </select>
                )}
                <p>옵션명 (여러개일 경우 ,를 사용하여 원하는 갯수만큼 추가해주세요)</p>
                <Input
                    type="text"
                    placeholder=",를 사용하여 구분"
                    value={optName.toString()}
                    onChange={(e) => setOptName(e.target.value.split(','))}
                />
                <p>가격 (옵션과 동일한 수 만큼 ,를 사용하여 추가해주세요)</p>
                <Input
                    type="text"
                    placeholder=",를 사용하여 구분"
                    value={optPrice.toString()}
                    onChange={(e) => setOptPrice(e.target.value.split(','))}
                />
                {data ? (
                    <BlackBtn type="button" onClick={updateOptionHandler}>
                        수정
                    </BlackBtn>
                ) : (
                    <BlackBtn type="button" onClick={saveOptionHandler}>
                        저장
                    </BlackBtn>
                )}
            </AdminPostDiv>
        </div>
    );
};

export default AOptDetail;
