import React from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import styled from "styled-components";

import CartList from "./CartList";
import CartPrice from "./CartPrice";

import { BlackBtn } from "../common/BlackBtn";

const Cart = ({ on, set }) => {
  let cartList = useSelector((state) => state.cart);
  let member = useSelector((state) => state.member);

  const navigate = useNavigate();

  const ReadyForPayment = () => {
    setTimeout(false);
    set(false);

    if (cartList.length === 0) {
      alert("장바구니에 상품을 담아주세요.");
      return false;
    }

    if (member.id === 0) {
      alert("로그인을 먼저 진행해주세요.");
      return false;
    }

    navigate("/payment/order-info");
  };

  return (
    <>
      <CartWrap
        className={!on ? "closed" : "opened"}
        onClick={(e) => e.stopPropagation()}
      >
        <FlexDiv>
          <p className="title">장바구니</p>
          <img
            className="iconX mobile-hidden"
            alt="icon"
            src="/images/icon_x.svg"
            onClick={() => set(false)}
          />
        </FlexDiv>
        <CartInnerWrap>
          <CartList />
          <CardPrice>
            <CartPrice />
            <div className="blank" style={{ height: "10px" }} />
            <BlackBtn
              type="button"
              // disabled={cartList.length > 0 ? false : true}
              onClick={ReadyForPayment}
            >
              결제하기
            </BlackBtn>
            <BlackBtn type="button">
              <img className="npay" alt="button" src="/images/logo_npay.svg" />
            </BlackBtn>
          </CardPrice>
        </CartInnerWrap>
      </CartWrap>
      <Modal
        className={!on ? "closed" : "opened"}
        onClick={() => set(false)}
      ></Modal>
    </>
  );
};

export default Cart;

export const Modal = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);

  &.opened {
    display: block;
  }
  &.closed {
    display: none;
  }
`;

export const CartWrap = styled.div`
  position: fixed;
  display: block;
  right: -100%;
  top: 0;
  bottom: 0;
  z-index: 100;

  box-shadow: 0 0 0;

  width: 25%;
  min-width: 486px;
  height: 100vh;

  background-color: white;
  padding: 40px;
  transition: all 0.8s ease;

  &.opened {
    right: 0;
  }
  &.closed {
    right: -100%;
  }

  @media screen and (max-width: 700px) {
    transition: all 0.6s ease;
    min-width: 340px;
    width: 78%;
    padding: 35px var(--wrapper-padding);
  }
`;

const CartInnerWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const FlexDiv = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .iconX {
    filter: brightness(0.3);
    width: 19px;
    height: 19px;
    cursor: pointer;
  }

  @media screen and (max-width: 700px) {
    .title {
      font-size: 16pt;
      font-weight: 600;
    }
  }
`;

export const CardPrice = styled.div`
  position: relative;
  width: 100%;
  height: 221px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;

  border-top: 1px solid var(--light-color);

  padding: 20px 0;

  small {
    font-size: var(--font-size-sm);
  }

  img {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: -10px;
  }

  @media screen and (max-width: 700px) {
    .naver-pay {
      position: relative;
      width: 100%;
      height: 46px;
      margin-top: -12px;
      background-color: var(--main-color);
      border-radius: 3px;
    }

    .blank {
      height: 1px;
      margin-top: -10px;
    }

    img {
      margin-top: 0;
    }
  }
`;
