import React, { useState } from 'react';

import { UseSelector, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate();
    const adminData = useSelector((state) => state.admin);

    const [data, setData] = useState(Array);

    if (!adminData) navigate('/');
    return <div>Dashboard</div>;
};

export default Dashboard;
