import React, { useState } from "react";

import {
  LoginWrapper,
  WarningText,
} from "../../../components/login/LoginInput";
import Nav, {
  FindInfoGridInput,
  FindInfoInner,
} from "../../../components/find/Nav";
import { CommonBtn } from "../../../components/payment/ShipInfo";

const FindPassword = () => {
  const [email, setEmail] = useState("");
  const [checkEmail, setCheckEmail] = useState(true);

  return (
    <LoginWrapper>
      <Nav />
      <FindInfoInner>
        <span className="mobile-visible">
          <p>
            가입한 아이디(이메일)를 입력해주세요.
            <br />
            발송된 링크를 통해 비밀번호 변경이 가능합니다.
          </p>
        </span>
        <label htmlFor="findPwInput">
          <small>아이디(이메일)</small>
        </label>
        <FindInfoGridInput>
          <input
            type="text"
            name="findPwInput"
            id="findPwInput"
            value={email}
            onChange={(e) => {
              e.target.value.includes("@") && e.target.value.includes(".")
                ? setCheckEmail(true)
                : setCheckEmail(false);

              setEmail(e.target.value);
            }}
            placeholder="이메일 입력"
          />
          <CommonBtn property="var(--main-color)" color="white">
            이메일&nbsp;발송
          </CommonBtn>
        </FindInfoGridInput>
        {!checkEmail && (
          <WarningText>
            <img alt="icon" src="/images/icon_warning.svg" />
            이메일 형식에 맞게 입력해 주세요
          </WarningText>
        )}
      </FindInfoInner>
    </LoginWrapper>
  );
};

export default FindPassword;
