import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useSelector } from "react-redux";

import {
  AddressGrid,
  CheckBox,
  CheckBoxAndLabel,
  CommonBtn,
  FlexButton,
  InputWrap,
  MainInner,
  PaymentInput,
  PaymentSelect,
  PaymentWrap,
  TitleFlex,
} from "../../../components/payment/ShipInfo";
import deliveryReqList from "../../../const/deliveryReq";
import axios from "axios";

const ShipmentInfo = () => {
  let member = useSelector((state) => state.member);

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [zonecode, setZonecode] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [deliveryReq, setDeliveryReq] = useState("없음");
  const [writeReq, setWriteReq] = useState("");
  const [baseAdr, setBaseAdr] = useState(false);
  const [data, setData] = useState("");

  const selected = useRef(null);

  const open = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );

  const selectLoginMember = () => {
    setName(member.memberName);
    setPhone(member.phone.replaceAll("-", ""));
  };

  const handleClick = () => {
    open({ onComplete: completeHandler });
  };

  useEffect(() => {
    if (deliveryReq === "없음" || deliveryReq === "직접입력")
      selected.current && selected.current.classList.add("empty");
    else {
      selected.current && selected.current.classList.remove("empty");
    }
  }, [deliveryReq]);

  const completeHandler = (data) => {
    const { address, zonecode } = data;
    setZonecode(zonecode);
    setAddress(address);
  };

  const getShipmentInfoHandler = async () => {
    await axios
      .get("/api/shipment/adr/base?id=" + member.id)
      .then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.dto);
        } else {
          alert(res.data.message);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    !name && setName(data.recipient || "");
    !phone && setPhone(data.phone || "");
    setAddress(data.address || "");
    setZonecode(data.zipCode || "");
    setAddressDetail(data.adrDetail || "");
    setBaseAdr(data.baseAddress === "Y" ? true : false);
  }, [data]);

  const saveShipInfo = async () => {
    if (!name || !phone || !address) return false;

    if (!data) {
      await axios
        .post(`/api/shipment/adr`, {
          sname: name,
          recipient: name,
          phone: phone,
          zipCode: zonecode,
          address: address,
          adrDetail: addressDetail,
          baseAddress: baseAdr ? "Y" : "N",
          memberId: member.id,
        })
        .then((res) => {
          if (res.data.result === "0000") {
            sessionStorage.setItem("memberId", member.id);
            sessionStorage.setItem("addressId", res.data.dto.id);
            sessionStorage.setItem("request", deliveryReq);

            if (sessionStorage.getItem("addressId")) {
              navigate("/payment/payment-method");
            }
          } else {
            alert("다시 시도해주세요.");
          }
        })
        .catch((e) => alert("다시 시도해주세요\n" + e));
    }

    sessionStorage.setItem("memberId", member.id);
    sessionStorage.setItem("addressId", data.id);
    sessionStorage.setItem("request", deliveryReq);

    if (sessionStorage.getItem("addressId")) {
      navigate("/payment/payment-method");
    }
  };

  return (
    <PaymentWrap>
      <p className="title">배송 정보를 확인해 주세요</p>
      <form>
        <MainInner>
          <InputWrap>
            <TitleFlex>
              <label htmlFor="name">수령인</label>
              <button type="button" onClick={selectLoginMember}>
                주문자 정보와 동일
              </button>
            </TitleFlex>
            <PaymentInput
              id="name"
              type={"text"}
              value={name}
              placeholder="이름"
              onChange={(e) => setName(e.target.value)}
            />
          </InputWrap>
          <InputWrap>
            <TitleFlex>
              <label htmlFor="phone">휴대폰 번호</label>
            </TitleFlex>
            <PaymentInput
              id="phone"
              type={"text"}
              value={phone}
              placeholder="‘-’없이 숫자만 입력"
              onChange={(e) =>
                setPhone(
                  e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1")
                )
              }
            />
          </InputWrap>
          <InputWrap>
            <TitleFlex>
              <label htmlFor="addressDetail">배송지</label>
              <button type="button" onClick={getShipmentInfoHandler}>
                최근 배송지
              </button>
            </TitleFlex>
            <AddressGrid>
              <PaymentInput
                id="address"
                type={"text"}
                value={address}
                disabled={true}
              />
              <CommonBtn
                type="button"
                property="var(--main-color)"
                color="white"
                onClick={handleClick}
              >
                주소 찾기
              </CommonBtn>
            </AddressGrid>
            <PaymentInput
              id="addressDetail"
              type={"text"}
              value={addressDetail}
              placeholder="상세주소"
              onChange={(e) => setAddressDetail(e.target.value)}
            />
          </InputWrap>
          <InputWrap>
            <TitleFlex>
              <label htmlFor="deliveryReq">배송 요청사항</label>
            </TitleFlex>
            <PaymentSelect
              ref={selected}
              onChange={(e) => setDeliveryReq(e.target.value)}
            >
              {deliveryReqList.map((v, i) => (
                <option key={i} value={v.value}>
                  {v.placeholder}
                </option>
              ))}
            </PaymentSelect>
            {deliveryReq === "직접입력" && (
              <PaymentInput
                type={"text"}
                value={writeReq}
                style={{ marginTop: "10px" }}
                placeholder="요청사항을 입력해주세요."
                onChange={(e) => setWriteReq(e.target.value)}
              />
            )}
          </InputWrap>

          <CheckBoxAndLabel onClick={() => setBaseAdr(!baseAdr)}>
            <CheckBox
              type="checkbox"
              name="privacy"
              id="privacy"
              //   onChange={(e) => setBaseAdr(!baseAdr)}
              checked={baseAdr}
            />

            <label htmlFor="privacy" style={{ zIndex: "-1" }}>
              <label className="checkbox"></label>
              기본 배송지로 지정
            </label>
          </CheckBoxAndLabel>
        </MainInner>
        <FlexButton>
          <CommonBtn
            type="button"
            property="white"
            onClick={() => navigate(-1)}
          >
            이전 단계로
          </CommonBtn>
          <CommonBtn
            type="button"
            property="var(--main-color)"
            color="white"
            onClick={saveShipInfo}
          >
            저장하고 다음 단계로
          </CommonBtn>
        </FlexButton>
      </form>
    </PaymentWrap>
  );
};

export default ShipmentInfo;
