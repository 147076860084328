import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AdminPostDiv } from '../../../../components/admin';
import { BlackBtn } from '../../../../components/common/BlackBtn';
import { ContetnsWrap, FlexWrap, TitleWrap } from '../../../../components/payment/Complete';
import { Border, MypageOrderInfo } from '../../../../components/user/UserComponent';
import formPrice from '../../../../const/formPrice';
import { Input } from '../../../../components/common/Input';

const APaymentDetail = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');

    useEffect(() => {
        !data &&
            params.paymentId &&
            axios
                .get('/api/order/one/' + params.paymentId)
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto);
                    } else {
                        alert('잘못된 경로입니다.');
                    }
                })
                .catch((e) => console.log(e));
    }, [data]);

    const updateHandler = async () => {
        if (!trackingNumber) {
            alert('운송장번호를 입력해주세요.');
            return false;
        }

        await axios
            .put('/api/order/' + params.paymentId, {
                orderId: params.paymentId,
                trackingNumber: trackingNumber,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate('/admin/payment');
                } else {
                    alert(res.data.message);
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));
    };

    return (
        <div>
            <h5>주문내역</h5>
            {data && (
                <AdminPostDiv>
                    <MypageOrderInfo>
                        <FlexWrap className="normal">
                            <TitleWrap>
                                <li>
                                    <p>주문번호</p>
                                </li>
                                <li>
                                    <p>수령인</p>
                                </li>
                                <li>
                                    <p>휴대폰&nbsp;번호</p>
                                </li>
                                <li>
                                    <p>배송지</p>
                                </li>
                                <li>
                                    <p>배송&nbsp;요청사항</p>
                                </li>
                                <li>
                                    <p>결제수단</p>
                                </li>
                                <li>
                                    <p>결제금액</p>
                                </li>
                            </TitleWrap>
                            <ContetnsWrap>
                                <li>
                                    <p>{data.payments[0].paymentId}</p>
                                </li>
                                <li>
                                    <p>{data.recipient}</p>
                                </li>
                                <li>
                                    <p>{data.phone}</p>
                                </li>
                                <li>
                                    <p>{data.address + ', ' + data.adrDetail}</p>
                                </li>
                                <li>
                                    <p>{data.request}</p>
                                </li>
                                <li>
                                    <p>
                                        {data.payments[0].method === 'card'
                                            ? '신용카드'
                                            : data.payments[0].method === 'naverpay'
                                            ? '네이버페이'
                                            : '카카오페이'}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        ₩&nbsp;
                                        {data.payments[0].price > 30000 ? 0 : formPrice(3000)}
                                    </p>
                                </li>
                            </ContetnsWrap>
                        </FlexWrap>
                    </MypageOrderInfo>
                    <Border />
                    <p>운송장번호입력</p>
                    <FlexWrap>
                        <Input
                            type="text"
                            placeholder="운송장번호 입력"
                            value={trackingNumber}
                            onChange={(e) => setTrackingNumber(e.target.value)}
                        />
                        <BlackBtn type="button" style={{ maxWidth: '200px' }} onClick={updateHandler}>
                            등록
                        </BlackBtn>
                    </FlexWrap>
                    <Border />
                    {data.goods.map((v, i) => (
                        <FlexWrap key={i}>
                            <TitleWrap>
                                <li>
                                    <p>제품명</p>
                                </li>
                                <li>
                                    <p>금액</p>
                                </li>
                                <li>
                                    <p>수량</p>
                                </li>
                            </TitleWrap>
                            <ContetnsWrap>
                                <li>
                                    <p>{v.goodsName}</p>
                                </li>
                                <li>
                                    <p> ₩ {v.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                                </li>
                                <li>
                                    <p>{data.orderItems[i].amount}개</p>
                                </li>
                            </ContetnsWrap>
                        </FlexWrap>
                    ))}
                    <div style={{ height: '20px' }} />
                    <BlackBtn type="button" onClick={() => navigate(-1)}>
                        목록으로
                    </BlackBtn>
                </AdminPostDiv>
            )}
        </div>
    );
};

export default APaymentDetail;
