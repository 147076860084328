import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import SubDetail from "../../../components/common/SubDetail";

const WalletsDetail = () => {
  const params = useParams();

  const [data, setData] = useState("");

  params.goodsId &&
    !data &&
    axios.get("/api/goods/" + params.goodsId).then((res) => {
      setData(res.data.data);
    });

  console.log(data);
  return <SubDetail data={data}></SubDetail>;
};

export default WalletsDetail;
