import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { dateFormat } from '../../../const/dateFormat';

import sortList from '../../../const/SortList';
import { AdminListDiv } from '../../../components/admin/index';
import { useSelector } from 'react-redux';
import { BlackBtn } from '../../../components/common/BlackBtn';
import categoryName from '../../../const/CategoryList';

const AdminGoodsList = () => {
    const navigate = useNavigate();
    const adminData = useSelector((state) => state.admin);

    const [data, setData] = useState(Array);
    const [sort, setSort] = useState(sortList[0]);

    if (!adminData) navigate('/');

    useEffect(() => {
        data.length === 0 &&
            axios
                .get('/api/goods?page=0&sort=1&size=10')
                .then((res) => {
                    setData(res.data.dto.content);
                    console.log(res.data.dto.content);
                })
                .catch((e) => console.log(e));
    }, []);

    const onDeleteHandler = async (e, id) => {
        if (!window.confirm('정말로 삭제하시겠습니까?')) return false;

        await axios
            .delete('/api/goods/' + id)
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate('/admin/goods');
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));
    };
    return (
        <AdminListDiv>
            <h5>제품 리스트</h5>
            <div className="right-btn">
                <BlackBtn type="button" onClick={() => navigate('/admin/goods/post')}>
                    <p>제품 추가</p>
                </BlackBtn>
            </div>
            <div className="admin-goods-card">
                <p>No</p>
                <p>제품명</p>
                <p>가격</p>
                <p>카테고리</p>
                <p>옵션</p>
                <p>리뷰수</p>
                <p>등록일</p>
                <p>삭제</p>
            </div>
            {data.length > 0 &&
                data.map((v, i) => (
                    <div key={i} className="admin-goods-card" onClick={() => navigate(`/admin/goods/${v.id}`)}>
                        <p>{i + 1}</p>
                        <p>{v.goodsName}</p>
                        <p>{v.price}원</p>
                        <p>
                            {v.showGoods.length > 0 &&
                                v.showGoods.map((j, k) => <div key={k}>{categoryName(j.categoryId)}</div>)}
                        </p>
                        <p>{v.options.length > 0 ? 'Y' : 'N'}</p>
                        <p>{v.reviews.length}</p>
                        <p>{dateFormat(v.created)}</p>
                        <button onClick={(e) => onDeleteHandler(e, v.id)} style={{ zIndex: 10 }}>
                            <img alt="icon" src="/images/icon_trash.svg" width={19} height={19} />
                        </button>
                    </div>
                ))}
        </AdminListDiv>
    );
};

export default AdminGoodsList;
