import styled from 'styled-components';

export const ShipmentList = styled.div`
    position: relative;
    width: 100%;
    max-width: 500px;
    height: fit-content;

    display: grid;
    gap: 10px;

    margin: 30px 0;

    .br {
        height: 6px;
    }

    .ship-btn {
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px solid var(--main-color);
        border-radius: 3px;
        padding: 10px;

        .flex {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 5px;

            button {
                border: 1px solid var(--light-color);
                border-radius: 3px;
                padding: 5px 15px;
                font-size: small;
            }
        }

        .baseAdr {
            position: relative;
            width: fit-content;
            height: fit-content;
            border: 1px solid var(--light-color);
            border-radius: 30px;
            padding: 1px 5px;
            color: var(--light-color);
            font-size: x-small;
            font-weight: 400;
        }

        p {
            font-size: medium;
        }

        small {
            font-size: small;
            font-weight: 400;
        }
    }
`;

export const ShipmentEmpty = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;

    p {
        margin: 30px 0;
        font-size: medium;
        font-weight: 500;
    }
`;
