import { createSlice } from '@reduxjs/toolkit';

const paymentGoodsSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        insertPayment: (state, action) => {
            state.push(action.payload);
        },
        deletePayment(state, action) {
            state = [];
        },
    },
});

export let { insertPayment, deletePayment } = paymentGoodsSlice.actions;
export default paymentGoodsSlice.reducer;
