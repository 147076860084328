import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

const LoginButton = ({ type, func, disabled }) => {
    const navigate = useNavigate();

    let buttontxt = '';
    let link = '';
    switch (type) {
        case 'kakao':
            buttontxt = '카카오 로그인';
            link = '/';
            break;
        case 'naver':
            buttontxt = '네이버 로그인';
            link = '/';
            break;
        case 'singup':
            buttontxt = '간편 회원가입';
            link = '/verification';
            break;
        case 'certification':
            buttontxt = '본인 인증하기';
            link = '/sign-up';
            break;
        case 'signupComplete':
            buttontxt = '가입하기';
            link = '/complete';
            break;
        case 'disable':
            buttontxt = '가입하기';
            break;
        case 'shopping':
            buttontxt = '쇼핑하러 가기';
            link = '/';
            break;
        case 'main':
            buttontxt = '쇼핑 계속하기';
            link = '/';
            break;
        case 'select':
            buttontxt = '주문내역 조회하기';
            link = '/mypage/order';
            break;
        case 'order':
            buttontxt = '저장하고 다음 단계로';
            link = '/payment/shipment-info';
            break;
        default:
            buttontxt = '로그인';
            break;
    }
    return (
        <LoginFormButton
            type={'button'}
            disabled={disabled}
            property={type}
            onClick={() => (func ? func() : navigate(link))}
        >
            {buttontxt}
        </LoginFormButton>
    );
};

export default LoginButton;

export const LoginFormButton = styled.button`
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 14px 0;
    text-align: center;
    background-color: ${(props) =>
        props.property === 'kakao'
            ? '#FEE500'
            : props.property === 'naver'
            ? '#03c75a'
            : props.property === 'singup' || props.property === 'main'
            ? 'white'
            : 'var(--main-color)'};
    color: ${(props) =>
        props.property === 'kakao' || props.property === 'singup' || props.property === 'main'
            ? 'var(--main-color)'
            : 'white'};
    border: 1px solid
        ${(props) =>
            props.property === 'kakao' ? '#FEE500' : props.property === 'naver' ? '#2DB400' : 'var(--main-color)'};
    border-radius: 3px;

    margin-bottom: 10px;

    font-size: var(--font-size-sm);

    @media screen and (max-width: 700px) {
        padding: 14px 0;

        font-weight: 600;
    }
`;
