import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

import formPrice from '../../const/formPrice';
import { GridDiv, GridInnerDiv, WrapperDiv } from './BestSeller';

const AllItems = ({ data }) => {
    return (
        <WrapperDiv>
            <h5>모든 아이템</h5>
            <GridDiv>
                {data.length > 0 &&
                    data.map((v, i) => (
                        <GridInnerDiv
                            key={i}
                            display={i === 2 ? 'none' : 'grid'}
                            onClick={() => (window.location.href = `/best-seller/${v.goods.id}`)}
                        >
                            <div className="img-wrap">
                                <img alt="icon" src={`${process.env.REACT_APP_DOMAIN}${v.goods.thumb_src}`} />
                            </div>
                            <p className="good-name" style={{ fontWeight: '500' }}>
                                {v.goods.goodsName}
                            </p>
                            <p>₩ {formPrice(v.goods.price)}</p>
                        </GridInnerDiv>
                    ))}
            </GridDiv>
            <Link to={'/all-items'}>
                <button>
                    <p>더보기 →</p>
                </button>
            </Link>
        </WrapperDiv>
    );
};

export default AllItems;
