import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminListDiv } from '../../../components/admin';
import { BlackBtn } from '../../../components/common/BlackBtn';

const AOpt = () => {
    const navigate = useNavigate();

    const [data, setData] = useState(Array);

    useEffect(() => {
        data.length === 0 &&
            axios
                .get('/api/opt/category')
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto);
                        console.log(res.data);
                    }
                })
                .catch((e) => alert('다시 시도해주세요.\n' + e));
    }, []);
    return (
        <AdminListDiv>
            <h5>옵션 리스트</h5>
            <div className="right-btn">
                <BlackBtn type="button" onClick={() => navigate('/admin/option/post')}>
                    <p>옵션 추가</p>
                </BlackBtn>
            </div>
            <div className="admin-opt-card">
                <p>No</p>
                <p>카테고리명</p>
                <p>옵션명</p>
                <p>추가금액</p>
            </div>
            {data.map((v, i) => (
                <div key={i} onClick={() => navigate('/admin/option/' + v.id)}>
                    {v.opts.map((d, j) => (
                        <div key={j} className="admin-opt-inner">
                            <p>{j < 1 && v.id}</p>
                            <p>{j < 1 && v.categoryName}</p>
                            <p>{d.optName}</p>
                            <p>{d.price}</p>
                        </div>
                    ))}
                </div>
            ))}
        </AdminListDiv>
    );
};

export default AOpt;
