import React from "react";

import OrderInfoContents from "../../../components/payment/OrderInfo";
import { PaymentWrap } from "../../../components/payment/ShipInfo";
const OrderInfo = () => {
  return (
    <PaymentWrap>
      <p className="title">주문자 정보를 확인해 주세요</p>
      <OrderInfoContents />
    </PaymentWrap>
  );
};

export default OrderInfo;
