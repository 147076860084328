import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { AdminGoodsDiv } from '../../../../components/admin';
import { BlackBtn } from '../../../../components/common/BlackBtn';
import { CheckBox } from '../../../../components/login/CheckCondition';

const AGoodsDetail = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { adminData } = useOutletContext();

    const [optData, setOptData] = useState(Array);
    const [data, setData] = useState('');

    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);
    const [contents, setContents] = useState('');
    const [thumb, setThumb] = useState(null);
    const [thumbPrv, setThumbPrv] = useState('');
    const [optCategory, setOptCategory] = useState(0);
    const [deleteArr, setDeleteArr] = useState([]);

    const [bag, setBag] = useState(false);
    const [wallet, setwallet] = useState(false);
    const [accessory, setAccessory] = useState(false);
    const [best, setBest] = useState(false);
    const [all, setAll] = useState(false);

    const arrayEight = new Array(8).fill(Array);
    const eightArr = new Array(8).fill('');

    const [goodsImg, setGoodsImg] = useState(arrayEight);

    const [goodsImgPrev, setGoodsImgPrev] = useState(eightArr);

    optData.length === 0 &&
        axios
            .get('/api/opt/category')
            .then((res) => {
                if (res.data.result === '0000') {
                    setOptData(res.data.dto);
                    console.log(res.data.dto);
                }
            })
            .catch((e) => console.log(e));

    useEffect(() => {
        params.goodsId &&
            !data &&
            axios
                .get('/api/goods/admin/' + params.goodsId)
                .then((res) => {
                    console.log(res.data.data);
                    setData(res.data.data);

                    setName(res.data.data.goodsName);
                    setPrice(res.data.data.price);
                    setContents(res.data.data.contents);
                    setThumb(res.data.data.thumb_src);
                    setThumbPrv('https://www.competoseoul.com' + res.data.data.thumb_src);

                    res.data.data.showGoods.map((v) =>
                        v.categoryId == 1
                            ? setBag(true)
                            : v.id == 2
                            ? setwallet(true)
                            : v.id == 3
                            ? setAccessory(true)
                            : v.id == 4
                            ? setBest(true)
                            : v.id == 5
                            ? setAll(true)
                            : null
                    );

                    for (let i = 0; i < res.data.data.goodsImgDtos.length; i++) {
                        goodsImg[i] = res.data.data.goodsImgDtos[i];
                        goodsImgPrev[i] = 'https://www.competoseoul.com' + res.data.data.goodsImgDtos[i].src;
                    }
                })
                .catch((e) => console.log(e));
    }, [data]);

    const formdata = new FormData();

    const createGoodsHandler = async () => {
        formdata.append('name', name);
        formdata.append('price', price);
        formdata.append('contents', contents);
        formdata.append('thumb', thumb);
        formdata.append('adminId', adminData.id);

        formdata.append('bag', bag);
        formdata.append('wallet', wallet);
        formdata.append('accessory', accessory);
        formdata.append('best', best);
        formdata.append('all', all);

        for (let i = 0; i < goodsImg.length; i++) {
            formdata.append('files', goodsImg[i]);
        }

        formdata.append('adminId', 1);
        formdata.append('optCategory', optCategory);

        for (let value of formdata.values()) {
            console.log('value: ' + value);
        }

        console.log(formdata.getAll('files'));

        await axios
            .post('/api/goods/post', formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate('/admin/goods');
                } else {
                    alert('다시 시도해주세요.');
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));
    };

    const updateGoodsHandler = async () => {
        formdata.append('name', name);
        formdata.append('price', price);
        formdata.append('contents', contents);
        formdata.append('thumb', thumb);
        formdata.append('adminId', adminData.id);

        formdata.append('bag', bag);
        formdata.append('wallet', wallet);
        formdata.append('accessory', accessory);
        formdata.append('best', best);
        formdata.append('all', all);

        formdata.append('deleteImg', deleteArr);

        // for (let i = 0; i < deleteArr.length; i++) {
        //     formdata.append('deleteImg', deleteArr[i]);
        // }

        for (let i = 0; i < goodsImg.length; i++) {
            formdata.append('files', goodsImg[i]);
        }

        formdata.append('adminId', 1);
        formdata.append('optCategory', optCategory);

        console.log(deleteArr);

        for (let value of formdata.values()) {
            console.log('value: ' + value);
        }

        console.log(formdata.getAll('files'));

        await axios
            .put('/api/goods/' + params.goodsId, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate('/admin/goods');
                } else {
                    alert('다시 시도해주세요.');
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));
    };

    useEffect(() => {
        // console.log(goodsImg);
    }, [goodsImg, data]);

    return (
        <AdminGoodsDiv>
            <h5>제품 {data ? '수정' : '추가'}</h5>
            <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="제품명"
                required
            />
            <input
                type="number"
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="금액"
                required
            />
            <textarea
                name="contents"
                value={contents}
                onChange={(e) => setContents(e.target.value)}
                placeholder="제품설명"
            />
            <div className="check-wrap">
                <div className="check" onClick={() => setBag(!bag)}>
                    <CheckBox type="checkbox" name="bag" id="bag" checked={bag} />

                    <label htmlFor="bag">
                        <label className="checkbox"></label>
                        가방
                    </label>
                </div>
                <div className="check" onClick={() => setwallet(!wallet)}>
                    <CheckBox type="checkbox" name="wallet" id="wallet" checked={wallet} />

                    <label htmlFor="wallet">
                        <label className="checkbox"></label>
                        지갑
                    </label>
                </div>
                <div className="check" onClick={() => setAccessory(!accessory)}>
                    <CheckBox type="checkbox" name="accessory" id="accessory" checked={accessory} />

                    <label htmlFor="accessory">
                        <label className="checkbox"></label>
                        악세서리
                    </label>
                </div>
                <div className="check" onClick={() => setBest(!best)}>
                    <CheckBox type="checkbox" name="best" id="best" checked={best} />

                    <label htmlFor="best">
                        <label className="checkbox"></label>
                        베스트아이템
                    </label>
                </div>
                <div className="check" onClick={() => setAll(!all)}>
                    <CheckBox type="checkbox" name="all" id="all" checked={all} />

                    <label htmlFor="all">
                        <label className="checkbox"></label>
                        모든아이템
                    </label>
                </div>
            </div>
            <input
                type="file"
                accept=".png,.jpeg,.jpg,.svg"
                onChange={(e) => {
                    setThumb(e.target.files[0]);

                    let fileRead = new FileReader();
                    fileRead.onload = () => {
                        setThumbPrv(fileRead.result);
                    };
                    fileRead.readAsDataURL(e.target.files[0]);
                }}
                required
            />
            <div className="preview-wrap">{thumbPrv && <img className="preview" alt="preview" src={thumbPrv} />}</div>

            <div className="flex-input">
                {eightArr.map((v, i) => (
                    <div key={i}>
                        <label htmlFor={`file-input${i}`}>
                            <p>
                                {typeof goodsImg[i] === 'function'
                                    ? '파일 선택'
                                    : data && typeof goodsImg[i] === 'object' && data.goodsImgDtos[i] === goodsImg[i]
                                    ? goodsImg[i].originName
                                    : document.querySelector(`.file-input${i}`).value}
                            </p>
                        </label>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            className={`file-input${i}`}
                            id={`file-input${i}`}
                            accept=".png,.jpeg,.jpg,.svg"
                            required={i < 2 ? true : false}
                            onClick={(e) => {
                                if (!e.target.value) {
                                    if (goodsImg[i].id) deleteArr.push(goodsImg[i].id);

                                    let imgList = [...goodsImg];
                                    imgList[i] = Array;
                                    setGoodsImg(imgList);

                                    let prevList = [...goodsImgPrev];
                                    prevList[i] = '';
                                    setGoodsImgPrev(prevList);
                                }
                            }}
                            onChange={(e) => {
                                if (e.target.value.length) {
                                    let imgList = [...goodsImg];
                                    imgList[i] = e.target.files[0];

                                    setGoodsImg(imgList);
                                    let fileRead = new FileReader();

                                    fileRead.onload = () => {
                                        let prevList = [...goodsImgPrev];
                                        prevList[i] = fileRead.result;
                                        setGoodsImgPrev(prevList);
                                    };
                                    fileRead.readAsDataURL(e.target.files[0]);
                                } else {
                                    let imgList = [...goodsImg];
                                    imgList[i] = Array;
                                    setGoodsImg(imgList);

                                    let prevList = [...goodsImgPrev];
                                    prevList[i] = '';
                                    setGoodsImgPrev(prevList);
                                }
                            }}
                        />
                        {goodsImgPrev[i] && (
                            <img
                                alt="preview"
                                className="preview"
                                src={goodsImgPrev[i]}
                                onClick={() => {
                                    let tempArr = goodsImgPrev;
                                    tempArr[i] = null;
                                    let tempImg = goodsImg;
                                    tempImg[i] = null;
                                    setGoodsImg(tempImg);
                                    setGoodsImgPrev(tempArr);
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div style={{ height: '30px' }}></div>
            <select onChange={(e) => setOptCategory(e.target.value)}>
                <option id="0">옵션비활성화</option>
                {optData.length > 0 &&
                    optData.map((v, i) => (
                        <option key={i} value={v.id}>
                            {v.categoryName}
                        </option>
                    ))}
            </select>
            {data ? (
                <BlackBtn type="button" onClick={updateGoodsHandler}>
                    수정
                </BlackBtn>
            ) : (
                <BlackBtn type="button" onClick={createGoodsHandler}>
                    저장
                </BlackBtn>
            )}
        </AdminGoodsDiv>
    );
};

export default AGoodsDetail;
