import React, { useState } from 'react';
import { AdminPostDiv } from '../../../../components/admin';
import { Input } from '../../../../components/common/Input';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { BlackBtn } from '../../../../components/common/BlackBtn';

const ABannerDetail = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [img, setImg] = useState('');
    const [url, seturl] = useState('');
    const [txt1, setTxt1] = useState('');
    const [txt2, setTxt2] = useState('');
    const [data, setData] = useState('');

    useEffect(() => {
        !data &&
            params.bannerId &&
            axios
                .get('/api/banner/' + params.bannerId, {
                    img: img,
                    url: url,
                    txt1: txt1,
                    txt2: txt2,
                })
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto);
                        setImg(res.data.dto.img);
                        setTxt1(res.data.dto.txt1);
                        setTxt2(res.data.dto.txt2);
                        seturl(res.data.dto.url);
                    }
                })
                .catch((e) => alert('다시 시도해주세요.\n' + e));
    }, [data]);

    const updateCouponHandler = async () => {
        if (!img || !url || !txt1) {
            alert('모두 입력해주세요.');
            return false;
        }

        await axios
            .put('/api/banner', {
                id: params.bannerId,
                img: img,
                url: url,
                txt1: txt1,
                txt2: txt2,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate('/admin/banners');
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));
    };

    return (
        <div>
            <h5>배너 수정</h5>
            <AdminPostDiv>
                <p>
                    이미지는 FTP 다운로드 후
                    <br />
                    호스트 : iup.cdn1.cafe24.com
                    <br />
                    사용자 : competoseoul
                    <br />
                    비밀번호 : smileean1! 에 업로드 후 해당 이미지 링크를 입력해주세요.
                    <br />
                    http://competoseoul.cdn1.cafe24.com/ + 업로드한 이미지명
                </p>

                <p>이미지</p>
                <Input type="text" placeholder="이미지 링크" value={img} onChange={(e) => setImg(e.target.value)} />

                <p>연결 링크</p>
                <Input type="text" placeholder="연결될 링크" value={url} onChange={(e) => seturl(e.target.value)} />

                <p>윗 줄 텍스트</p>
                <Input type="text" placeholder="텍스트1" value={txt1} onChange={(e) => setTxt1(e.target.value)} />

                <p>아랫줄 텍스트</p>
                <Input type="text" placeholder="텍스트2" value={txt2} onChange={(e) => setTxt2(e.target.value)} />
            </AdminPostDiv>

            <BlackBtn type="button" onClick={updateCouponHandler}>
                수정
            </BlackBtn>
        </div>
    );
};

export default ABannerDetail;
