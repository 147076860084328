import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import styled from "styled-components";
import Popup from "../popup/Popup";

const HeaderLine = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <HeaderLineWrapper onClick={() => setOpen(true)}>
        <div className="swiper-wrap">
          <Swiper
            centeredSlides={true}
            slidesPerView={1}
            speed={800}
            autoplay={{
              delay: 3000,
              pauseOnMouseEnter: false,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            loop={true}
            className="mySwiper"
          >
            <SwiperSlide>
              <p>
                &nbsp;무료&nbsp;배송&nbsp;&
                선물&nbsp;포장&nbsp;서비스&nbsp;혜택&nbsp;
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <p>
                &nbsp;블랙&nbsp;진&nbsp;파우치&nbsp;선물포장&nbsp;혜택&nbsp;
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
      </HeaderLineWrapper>
      <Popup open={open} set={setOpen} />
    </>
  );
};

export default HeaderLine;

const HeaderLineWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 25px;
  top: 0;

  display: ${(props) => (props.$close ? "none" : "grid")};
  align-items: center;

  background-color: var(--main-color);
  color: white;

  z-index: 3;

  .mySwiper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  p {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    font-size: var(--font-size-sm);
    font-weight: 500;
    z-index: 5;
    text-decoration: underline;
    text-underline-offset: 2px;
    display: flex;
    align-items: top;

    text-decoration-color: var(--main-color);

    cursor: pointer;

    &:hover {
      text-decoration-color: white;

      transition: all 0.5s;

      .plus {
        opacity: 1;
      }
    }
  }

  .swiper-wrap {
    position: relative;
    width: fit-content;
    max-width: 600px;
    height: fit-content;
    margin: 0 auto;
    display: grid;
    align-items: center;
  }
`;

const HeaderLineX = styled.button`
  position: absolute;
  top: 0;
  right: var(--wrapper-padding);
  width: fit-content;
  height: 100%;
  display: grid;
  align-items: center;

  img {
    position: relative;
    width: 10px;
    height: auto;
    object-fit: contain;
  }
`;
