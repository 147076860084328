import React from 'react';

import LeftNav, { WrapperInner, ContetnsWrapper } from '../../../components/legalNotice/LeftNav';
import YContentsText2 from '../../../components/legalNotice/YContentsText2';

const Cookie = () => {
    return (
        <WrapperInner>
            <LeftNav />
            <ContetnsWrapper>
                <YContentsText2/>
            </ContetnsWrapper>
        </WrapperInner>
    );
};

export default Cookie;
