import React from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import UserNav from './UserNav';

const UserLayout = () => {
    const { pathname } = useLocation();

    return (
        <UserWarp>
            <UserNav path={pathname} />
            <Outlet />
        </UserWarp>
    );
};

export default UserLayout;

const UserWarp = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 70vh;
    padding: 120px var(--wrapper-padding);

    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 140px;

    @media screen and (max-width: 700px) {
        display: block;

        padding: 20px var(--wrapper-padding);

        .title {
            font-weight: 600;
        }
    }
`;
