import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: 0,
    email: '',
    adminName: '',
};

const adminSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        setAdmin: (state, action) => {
            state.id = action.payload.id;
            state.email = action.payload.email;
            state.adminName = action.payload.adminName;
            state.removeAdmin = action.payload.removeAdmin;
        },
        removeAdmin: (state) => {
            state.id = 0;
            state.email = '';
            state.adminName = '';
        },
    },
});

export const { setAdmin, removeAdmin } = adminSlice.actions;
export default adminSlice.reducer;
