import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import MobileMenu from '../../../components/user/MobileMenu';
import UserComponent, { MainContents } from '../../../components/user/UserComponent';
import axios from 'axios';

const Mypage = () => {
    let member = useSelector((state) => state.member);

    const [data, setData] = useState('');

    useEffect(() => {
        !data &&
            axios
                .get(`/api/order/${member.id}/counting`)
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto);
                        console.log(res.data.dto);
                    }
                })
                .catch((e) => console.log(e));
    }, [data]);

    return (
        <div>
            <p className="title">반갑습니다, {member.memberName}님</p>
            <MainContents>
                <p className="mobile-hidden">주문내역</p>
                <div style={{ height: '10px' }} />
                <UserComponent data={data} />
                <MobileMenu />
            </MainContents>
        </div>
    );
};

export default Mypage;
