import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-std-dynamic-subset.min.css");

    :root {
        --background-color: 255, 255, 255;
        --light-color: #CDCDCD;
        --main-color: #151414;
        
        --font-size-h5: 35px;

        --font-size-p: 18px;
        --font-size-sm: 14px;
        --font-size-xs: 10px;

        --wrapper-padding: 40px;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Pretendard Std',sans-serif;
        font-weight: 600;

        // user-select: none;
    }

    html, body {
        font-size: var(--font-size-p);

        word-break: keep-all;

        color: var(--main-color);
        cursor: default;
    }

    
    input,
    textarea,
    select {
        outline: none;
        background-color: transparent;
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px solid var(--main-color);
        border-radius: 3px;

        font-weight: 400;
    }

    select {
        -o-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-left: 8px!important;
      }

    select {
        background: url('/images/arrow.svg') calc(100% - 8px) center no-repeat;
        background-size: 10px;
        outline: 0 none;
      }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input::placeholder,
    textarea::placeholder,
    select::placeholder {
        color: var(--light-color);
    }

    input[type="checkbox"]:checked{
        display : none;
    }

    input[type="checkbox"]:checked + label > .checkbox::before {
        position: relative;
        content : "";
        width: 10px;
        height: 10px;
        border: 1px solid var(--main-color);
        margin-right: 5px;
        margin-top: -2.8px;

        background:url(/images/icon_checkOn.svg) no-repeat;
        background-size: 8px 8px;
        background-position: center;


        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        border-radius: 2.5px;
        user-select: none; /* 드래그 금지 */
    }


    menu, ol, ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    button, a{
        all: unset;
        cursor: pointer;
    }

    h5{
        font-size: var(--font-size-h5);
    }

    small {
        font-size: var(--font-size-sm);
    }

    .mobile-hidden {
        display: inline-block;
    }

    .mobile-visible {
        display: none;
    }

    .good-name {
        display: block;
        width: 95%;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (max-width: 700px) {
        :root {
            
            --font-size-h5: 19pt;
    
            --font-size-p: 11pt;
            --font-size-sm: 9pt;
            --font-size-xs: 8pt;
    
            --wrapper-padding: 16px;
        }

        .mobile-hidden {
            display: none;
        }

        .mobile-visible {
            display: block;
        }

        .good-name {
            margin-bottom: -3.5px;
        }
    }


    @media screen and (min-width: 501px) and (max-width: 1280px) {

        .mobile-hidden {
            display: none;
        }

        .mobile-visible {
            display: block;
        }
        
        :root {
            --wrapper-padding: 20px;
        }
    }


`;

export default GlobalStyles;
