import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const Review = () => {
    const params = useParams();
    const navigate = useNavigate();

    const member = useSelector((state) => state.member);

    const [data, setData] = useState('');
    const [contents, setContents] = useState('');
    const [score, setScore] = useState(0);
    const [imgYn, setImgYn] = useState('N');

    const threeArr = new Array(3).fill('');
    const [imgArr, setImgArr] = useState(threeArr);
    const [preview, setPreview] = useState(threeArr);

    const formdata = new FormData();

    const createGoodsHandler = async () => {
        formdata.append('contents', contents);
        formdata.append('score', score);
        formdata.append('imgYn', imgYn);
        formdata.append('goodsId', params.goodsId);
        formdata.append('memberId', member.id);
        formdata.append('orderID', params.orderId);

        for (let i = 0; i < imgArr.length; i++) {
            formdata.append('files', imgArr[i]);
        }

        for (let value of formdata.values()) {
            console.log('value: ' + value);
        }

        await axios
            .post('/api/review', formdata)
            .then((res) => {
                if (res.data.result === '0000') {
                    navigate(-1);
                } else {
                    alert('다시 시도해주세요.');
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));
    };

    return <div>PostReview</div>;
};

export default Review;
