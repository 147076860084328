import React from 'react';

import styled from 'styled-components';

const PaymentNav = ({ path }) => {
    return (
        <PaymentNavWrap className={path === '/payment/complete' ? 'mobile-hidden' : ''}>
            <NavList>
                <li className={path === '/payment/order-info' ? 'payment-here' : ''}>
                    <p>1 주문자 정보</p>
                </li>
                <li className={path === '/payment/shipment-info' ? 'payment-here' : ''}>
                    <p>2 배송 정보</p>
                </li>
                <li className={path === '/payment/payment-method' ? 'payment-here' : ''}>
                    <p>3 결제수단</p>
                </li>
                <li className={path === '/payment/complete' ? 'payment-here' : ''}>
                    <p>4 주문완료</p>
                </li>
            </NavList>
            <MobileNavList>
                <li className={path === '/payment/order-info' ? 'payment-here' : ''}>
                    <p>주문자 정보&nbsp;&nbsp;&gt;&nbsp;&nbsp;</p>
                </li>
                <li className={path === '/payment/shipment-info' ? 'payment-here' : ''}>
                    <p>배송 정보&nbsp;&nbsp;&gt;&nbsp;&nbsp;</p>
                </li>
                <li className={path === '/payment/payment-method' ? 'payment-here' : ''}>
                    <p>결제수단&nbsp;&nbsp;&gt;&nbsp;&nbsp;</p>
                </li>
                <li className={path === '/payment/complete' ? 'payment-here' : ''}>
                    <p>주문완료</p>
                </li>
            </MobileNavList>
        </PaymentNavWrap>
    );
};

export default PaymentNav;

const PaymentNavWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100px;

    margin-left: auto;
    @media screen and (max-width: 700px) {
        margin: 0;
        padding-bottom: 10px;
    }
`;

const NavList = styled.ul`
    position: relative;
    width: 100%;
    height: fit-content;

    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 40px;

    color: var(--light-color);

    .payment-here {
        color: var(--main-color);
    }

    @media screen and (max-width: 700px) {
        display: none;
    }
`;

const MobileNavList = styled.ul`
    position: relative;
    width: 100vw;
    height: fit-content;

    display: flex;

    margin-bottom: 10px;

    color: var(--light-color);

    .payment-here {
        color: var(--main-color);
    }

    p {
        font-weight: 500;
        font-size: var(--font-size-sm);
    }

    @media screen and (min-width: 501px) {
        display: none;
    }
`;
