import React from "react";

import { Link } from "react-router-dom";

import styled from "styled-components";

const ProductByCategory = () => {
  return (
    <GridDiv>
      <Link to={"/bags"}>
        <ImgDiv bg={"#F7F7F7"}>
          <img
            alt="icon"
            src="http://competoseoul.cdn1.cafe24.com/banner2.png"
            width={537}
            height={535}
          />
          <p>가방 컬랙션→</p>
        </ImgDiv>
      </Link>
      <Link to={"/wallets"}>
        <ImgDiv bg={"#F7F7F7"} className="contain">
          <img
            alt="icon"
            src="http://competoseoul.cdn1.cafe24.com/banner4.png"
            width={537}
            height={535}
          />
          <p>지갑 컬랙션 →</p>
        </ImgDiv>
      </Link>
      <Link to={"/accessories"}>
        <ImgDiv bg={"#F7F7F7"}>
          <img
            alt="icon"
            src="http://competoseoul.cdn1.cafe24.com/banner5.png"
            width={537}
            height={535}
          />
          <p>악세서리 컬랙션 →</p>
        </ImgDiv>
      </Link>
    </GridDiv>
  );
};

export default ProductByCategory;

const GridDiv = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;

  width: 100%;
  height: fit-content;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

export const ImgDiv = styled.div`
  position: relative;
  width: 100%;
  height: 0;

  display: grid;
  justify-items: center;
  align-items: center;

  padding-bottom: 138%;

  background-color: #f2f2f2;

  &.second-banner {
    position: relative;
    width: 100%;
    height: 0;

    display: grid;
    justify-items: center;
    align-items: center;

    padding-bottom: 92%;

    background-color: #f2f2f2;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    left: 0;
    top: 0;
  }

  .contain {
    img {
      object-fit: contain;
    }
  }

  .center-div {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    display: grid;
    align-items: center;
    justify-content: center;

    text-align: center;

    h5 {
      font-weight: bold;
    }

    button {
      position: relative;
      background-color: white;
      margin-top: 20px;
      padding: 10px 50px;
      border-radius: 3px;
    }

    p {
      position: relative;
      left: 0;
      bottom: 0;
    }
  }

  p {
    position: absolute;
    left: var(--wrapper-padding);
    bottom: var(--wrapper-padding);
    color: ${(props) => (props.color ? props.color : "var(--main-color)")};
  }

  .absolute-div {
    position: absolute;
    width: fit-content;
    left: var(--wrapper-padding);
    bottom: var(--wrapper-padding);
    color: ${(props) => (props.color ? props.color : "var(--main-color)")};

    p {
      position: relative;
      left: 0;
      bottom: 0;
    }

    small {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 700px) {
    max-width: 100vw;
    height: 430px;

    img {
      max-height: none;
      height: 100%;
    }

    &.second-banner {
      padding-bottom: 113%;
    }

    .center-div {
      button {
        margin-top: 10px;
        padding: 7px 35px;
      }
    }
  }
`;
