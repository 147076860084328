import React from 'react';

import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

const LeftNav = () => {
    const { pathname } = useLocation();

    console.log(pathname);
    return (
        <NavWrapper>
            <p>법적고지</p>
            <NavList>
                <li className={pathname === '/terms-of-use' ? 'on' : ''}>
                    <a href="/terms-of-use">이용약관</a>
                </li>
                <li className={pathname === '/privacy-policy' ? 'on' : ''}>
                    <a href="/privacy-policy">개인정보처리방침</a>
                </li>
                <li className={pathname === '/cookie-policy' ? 'on' : ''}>
                    <a href="/cookie-policy">쿠키 정책</a>
                </li>
            </NavList>
        </NavWrapper>
    );
};

export default LeftNav;

export const WrapperInner = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;

    width: 100%;
    height: 100%;

    max-width: 886px;

    margin: 0 auto;

    padding: 119px 0;

    @media screen and (max-width: 700px) {
        display: inline-block;
        padding: 80px var(--wrapper-padding);
    }
`;

const NavWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 240px;

    @media screen and (max-width: 700px) {
        display: flex;
        max-width: none;
    }
`;

const NavList = styled.ul`
    position: relative;

    width: 100%;
    height: fit-content;

    padding-top: 59px;
    font-size: var(--font-size-sm);

    text-align: left;

    li {
        padding-bottom: 40px;
    }

    a {
        font-weight: 500;
    }

    .on {
        text-decoration: underline;
    }

    @media screen and (max-width: 700px) {
        display: grid;
        width: fit-content;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
        padding-top: 0;
    }
`;

export const ContetnsWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    padding-top: 82px;

    @media screen and (max-width: 700px) {
        padding-top: 40px;
    }
`;
