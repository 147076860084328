import React from 'react';
import { PagenationDiv } from '../user/UserComponent';

const Pagination = ({ totalPage, curr, set }) => {
    const keyboard = Array.from({ length: totalPage }, (v, i) => i + 1);

    return (
        <PagenationDiv>
            <button type="button" disabled={curr === 0 ? true : false} onClick={() => curr > 0 && set(curr - 1)}>
                <img alt="icon" src="/images/icon_arrow.svg" />
            </button>
            <div>
                {keyboard &&
                    keyboard.map((v, i) => (
                        <p
                            key={i}
                            onClick={() => set(v - 1)}
                            style={curr !== v - 1 ? { color: 'var(--light-color)' } : { color: 'var(--main-color)' }}
                        >
                            {v}
                        </p>
                    ))}
            </div>
            <button
                type="button"
                disabled={curr + 1 === totalPage ? true : false}
                onClick={() => curr < totalPage && set(curr + 1)}
            >
                <img alt="icon" src="/images/icon_arrow.svg" style={{ transform: 'rotate(180deg)' }} />
            </button>
        </PagenationDiv>
    );
};

export default Pagination;
