import React, { useEffect, useState } from 'react';
import axios from 'axios';

import styled from 'styled-components';

import SubTitle from '../../components/common/SubTitle';
import SubCard from '../../components/common/SubCard';
import { FlexDiv, WrapperDiv } from '../../components/home/BestSeller';
import sortList from '../../const/SortList';
import CustomSelect from '../../components/common/CustomSelect';

const Wallets = () => {
    const [data, setData] = useState(Array);
    const [sort, setSort] = useState(sortList[0]);

    useEffect(() => {
        data.length === 0 &&
            axios
                .get(`/api/goods/category?categoryId=2&page=0&sort=${sort.id}&size=10`)
                .then((res) => {
                    setData(res.data.dto.content);
                })
                .catch((e) => console.log(e));
    }, [sort]);
    return (
        <div style={{ position: 'relative', width: '100%', height: 'fit-content' }}>
            <SubTitle title="지갑" />
            <WrapperDiv>
                <FlexDiv>
                    <p>
                        지갑&nbsp;|&nbsp;
                        <span>콤페토에서 전개하는 모든 지갑 컬렉션</span>
                    </p>
                    <CustomSelect sort={sort} setSort={setSort} />
                </FlexDiv>
                <GridDiv>
                    {data[0] &&
                        data[0].goods &&
                        data.map((v, i) => (
                            <SubCard
                                key={i}
                                link={'/wallets/' + v.goods.id}
                                img={v.goods.thumb_src}
                                price={v.goods.price}
                                title={v.goods.goodsName}
                            />
                        ))}
                </GridDiv>
            </WrapperDiv>
        </div>
    );
};

export default Wallets;

export const GridDiv = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    max-width: 100vw;

    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr 1fr;

        padding: 20px 0;

        gap: 10px;
    }
`;
