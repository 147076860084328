import React, { useEffect, useState } from 'react';

import axios from 'axios';

import AllItems from '../../components/home/AllItems';
import Banner, { HomeWrapDiv } from '../../components/home/Banner';
import BestSeller from '../../components/home/BestSeller';
import HomeVideo from '../../components/home/HomeVideo';
import ProductByCategory from '../../components/home/ProductByCategory';
import SecondBanner from '../../components/home/SecondBanner';

import { MainSeo } from '../../const/metatag';

const Home = () => {
    const [banner, setBanner] = useState(Array);

    useEffect(() => {
        banner.length < 1 &&
            axios.get('/api/banner').then((res) => {
                if (res.data.result === '0000') {
                    setBanner(res.data.dto);
                }
            });
    }, []);

    const [allItem, setAllItem] = useState(Array);
    useEffect(() => {
        allItem.length === 0 &&
            axios
                .get(`/api/goods/category?categoryId=4&page=0&sort=1&size=3`)
                .then((res) => {
                    setAllItem(res.data.dto.content);
                    console.log('allitem:', res.data.dto.content);
                })
                .catch((e) => console.log(e));
    }, []);

    const [best, setbest] = useState(Array);
    useEffect(() => {
        best.length === 0 &&
            axios
                .get(`/api/goods/category?categoryId=5&page=0&sort=1&size=3`)
                .then((res) => {
                    setbest(res.data.dto.content);
                    console.log('best:', res.data.dto.content);
                })
                .catch((e) => console.log(e));
    }, []);
    return (
        <HomeWrapDiv>
            <MainSeo />
            <Banner data={banner} />
            <HomeVideo />
            <BestSeller data={best} />
            <ProductByCategory />
            <AllItems data={allItem} />
            <SecondBanner data={banner[2]} />
        </HomeWrapDiv>
    );
};

export default Home;
