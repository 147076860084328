import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { HelmetProvider } from "react-helmet-async";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

import { BrowserRouter } from "react-router-dom";
import RouteScroll from "./components/common/RouteScroll";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(document.getElementById("root"));

if (container.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    container,
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <RouteScroll />
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <RouteScroll />
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  );
}

reportWebVitals();
