import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

import memberReducer from './memberSlice';
import cartReducer from './cartSlice';
import payCartReducer from './PayCartSlice';
import adminReducer from './AdminSlice';
import totalPriceReducer from './PaymentPriceSlice';

const rootReducer = combineReducers({
    member: memberReducer,
    cart: cartReducer,
    admin: adminReducer,
    paymentGoods: payCartReducer,
    price: totalPriceReducer,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['member', 'admin', 'cart'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            logger: logger,
        }),
});

export default store;

export const persistor = persistStore(store);
