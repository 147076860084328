import styled from 'styled-components';

export const VerifDiv = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 80vh;
    max-width: 406px;

    padding: 120px 0;
    margin: 0 auto;

    @media screen and (max-width: 700px) {
        padding: 40px var(--wrapper-padding);
        max-width: 690px;
    }

    .br {
        position: relative;
        width: 100%;
        height: 20px;
    }

    .signupTxt {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 20px;
        padding-bottom: 40px;

        small {
            font-size: var(--font-size-sm);
            font-weight: 500;
        }
    }

    .warning {
        font-size: var(--font-size-sm);
        font-weight: 500;
        color: red;
    }

    .inputDiv {
        p {
            font-size: var(--font-size-xs) !important;
            font-weight: 500;
            margin-bottom: 10px;
        }

        input[type='text'],
        input[type='password'] {
            position: relative;
            font-weight: 500;
            width: 100%;
            height: 45px;
            border: 1px solid var(--main-color);
            padding: 0 10px;
        }
    }

    .popup-bg {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        background-color: rgba(0, 0, 0, 0.5);

        z-index: 52;
    }

    .popup {
        position: fixed;
        top: 150px;
        left: calc(50vw - 350px);
        width: 100%;
        height: fit-content;
        max-width: 700px;

        z-index: 53;

        background-color: white;

        margin: 0 auto;
        padding: 30px;

        border-radius: 30px;

        .flex-between {
            position: relative;
            width: 100%;
            height: fit-content;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo {
                position: relative;
                width: 160px;
                height: auto;
                object-fit: contain;
            }
        }

        .txt {
            margin: 30px 0;

            p {
                font-size: larger;
                margin-bottom: 30px;
            }
            small {
                font-size: medium;
                line-height: 25px;
                color: #787878;
            }
        }

        .input {
            position: relative;
            width: 100%;
            height: fit-content;

            display: flex;
            justify-content: center;
            gap: 10px;

            input {
                position: relative;
                width: 50px;
                height: 50px;
                border-radius: 5px;
                text-align: center;

                font-size: 14pt;

                border: 1px solid var(--light-color);

                &.on {
                    border: 1px solid var(--main-color);
                }
            }
        }

        .buttonDiv {
            display: flex;
            justify-content: flex-end;
            gap: 15px;
            margin-top: 50px;
        }
    }

    @media screen and (max-width: 700px) {
        .popup {
            left: 0;
            right: 0;
            width: 100%;
        }
    }
`;
