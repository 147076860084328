import React, { useEffect, useState } from 'react';
import axios from 'axios';

import SubTitle from '../../components/common/SubTitle';
import SubCard from '../../components/common/SubCard';
import { FlexDiv, WrapperDiv } from '../../components/home/BestSeller';
import { GridDiv } from '../Wallets';
import CustomSelect from '../../components/common/CustomSelect';
import sortList from '../../const/SortList';

const AllItmes = () => {
    const [data, setData] = useState(Array);
    const [sort, setSort] = useState(sortList[0]);

    useEffect(() => {
        axios
            .get(`/api/goods/category?categoryId=4&page=0&sort=${sort.id}&size=10`)
            .then((res) => {
                setData(res.data.dto.content);
            })
            .catch((e) => console.log(e));
    }, [sort]);

    useEffect(() => {
        data.length === 0 &&
            axios
                .get(`/api/goods/category?categoryId=4&page=0&sort=1&size=10`)
                .then((res) => {
                    setData(res.data.dto.content);
                    console.log(res.data.dto.content);
                })
                .catch((e) => console.log(e));
    }, []);
    return (
        <div style={{ position: 'relative', width: '100%', height: 'fit-content' }}>
            <SubTitle title="모든 아이템" />
            <WrapperDiv>
                <FlexDiv>
                    <p>
                        모든&nbsp;아이템&nbsp;|&nbsp;
                        <span>콤페토에서&nbsp;전개하는&nbsp;모든&nbsp;제품&nbsp;컬렉션</span>
                    </p>
                    <CustomSelect sort={sort} setSort={setSort} />
                </FlexDiv>
                <GridDiv>
                    {data[0] &&
                        data[0].goods &&
                        data.map((v, i) => (
                            <SubCard
                                key={i}
                                link={'/best-seller/' + v.goods.id}
                                img={v.goods.thumb_src}
                                price={v.goods.price}
                                title={v.goods.goodsName}
                            />
                        ))}
                </GridDiv>
            </WrapperDiv>
        </div>
    );
};
export default AllItmes;
