import React from "react";

import { LegalNotice } from "./YContentsText";

//개인정보처리방침
//제목 - <h2>, 소제목 - <h4>, 내용 - <p>
//소제목 번호 매겨져 있으나 <ol> 태그 사용시 css 작업 번잡할 것 같아 <h4> 태그 사용
//조항 있을 경우(숫자로 구분 - <ol>, 점으로 구분 - <ul>), 조항 안에 조항 있을 경우(=알파벳으로 구분) - <ool>
//표 셀병합시 행 병합 사용

const YContentsText1 = () => {
  return (
    <LegalNotice>
      <h2>개인정보처리방침</h2>
      <p>
        콤페토&#40;이하 '회사'&#41;는 개인정보보호법, 정보통신망 이용촉진 및
        정보보호 등에 관한 법률 등 개인정보 관련 법령에 따라 콤페토
        웹사이트&#40;www.competoseoul.com, 이하 '사이트'&#41;를 이용하는
        이용자의 개인정보 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을
        원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다. 회사는
        관련 법령 및 지침의 변경 또는 내부 운영방침의 변경에 따라
        개인정보처리방침을 개정할 수 있으며, 이러한 경우 웹사이트
        공지사항&#40;또는 개별통지&#41;을 통하여 변경 내용을 공지할 것입니다.
      </p>
      <br />
      <p>○ 본 방침은 2023년 12월 25일부터 시행됩니다.</p>
      <br />
      <h4>1. 개인정보 수집 및 이용 목적</h4>
      <p>
        회사는 다음과 같은 목적으로 이용자의 개인정보를 수집 및 이용합니다.
        개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이
        변경될 시에는 사전동의를 구할 예정입니다.
        <ol>
          <li>
            홈페이지 회원가입 및 관리&#40;회원가입을 하는 경우에
            한함&#41;본인확인, 불량회원의 부정이용 방지 가입의사 확인, 가입
            제한, 회원자격 유지 • 관리, 분쟁조정을 위한 기록보존, 불만처리 등
            민원처리, 고지사항 전달 등 목적
          </li>
          <li>
            재화 또는 서비스 제공물품&#40;상품 또는 경품&#41;배송, 청구서 발송,
            물품회수 서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 게임 콘텐츠 및
            서비스 제공, 본인인증, 대금결제, 요금추심, 재입고 알림 서비스 제공,
            정품 인증 서비스, 해외 배송 통관 업무 진행 등 목적
          </li>
          <li>
            민원사무 처리민원인의 신원 확인, 민원사항 확인, 사실조사를 위한
            연락·통지, 처리결과 통보 등 목적
          </li>
          <li>
            마케팅 및 광고에의 활용신규 서비스&#40;제품&#41; 개발 및 맞춤서비스
            제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 서비스의 유효성
            확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등 목적
          </li>
        </ol>
      </p>
      <br />
      <h4>2. 수집하는 개인정보의 항목 및 수집방법</h4>
      <p>가. 수집하는 개인정보의 항목</p>
      <ul>
        <table>
          <tr>
            <th width={"20%"}>수집 및 이용 목적</th>
            <th>수집하는 개인정보의 항목</th>
            <th width={"12%"}>필수/선택</th>
            <th width={"25%"}>보유 및 이용기간</th>
          </tr>
          <tr>
            <td rowspan="3">회원가입</td>
            <td>
              &#91;직접 가입&#93; <br />
              이메일, 비밀번호, 이름, 거주국가, 생년월일, 성별, 전화번호
              <br />
              <br />
            </td>
            <td>필수</td>
            <td rowspan="3">
              부정 가입 방지를 위해 탈퇴 후 30일 간 보관 후 삭제단, 관계법령에
              따라 일정기간 보존해야 하는 경우 해당 기간 보관후 파기
            </td>
          </tr>
          <tr>
            <td>
              &#91;카카오로 가입하기&#93; 카카오 이용자 식별ID, 프로필
              정보&#40;닉네임/프로필 사진&#41;, 카카오계정&#40;이메일&#41;,
              생일, 출생 연도, 카카오계정&#40;전화번호&#41;
              <br />
              <br />
            </td>
            <td>필수</td>
          </tr>
          <tr>
            <td>
              &#91;네이버로 가입하기&#93; 네이버 이용자 식별ID, 프로필
              정보&#40;닉네임/프로필 사진&#41;, 네이버계정&#40;이메일&#41;,
              생일, 출생 연도, 네이버계정&#40;전화번호&#41;
              <br />
              <br />
            </td>
            <td>필수</td>
          </tr>
          <tr>
            <td rowspan="2">재화 또는 서비스 제공</td>
            <td>
              &#91;주문&#93; 주문자 정보&#40;이름, 전화번호, 이메일, 배송지
              주소&#41;, 주문번호, 본인확인기관에서 제공하는 본인인증결과값
              &#40;CI&#41;
              <br />
              <br />
            </td>
            <td>필수</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>
              &#91;결제 및 환불&#93; 주문정보&#40;주문번호, 주문자 정보, 결제
              정보&#41;, 환불정보&#40;환불계좌 은행명, 계좌주명,
              환불계좌번호&#41;
              <br />
              <br />
            </td>
            <td>필수</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>민원사무처리</td>
            <td>
              &#91;이메일 문의하기&#93; 성별, 이름, 이메일, 문의 내용
              <br />
              <br />
            </td>
            <td>필수</td>
            <td>3년</td>
          </tr>
          <tr>
            <td>
              서비스 이용 또는 업무처리과정에서 자동으로 생성되어 수집되는 정보
            </td>
            <td>
              접속 IP 정보, 쿠키&#40;Cookie&#41;, 서비스 이용 기록, 접속 로그,
              결제기록, 이용정지기록
              <br />
              <br />
            </td>
            <td>필수</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>마케팅 정보 수신 및 활용</td>
            <td>
              &#91;정보제공&#93; 이름, 이메일, 전화번호, 생년월일, 쿠키, 접속 IP
              정보
              <br />
              <br />
            </td>
            <td>선택</td>
            <td>5년</td>
          </tr>
        </table>
      </ul>
      <p>
        나. 개인정보 수집방법 온라인 웹사이트를 통한 회원가입 및 서비스 이용
        전화, 팩스, 서면 등을 통한 오프라인에서의 수집 본인확인기관 또는
        제휴사로부터 제공 등 로그 분석 수집툴을 통한 생성정보수집, ‘쿠키’에 의한
        정보수집
      </p>
      <br />
      <h4>3. 개인정보의 보유 및 보유 기간</h4>
      <ol>
        <li>
          회사는 이용자로부터 개인정보 수집시 동의 받은 기간 내에서 개인정보를
          보유 및 이용합니다. 다만, 관계 법령의 규정에 따라 보존할 필요성이 있는
          경우에는 관계 법령에 따라 보존합니다.
        </li>
        <li>
          회원의 경우 개인정보의 보유 및 이용 기간은 서비스 이용계약
          체결시&#40;회원가입시&#41;부터 서비스 이용계약 해지시&#40;탈퇴신청,
          직권탈퇴 포함&#41;까지입니다.
        </li>
        <li>
          회사는 다른 법령에서 별도의 기간을 정하고 있거나 고객의 요청이 있는
          경우를 제외하면, 법령에서 정의하는 기간&#40;1년&#41; 동안 재이용하지
          아니하는 회원의 개인정보를 파기하거나 휴면회원 개인정보로 다른 회원의
          개인정보와 분리하여 별도로 저장·관리합니다. 단, 기간 만료 30일 전까지
          개인정보가 파기되거나 분리되어 저장·관리되는 사실과 기간 만료일 및
          해당 개인정보의 항목을 이메일·서면·모사전송·전화 또는 이와 유사한 방법
          중 어느 하나의 방법으로 회원에게 알립니다.
        </li>
        <li>
          전항에 따라 파기되거나 분리 보관되는 개인정보 항목은 아래와 같습니다.
        </li>
        <table>
          <tr>
            <th width={"25%"}>구분</th>
            <th>개인정보 항목</th>
          </tr>
          <tr>
            <td>분리보관</td>
            <td>ID, PW, 성별, 생년월일, 마케팅 수신동의 여부</td>
          </tr>
        </table>
        <li>관계 법령에 의한 개인정보 보유 기간은 다음과 같습니다.</li>
        <table>
          <tr>
            <th width={"45%"}>보관정보</th>
            <th>보관기관</th>
            <th width={"30%"}>보존사유</th>
          </tr>
          <tr>
            <td>표시/광고에 관한 기록</td>
            <td>6개월</td>
            <td rowspan="4">
              전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 동시행령 제6조
            </td>
          </tr>
          <tr>
            <td>계약 또는 청약철회 등에 관한 기록</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
            <td>3년</td>
          </tr>
          <tr>
            <td>
              컴퓨터통신 또는 인터넷 접속에 관한 기록
              <br />
              <br />
            </td>
            <td>3개월</td>
            <td>통신비밀보호법 제15조의2 및 동시행령 제41조</td>
          </tr>
        </table>
        <li>
          관계 법령에 그 근거가 없더라도 회사의 중대한 손실을 예방하거나 범죄 및
          소송 등을 위해 보관해야 하는 경우 회사방침에 따라 보관할 수 있습니다.
          단, 그 목적을 달성하기 위한 최소한의 기간 및 항목만 보관합니다. “부정
          이용”이란, 사이트를 이용하여 부당한 이득을 취하는 등 통상적인
          전자상거래관행에 부합하지 않는 거래상의 부정한 행위로서 아래 예시를
          포함하나 이에 한하지 않습니다.예시&#41; 사이트를 이용하여 재화 등을
          구매한 후 정당한 이유 없이 상습 또는 반복적으로 취소, 반품하여 회사의
          업무를 방해하는 행위 등 기타 회사의 정상적인 영업활동 및 서비스 운영을
          고의로 방해하는 행위, 지나치게 반복적인 회원탈퇴 및 재가입 또는 복수
          이메일을 이용한 경품행사 참여 등의 행위, 기타 사이트를 이용하여 부당한
          이득을 취하는 행위
        </li>
        <br />
        <table>
          <tr>
            <th width={"45%"}>보관정보</th>
            <th width={"18%"}>보존기간</th>
            <th>보존사유</th>
          </tr>
          <tr>
            <td>
              부정 이용 회원/비회원의 정보에 관한 기록&#123;이름, 전화번호,
              이메일, 주소, 본인확인기관에서 제공하는
              본인인증결과값&#40;CI&#41;&#125;
            </td>
            <td>5년</td>
            <td>부정 이용 회원의 재발 방지</td>
          </tr>
        </table>
      </ol>
      <br />
      <h4>개인정보의 제3자 제공에 관한 사항</h4>
      <ol>
        <li>
          회사는 이용자의 개인정보를 본 방침 제1조 ‘개인정보의 수집 및 이용
          목적’에서 명기한 범위 내에서만 사용합니다.
        </li>
        <li>
          회사는 이용자들의 개인정보를 제3자에게 제공하는 경우 이용자로부터
          &#40;i&#41; 개인정보를 제공받는 자 &#40;ii&#41; 개인정보를 제공받는
          자의 개인정보 이용목적 &#40;iii&#41; 제공하는 개인정보의 항목
          &#40;iv&#41; 개인정보를 제공받는 자의 보유 및 이용기간을 알리고 동의를
          받거나, 관련 법령이 허용한 범위 내에만 개인정보를 제3자에게
          제공합니다.
        </li>
      </ol>
      <br />
      <h4>5. 개인정보 처리위탁</h4>
      <ol>
        <li>
          회사는 이용자에게 보다 원활한 서비스를 제공하기 위하여 다음과 같이
          개인정보 처리 관련 업무를 외부 전문업체에 위탁하고 있습니다.
        </li>
        <table>
          <tr>
            <th width={"50%"}>수탁업체</th>
            <th>위탁하는 업무 내용</th>
          </tr>
          <tr>
            <td>NAVER PAY</td>
            <td>결제</td>
          </tr>
          <tr>
            <td>KCP</td>
            <td>결제</td>
          </tr>
          <tr>
            <td>카카오페이</td>
            <td>결제</td>
          </tr>
          <tr>
            <td>나이스평가정보</td>
            <td>본인 인증</td>
          </tr>
        </table>
        <li>
          회사는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적
          관리적 보호조치, 재 위탁 제한, 수탁자에 대한 관리 및 감독, 손해배상 등
          책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를
          안전하게 처리하는지를 관리•감독합니다.
        </li>
        <li>
          회사는 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
          개인정보 처리방침을 통하여 이용자에게 알리겠습니다.
        </li>
      </ol>
      <br />
      <h4>6. 정보주체의 권리와 그 행사방법</h4>
      <ol>
        <li>
          이용자는 회사에 대하여 언제든지 개인정보 수집∙이용∙제공 등의 동의를
          철회할 수 있습니다. 동의 철회시 회사는 지체 없이 수집된 개인정보를
          파기하는 등 필요한 조치를 취합니다.
        </li>
        <li>
          이용자는 회사에 대하여 본인에 관한 다음 각호의 사항에 대해 열람, 제공,
          정정을 요구할 수 있습니다.
        </li>
        <ul className="ul">
          <li>회사가 가지고 있는 이용자의 개인정보</li>
          <li>이용자 개인정보의 이용 및 제3자 제공 현황</li>
          <li>회사에게 개인정보 수집 이용 제공 등의 동의를 한 현황</li>
        </ul>
        <li>
          이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는
          회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나
          제공하지 않습니다.
        </li>
        <li>
          이용자는 개인정보의 열람 등 청구를 아래의 부서에 할 수 있습니다.
          이용자의 개인정보 열람 등 청구 업무가 신속하게 처리되도록
          노력하겠습니다.
        </li>
      </ol>
      <br />
      <h4>7. 아동의 개인정보보호</h4>
      <p style={{ "padding-left": "10px" }}>
        회사에서는 만14세 미만 아동의 회원가입을 받지 않으며, 개인정보 수집을
        하지 않습니다.
      </p>
      <br />
      <h4>8. 개인정보의 파기 절차 및 방법</h4>
      <ol>
        <li>
          파기절차이용자가 입력한 정보는 목적 달성 후 별도의 DB에
          옮겨져&#40;종이의 경우 별도의 서류&#41; 내부 방침 및 기타 관련 법령에
          따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진
          개인정보는 법률에 의한 경우가 아니고서 다른 목적으로 이용되지
          않습니다.
        </li>
        <li>
          파기기한이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는
          보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당
          서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는
          개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그
          개인정보를 파기합니다.
        </li>
        <li>
          파기방법전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
          사용합니다.종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
          파기합니다.
        </li>
      </ol>
      <br />
      <h4>9. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</h4>
      <ol>
        <li>
          회사는 이용자를 식별하고 회원의 로그인 상태를 유지하며, 이용자 개인별
          맞춤 서비스를 제공하기 위해 이용자의 정보를 저장하고 수시로 불러오는
          쿠키&#40;Cookie&#41;를 사용합니다. 쿠키는 웹사이트 서버가 이용자의
          웹브라우저에 전송하는 소량의 정보로서, 이용자의 단말기 하드디스크에
          저장됩니다.
        </li>
        <li>
          이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 이용자는
          웹브라우저의 설정을 통해 모든 쿠키를 허용/거부하거나, 쿠키가 저장될
          때마다 확인을 거치도록 할 수 있습니다. 단, 쿠키의 저장을 거부할
          경우에는 개인 맞춤서비스 등 회사가 제공하는 일부 서비스는 이용이
          어려울 수 있습니다.쿠키 설정 거부 방법은 다음과 같습니다.
          <ul className="outside">
            <li>
              인터넷 익스플로러 : 웹 브라우저 상단의 도구 &gt; 인터넷옵션 &gt;
              개인정보 &gt; 고급 &gt; 설정방법 선택
            </li>
            <li>모바일 사파리 : 설정 &gt; 사파리 &gt; 쿠키 차단여부 선택</li>
            <li>
              모바일 크롬 : 우측상단 More &gt; 설정 &gt; 사이트설정 &gt; 쿠키
              &gt; 설정방법 선택
            </li>
          </ul>
        </li>
      </ol>

      <br />
      <h4>10. 권익침해 구제방법</h4>
      <p>
        회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나
        보다 자세한 도움이 필요하신 경우 이용자는 아래의 기관에 대해 개인정보
        침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
        <ol>
          <li>개인정보침해신고센터&#40;한국인터넷진흥원 운영&#41;</li>
          <ul className="outside">
            <li>홈페이지: https://privacy.kisa.or.kr</li>
            <li>전화번호: 국번없이 118</li>
          </ul>
          <li>개인정보보호 포털 &#40;행정안전부 운영&#41;</li>
          <ul className="outside">
            <li>홈페이지: http://www.privacy.go.kr</li>
            <li>전화번호: 118</li>
          </ul>
          <li>경찰청사이버수사국</li>
          <ul className="outside">
            <li>홈페이지: http://cyberbureau.police.go.kr</li>
            <li>전화번호: 국번없이 182</li>
          </ul>
        </ol>
      </p>
      <br />
      <h4>11. 개인정보처리방침 변경</h4>
      <p style={{ "padding-left": "10px" }}>
        이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
        변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전에
        웹사이트 게시, 이메일 등을 통해 사전 공지하고, 사전 공지가 곤란한 경우
        최대한 빠른 시간 내 공지합니다.
      </p>
      <br />
      <p style={{ "padding-left": "10px" }}>
        공고일자: 2023-12-25 <br /> 시행일자: 2023-12-26
      </p>
    </LegalNotice>
  );
};

export default YContentsText1;
