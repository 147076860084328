import React, { useEffect, useState } from 'react';
import { AdminListDiv } from '../../../components/admin';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BlackBtn } from '../../../components/common/BlackBtn';

const ACouponList = () => {
    const navigate = useNavigate();

    const [data, setData] = useState(Array);

    useEffect(() => {
        data.length === 0 &&
            axios
                .get('/api/coupon')
                .then((res) => {
                    if (res.data.result === '0000') {
                        setData(res.data.dto);
                        console.log(res.data);
                    }
                })
                .catch((e) => alert('다시 시도해주세요.\n' + e));
    }, []);

    return (
        <AdminListDiv>
            <h5>쿠폰 리스트</h5>
            <div className="right-btn">
                <BlackBtn type="button" onClick={() => navigate('/admin/coupon/post')}>
                    <p>쿠폰 추가</p>
                </BlackBtn>
            </div>
            <div className="admin-card">
                <p>코드</p>
                <p>쿠폰명</p>
                <p>할인율</p>
                <p>할인금액</p>
                <p>만료일</p>
                <p>대상</p>
            </div>
            {data.length > 0 &&
                data.map((v, i) => (
                    <div key={i} className="admin-card" onClick={() => navigate('/admin/coupon/' + v.id)}>
                        <p>{v.couponCode}</p>
                        <p>{v.couponName}</p>
                        <p>{v.discountRate}</p>
                        <p>{v.discountPrice}</p>
                        <p>{v.expirationDate}</p>
                        <p>{v.forNew === 'true' ? '신규회원' : '전체회원'}</p>
                    </div>
                ))}
        </AdminListDiv>
    );
};

export default ACouponList;
