import styled from 'styled-components';

export const Input = styled.input`
    position: relative;
    width: 100%;
    height: 45px;

    padding: 0 10px;

    border: 1px solid var(--main-color);
    font-weight: 500;

    background-color: white;
`;
