import React from 'react';

import { useDispatch } from 'react-redux';
import { removeMember } from '../../store/memberSlice';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';

const MobileMenu = () => {
    let dispatch = useDispatch();

    const onLogout = async () => {
        await axios
            .get('/api/logout')
            .then(() => dispatch(removeMember()))
            .then(() => (window.location.href = '/'));
    };
    return (
        <MobileNav>
            <Link to={'/mypage/order'}>
                <div className="innerDiv">
                    <p>주문/배송</p>
                </div>
            </Link>
            <Link to={'/mypage/coupon'}>
                <div className="innerDiv">
                    <p>이벤트쿠폰</p>
                </div>
            </Link>
            <Link to={'/mypage/info'}>
                <div className="innerDiv">
                    <p>회원정보</p>
                </div>
            </Link>
            <Link to={'/mypage/address'}>
                <div className="innerDiv">
                    <p>주소록</p>
                </div>
            </Link>
            <Link to={'/mypage/inquiries'}>
                <div className="innerDiv">
                    <p>1:1문의하기</p>
                </div>
            </Link>
            <div className="innerDiv" onClick={onLogout}>
                <p>로그아웃</p>
            </div>
        </MobileNav>
    );
};

export default MobileMenu;

const MobileNav = styled.div`
    padding: 40px 0;

    display: none;

    .innerDiv {
        background-color: var(--main-color);
        color: white;
        padding: 30px;

        text-align: center;
        border: 1px solid white;

        p {
            font-weight: 500;
        }
    }

    @media screen and (max-width: 700px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
`;
