import React, { useEffect, useState } from "react";

import { AdminUserListDiv } from "../../../components/admin";
import { useNavigate } from "react-router-dom";
import sortList from "../../../const/SortList";
import axios from "axios";
import { useSelector } from "react-redux";

const AUsers = () => {
  const navigate = useNavigate();
  const adminData = useSelector((state) => state.admin);

  const [data, setData] = useState(Array);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(0);

  if (!adminData) navigate("/");

  useEffect(() => {
    data.length === 0 &&
      axios
        .get(`/api/member?page=${page}&size=10&sort=${sort}`)
        .then((res) => {
          if (res.data.result === "0000") {
            setData(res.data.dto.content);
            console.log(res.data.dto);
          } else {
            alert("다시 시도해주세요.");
          }
        })
        .catch((e) => alert("다시 시도해주세요.\n" + e));
  }, []);
  return (
    <AdminUserListDiv>
      <h5>회원 목록</h5>
      <div className="user-card">
        <p>No</p>
        <p>이메일</p>
        <p>이름</p>
        <p>생년월일</p>
        <p>성별</p>
        <p>마케팅동의</p>
        <p>소식동의</p>
      </div>
      {data &&
        data.length > 0 &&
        data.map((v, i) => (
          <div
            key={i}
            className="user-card"
            onClick={() => navigate("/admin/users/" + v.id)}
          >
            <p>{v.id}</p>
            <p>{v.email}</p>
            <p>{v.memberName}</p>
            <p>{v.birth}</p>
            <p>{v.sex ? (v.sex === 1 ? "남자" : "여자") : "선택안함"}</p>
            <p>{v.termMarketing}</p>
            <p>{v.termNews}</p>
          </div>
        ))}
    </AdminUserListDiv>
  );
};

export default AUsers;
