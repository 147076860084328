import React, { useState } from "react";

import { useSelector } from "react-redux";

import styled from "styled-components";
import { CheckBox, CheckTerms } from "../login/CheckCondition";
import LoginButton from "../login/LoginButton";

import SignInput from "../login/SignInput";

const OrderInfoContents = () => {
  let member = useSelector((state) => state.member);

  const [check, setCheck] = useState({
    terms: false,
    privacy: false,
  });

  const checkAll = (e) => {
    console.log(e.target.checked);
    e.target.checked
      ? setCheck({
          terms: true,
          privacy: true,
        })
      : setCheck({
          terms: false,
          privacy: false,
        });
  };
  return (
    <InfoWrap>
      <InputWrap>
        <p>아이디(이메일)</p>
        <SignInput type="email" value={member.email} disabled={true} />
      </InputWrap>
      <InputWrap>
        <p>이름</p>
        <SignInput type="name" value={member.memberName} disabled={true} />
      </InputWrap>
      <InputWrap>
        <p>휴대폰 번호</p>
        <SignInput type="phone" value={member.phone} disabled={true} />
      </InputWrap>
      <AgreeTerms>
        <CheckTerms>
          <CheckBox
            type="checkbox"
            name="all"
            id="all"
            onChange={checkAll}
            checked={check.terms && check.privacy ? true : false}
          />
          <label htmlFor="all">
            <label
              className="checkbox"
              onClick={() => {
                check.terms && check.privacy
                  ? setCheck({ terms: false, privacy: false })
                  : setCheck({ terms: true, privacy: true });
              }}
            ></label>
            모두 동의합니다.
          </label>
        </CheckTerms>
        <div className="mobile-hidden" style={{ height: "20px" }}></div>
        <div className="mobile-visiable" style={{ height: "10px" }}></div>
        <CheckTerms onClick={() => setCheck({ ...check, terms: !check.terms })}>
          <CheckBox
            type="checkbox"
            name="terms"
            id="terms"
            checked={check.terms}
          />

          <label htmlFor="terms">
            <label className="checkbox"></label>
            (필수)
            <span
              onClick={() =>
                window.open(
                  "/terms-of-use",
                  "_black",
                  " width=500, height=600, toolbar=no"
                )
              }
            >
              이용약관에 동의합니다.
            </span>
          </label>
        </CheckTerms>

        <CheckTerms
          onClick={() => setCheck({ ...check, privacy: !check.privacy })}
        >
          <CheckBox
            type="checkbox"
            name="privacy"
            id="privacy"
            checked={check.privacy}
          />

          <label htmlFor="privacy">
            <label className="checkbox"></label>
            (필수)
            <span
              onClick={() =>
                window.open(
                  "/privacy-policy",
                  "_black",
                  " width=500, height=600, toolbar=no"
                )
              }
            >
              개인정보처리방침에 동의합니다.
            </span>
          </label>
        </CheckTerms>
      </AgreeTerms>
      <LoginButton type={"order"} disabled={!check.terms || !check.privacy} />
    </InfoWrap>
  );
};

export default OrderInfoContents;

export const InfoWrap = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  padding-top: 30px;
  padding-bottom: 10px;
`;

export const InputWrap = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  p {
    font-size: var(--font-size-xs);
    font-weight: 500;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 700px) {
    p {
      padding-bottom: 5px;
    }
  }
`;

export const AgreeTerms = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  padding-top: 20px;
  padding-bottom: 40px;

  label {
    font-size: var(--font-size-xs);
    font-weight: 500;
  }

  span {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: 20px;
  }
`;
