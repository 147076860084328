import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setMember } from '../../../store/memberSlice';

import axios from 'axios';
import { LoadingDiv } from '../../../components/common/Loading';

const NaverLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let code = new URL(window.location.href).searchParams.get('code');
        let state = new URL(window.location.href).searchParams.get('state');

        if (code && state) {
            axios
                .get(`/api/login/naver-token?code=${code}&state=${state}`)
                .then((res) => {
                    if (res.data.result === '0000') {
                        axios
                            .get(
                                `/api/login/naver?accessToken=${res.data.accessToken}&refreshToken=${res.data.refreshToken}`
                            )
                            .then((res) => {
                                if (res.data.result === '0000') {
                                    dispatch(
                                        setMember({
                                            id: res.data.id,
                                            email: res.data.email,
                                            memberName: res.data.memberName,
                                            birth: res.data.birth,
                                            phone: res.data.phone,
                                        })
                                    );
                                } else {
                                    navigate('/login');
                                }
                            })
                            .then(() => navigate('/'))
                            .catch((err) => console.log(err));
                    } else {
                        navigate('/login');
                    }
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return (
        <LoadingDiv>
            <div className="img">
                <img alt="spinner" src="/images/loader.gif" />
                <p>LOADING</p>
            </div>
        </LoadingDiv>
    );
};

export default NaverLogin;
