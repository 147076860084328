import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import axios from "axios";

const Search = ({ on, set }) => {
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState(Array);

  useEffect(() => {
    data.length === 0 &&
      axios
        .get("/api/goods?page=0&sort=0&size=3")
        .then((res) => {
          setData(res.data.dto.content);
        })
        .catch((e) => console.log(e));
  }, [data]);

  const getSearchValueData = (e) => {
    setSearchValue(e.target.value);

    e.target.value === ""
      ? axios
          .get("/api/goods?page=0&sort=0&size=3")
          .then((res) => {
            setData(res.data.dto.content);
          })
          .catch((e) => console.log(e))
      : axios.get(`/api/goods/search?q=${e.target.value}`).then((res) => {
          console.log(res.data.data);
          setData(res.data.data);
        });
  };

  return (
    <>
      <SearchWrap
        className={!on ? "closed" : "opened"}
        onClick={(e) => e.stopPropagation()}
      >
        <p className="mobile-visiable title">제품 찾기</p>
        <FlexWrap>
          <SearchInput className={on ? "opened" : "closed"}>
            <img
              alt="icon"
              src="/images/icon_search.svg"
              width={13}
              height={13}
            />
            <input
              type={"text"}
              value={searchValue}
              style={{ fontWeight: "400" }}
              onChange={(e) => getSearchValueData(e)}
              placeholder="검색어를 입력하세요"
            />
          </SearchInput>
          <button className="mobile-hidden" onClick={() => set(false)}>
            <p>취소</p>
          </button>
        </FlexWrap>
        <SearchValueList>
          {data.length > 0 &&
            data.map((v, i) => (
              <Link
                key={i}
                to={
                  v.categoryId === 1
                    ? "/bags/" + v.id
                    : v.categoryId === 2
                    ? "/wallets/" + v.id
                    : "/accessories/" + v.id
                }
                onClick={() => set(false)}
              >
                <SearchCard key={i}>
                  <img
                    alt="thumbnail"
                    src={process.env.REACT_APP_DOMAIN + v.thumb_src}
                  />
                  <p>{v.goodsName}</p>
                </SearchCard>
              </Link>
            ))}
        </SearchValueList>
        <EventBanner>
          <p>특별한 혜택</p>
          <img
            alt="banner"
            src="http://competoseoul.cdn1.cafe24.com/banner3.png"
          />
          <span>
            <p>블랙 진 파우치 선물 포장 서비스</p>
            <small>온라인 구매 고객님들께 특별한 서비스를 제공합니다.</small>
          </span>
        </EventBanner>
      </SearchWrap>
      <Modal
        className={!on ? "closed" : "opened"}
        onClick={() => set(false)}
      ></Modal>
    </>
  );
};

export default Search;

const FlexWrap = styled.div`
  position: relative;
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    font-size: var(--font-size-sm);
    font-weight: 500;
  }
`;

const SearchInput = styled.div`
  position: relative;
  width: 100%;
  height: 33px;
  border: 1px solid var(--main-color);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 3px;

  input {
    border: 0 solid white;
    font-size: var(--font-size-xs);
    font-weight: 400;
  }
`;

const SearchValueList = styled.div`
  position: relative;
  width: 100%;
  height: -webkit-calc(100vh - 600px);
  overflow: auto;
  margin: 20px 0;

  @media screen and (max-width: 700px) {
    height: -webkit-calc(100vh - 560px);
  }
`;

const SearchCard = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  gap: 10px;

  margin-bottom: 20px;

  cursor: pointer;

  p {
    font-size: var(--font-size-xs);
    font-weight: 500;
  }

  img {
    width: 59px;
    height: 73px;
  }
`;

const EventBanner = styled.div`
  position: relative;
  width: 100%;
  height: 300px;

  img {
    position: relative;
    width: 100%;
    height: 322px;
    object-fit: cover;
    margin: 10px 0;
  }

  span p {
    font-size: var(--font-size-sm);
  }

  span small {
    font-size: var(--font-size-xs);
    font-weight: 500;
  }

  @media screen and (max-width: 700px) {
    p {
      font-weight: 600;
      font-size: 14pt;
    }
    span small {
      font-weight: 500;
    }

    img {
      height: 220px;
      margin-bottom: 5px;
    }
  }
`;

const SearchWrap = styled.div`
  position: fixed;
  display: block;
  right: -100%;
  top: 0;
  bottom: 0;
  z-index: 100;

  box-shadow: 0 0 0;

  width: 25%;
  min-width: 486px;
  height: 100vh;

  background-color: white;
  padding: 40px;

  .title {
    margin-bottom: 20px;
    font-size: 14pt;
  }

  transition: all 0.8s ease;

  &.opened {
    right: 0;
  }
  &.closed {
    right: -100%;
  }

  @media screen and (max-width: 700px) {
    min-width: 0;
    top: 82px;
    width: 100vw;
    height: calc(100vh - 82px);
    padding: 35px var(--wrapper-padding);
  }
`;

const Modal = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);

  &.opened {
    display: block;
  }
  &.closed {
    display: none;
  }

  @media screen and (max-width: 700px) {
    background: rgba(0, 0, 0, 0);
  }
`;
