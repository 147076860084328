import React from "react";
import { useNavigate } from "react-router-dom";
import { MainContents } from "../../../../components/user/UserComponent";

const MypageInquiries = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="title">1:1 문의하기</p>
        <img
          className="mobile-visible"
          alt="←"
          src="/images/leftarrow.svg"
          width={15}
          height={15}
          onClick={() => navigate("/mypage")}
        />
      </div>

      <MainContents>
        <p style={{ lineHeight: "1.5em", fontWeight: "500" }}>
          우측 하단의 말풍선 아이콘 클릭 시,
          <br />
          콤페토 클라이언트 서비스팀과 1:1 상담할 수 있습니다.
          <br />
          운영 시간 외 문의는 남겨주시면, 운영 시간 내 순차적으로 답변 됩니다.
        </p>
        <div style={{ marginTop: "20px" }}>
          <p style={{ lineHeight: "1.5em" }}>서비스 운영시간</p>
          <p style={{ lineHeight: "1.5em", fontWeight: "500" }}>
            10:30am to 5:00pm, 월-금
          </p>
        </div>
      </MainContents>
    </div>
  );
};

export default MypageInquiries;
