import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";

import GlobalStyles from "./GlobalStyles";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Layout from "./components/layout/Layout";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import FindId from "./pages/Find/Id";
import FindPassword from "./pages/Find/Password";
import About from "./pages/About";
import Bags from "./pages/Bags";
import BagsDetail from "./pages/Bags/Detail";
import Wallets from "./pages/Wallets";
import WalletsDetail from "./pages/Wallets/Detail";
import Accessories from "./pages/Accessories";
import AccessoriesDetail from "./pages/Accessories/Detail";
import BestSeller from "./pages/BestSeller";
import Mypage from "./pages/User/Mypage";
import MypageOrder from "./pages/User/Mypage/Order";
import MyPageOrderDetail from "./pages/User/Mypage/Order/Detail";
import MyPageCoupon from "./pages/User/Mypage/Coupone";
import MypageInfo from "./pages/User/Mypage/Info";
import MypageChangePass from "./pages/User/Mypage/ChangePassword";
import MypageAddress from "./pages/User/Mypage/Address";
import MypageInquiries from "./pages/User/Mypage/Inquiries";
import Terms from "./pages/LegalNotice/Terms";
import Privacy from "./pages/LegalNotice/Privacy";
import Cookie from "./pages/LegalNotice/Cookie";
import Verification from "./pages/Signup/Verification";
import Complete from "./pages/Signup/Complete";
import OrderInfo from "./pages/Payment/OrderInfo";
import ShipmentInfo from "./pages/Payment/ShipInfo";
import PayMethod from "./pages/Payment/PayMethod";
import CompleteOrder from "./pages/Payment/Complete";
import PaymentLayout from "./components/payment/PaymentLayout";
import BestSellerDetail from "./pages/BestSeller/Detail";
import UserLayout from "./components/user/UserLayout";
import NewPassword from "./pages/User/Mypage/ChangePassword/newPass";

import ChannelService from "./ChannelService";
import Dashboard from "./pages/Admin/Dashboard";
import ABanners from "./pages/Admin/Banners";
import AGoods from "./pages/Admin/Goods";
import APopup from "./pages/Admin/Popup";
import AAdmins from "./pages/Admin/Admins";
import AUsers from "./pages/Admin/Users";
import AGoodsDetail from "./pages/Admin/Goods/Detail";
import NaverLogin from "./pages/Login/Naver";
import MypageAddressDetail from "./pages/User/Mypage/Address/Detail";
import AMemberPost from "./pages/Admin/Admins/Detail";
import AUsersDetail from "./pages/Admin/Users/Detail";
import ALogin from "./pages/Login/Admin";
import ACouponList from "./pages/Admin/Coupon";
import AcouponDetail from "./pages/Admin/Coupon/Detail";
import ALayout from "./components/admin/Layout";
import AOpt from "./pages/Admin/Option";
import AOptDetail from "./pages/Admin/Option/Detail";
import APayment from "./pages/Admin/Payment";
import AllItmes from "./pages/AllItems";
import Review from "./pages/User/Mypage/Review/Detail";
import ABannerDetail from "./pages/Admin/Banners/Detail";
import APaymentDetail from "./pages/Admin/Payment/Detail";
import ACancel from "./pages/Admin/Cancel";
import ACancelDetail from "./pages/Admin/Cancel/Detail";

function App() {
  const memberData = useSelector((state) => state.member);

  useEffect(() => {
    // 서버에서 렌더링 된 HTML 문서에서 CSRF 토큰을 가져오는 로직
    const csrfTokenMeta = document.querySelector("meta[name='_csrf']");

    // CSRF 토큰이 존재하는지 확인 후 요청 헤더에 추가
    if (csrfTokenMeta) {
      const csrfToken = csrfTokenMeta.textContent;
      axios.defaults.headers.common["X-XSRF-TOKEN"] = csrfToken;
    }
  }, []);

  ChannelService.loadScript();

  ChannelService.boot({
    pluginKey: "f0d9592e-e036-4943-a50e-cb6305290beb", //please fill with your plugin key
    openChatDirectlyAsPossible: true,
    mobileMessengerMode: "iframe",
    zIndex: 99,
    hidePopup: false,
    memberId: `${memberData.email}`, // fill user's member id
    profile: {
      // fill user's profile
      email: `${memberData.email}`, // fill user's landline number
      name: `${memberData.memberName}`, // fill user's name
      mobileNumber: `${memberData.phone}`, // fill user's mobile number
      memberKey: `${memberData.id}`, // fill user's mobile number
    },
  });

  function vh_height() {
    var vh = window.outerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    vh_height();
  }, []);

  return (
    <div
      className="App"
      style={{ position: "relative", width: "100%", height: "100%" }}
    >
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/oauth/login/naver" element={<NaverLogin />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/complete" element={<Complete />} />
          <Route path="/find-id" element={<FindId />} />
          <Route path="/find-password" element={<FindPassword />} />
          <Route path="/about" element={<About />} />
          <Route path="/bags" element={<Bags />} />
          <Route path="/bags/:goodsId" element={<BagsDetail />} />
          <Route path="/wallets" element={<Wallets />} />
          <Route path="/wallets/:goodsId" element={<WalletsDetail />} />
          <Route path="/accessories" element={<Accessories />} />
          <Route path="/accessories/:goodsId" element={<AccessoriesDetail />} />
          <Route path="/all-items" element={<AllItmes />} />
          <Route path="/best-seller" element={<BestSeller />} />
          <Route path="/best-seller/:goodsId" element={<BestSellerDetail />} />
          <Route path="mypage" element={<UserLayout />}>
            <Route index element={<Mypage />} />
            <Route path="order" element={<MypageOrder />} />
            <Route path="order/:orderId" element={<MyPageOrderDetail />} />
            <Route path="review/:orderId/:goodsId" element={<Review />} />
            <Route path="coupon" element={<MyPageCoupon />} />
            <Route path="info" element={<MypageInfo />} />
            <Route path="change-password" element={<MypageChangePass />} />
            <Route path="new-password" element={<NewPassword />} />
            <Route path="address" element={<MypageAddress />} />
            <Route
              path="address/:addressId"
              element={<MypageAddressDetail />}
            />
            <Route path="address/post" element={<MypageAddressDetail />} />
            <Route path="inquiries" element={<MypageInquiries />} />
          </Route>
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/cookie-policy" element={<Cookie />} />
          <Route path="payment" element={<PaymentLayout />}>
            <Route path="order-info" element={<OrderInfo />} />
            <Route path="shipment-info" element={<ShipmentInfo />} />
            <Route path="payment-method" element={<PayMethod />} />
            <Route path="complete/:completeId" element={<CompleteOrder />} />
          </Route>
          <Route path="/login/admin" element={<ALogin />} />
          <Route path="admin" element={<ALayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="goods" element={<AGoods />} />
            <Route path="goods/post" element={<AGoodsDetail />} />
            <Route path="goods/:goodsId" element={<AGoodsDetail />} />
            <Route path="banners" element={<ABanners />} />
            <Route path="banners/:bannerId" element={<ABannerDetail />} />
            <Route path="popup" element={<APopup />} />
            <Route path="member" element={<AAdmins />} />
            <Route path="member/:adminId" element={<AMemberPost />} />
            <Route path="member/post" element={<AMemberPost />} />
            <Route path="users" element={<AUsers />} />
            <Route path="users/:userId" element={<AUsersDetail />} />
            <Route path="coupon" element={<ACouponList />} />
            <Route path="coupon/post" element={<AcouponDetail />} />
            <Route path="coupon/:couponId" element={<AcouponDetail />} />
            <Route path="option" element={<AOpt />} />
            <Route path="option/post" element={<AOptDetail />} />
            <Route path="option/:optionId" element={<AOptDetail />} />
            <Route path="payment" element={<APayment />} />
            <Route path="payment/:paymentId" element={<APaymentDetail />} />
            <Route path="cancel" element={<ACancel />} />
            <Route path="cancel/:cancelId" element={<ACancelDetail />} />
          </Route>
          <Route path="/*" element={<Home />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
