import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { AdminPostDiv } from '../../../../components/admin';
import { Input } from '../../../../components/common/Input';
import { BlackBtn } from '../../../../components/common/BlackBtn';

const AMemberPost = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [useYn, setUseYn] = useState('');

    params.adminId &&
        !data &&
        axios
            .get('/api/admins/' + params.adminId)
            .then((res) => {
                if (res.data.result === '0000') {
                    setData(res.data.dto);
                    console.log(res.data.dto);
                } else {
                    alert(res.data.message);
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));

    useEffect(() => {
        if (data) {
            setEmail(data.email);
            setName(data.adminName);
            setPassword('****');
            setUseYn(data.useYn);
        }
    }, [data]);

    const saveAdminHandler = async () => {
        if (!email || !password || !name || !useYn) return false;

        await axios
            .post('/api/admins', {
                email: email,
                adminPass: password,
                adminName: name,
                useYn: useYn,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    alert('정상적으로 등록되었습니다.');
                    navigate('/admin/member');
                }
            })
            .catch((e) => alert('다시 시도해주세요.\n' + e));
    };

    const updateAdminHandler = async () => {
        if (!email || !password || !name) return false;

        await axios
            .put('/api/admins', {
                id: data.id,
                email: email,
                adminPass: password === '****' ? null : password,
                adminName: name,
                useYn: useYn,
            })
            .then((res) => {
                if (res.data.result === '0000') {
                    alert('정상적으로 수정되었습니다.');
                    navigate('/admin/member');
                }
            });
    };
    return (
        <div>
            <h5>관리자 {data ? '수정' : '추가'}</h5>
            <AdminPostDiv>
                <p>이메일</p>
                <Input
                    type="text"
                    placeholder="이메일"
                    disabled={data ? true : false}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="br"></div>
                <p>비밀번호</p>
                <Input
                    type="password"
                    placeholder="비밀번호"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="br"></div>
                <p>이름</p>
                <Input type="text" placeholder="이름" value={name} onChange={(e) => setName(e.target.value)} />
                <div className="br"></div>
                <p>사용여부</p>
                <input
                    type="radio"
                    id="use"
                    name="useYn"
                    value={useYn}
                    checked={useYn === 'Y'}
                    onChange={(e) => e.target.checked && setUseYn('Y')}
                />
                <label htmlFor="use">사용</label>
                <input
                    type="radio"
                    id="no"
                    name="useYn"
                    value={useYn}
                    checked={useYn === 'N'}
                    onChange={(e) => e.target.checked && setUseYn('N')}
                />
                <label htmlFor="no">미사용</label>
                <div className="br"></div>
            </AdminPostDiv>
            {data ? (
                <BlackBtn type="button" property="var(--main-color)" color="white" onClick={updateAdminHandler}>
                    수정
                </BlackBtn>
            ) : (
                <BlackBtn type="button" property="var(--main-color)" color="white" onClick={saveAdminHandler}>
                    저장
                </BlackBtn>
            )}
        </div>
    );
};

export default AMemberPost;
