import React from 'react';

import LeftNav, { WrapperInner, ContetnsWrapper } from '../../../components/legalNotice/LeftNav';
import YContentsText1 from '../../../components/legalNotice/YContentsText1';

const Privacy = () => {
    return (
        <WrapperInner>
            <LeftNav />
            <ContetnsWrapper>
                <YContentsText1/>
            </ContetnsWrapper>
        </WrapperInner>
    );
};

export default Privacy;
