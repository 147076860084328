import React from 'react';

import LeftNav, { WrapperInner, ContetnsWrapper } from '../../../components/legalNotice/LeftNav';
import YContentsText from '../../../components/legalNotice/YContentsText';


const Terms = () => {
    return (
        <WrapperInner>
            <LeftNav />
            <ContetnsWrapper>
                <YContentsText/>
            </ContetnsWrapper>
        </WrapperInner>
    );
};

export default Terms;
