import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import SignInput, { SignSelect } from "../../../../components/login/SignInput";
import {
  PasswordGrid,
  UserInfoWrap,
} from "../../../../components/user/UserComponent";
import { CommonBtn } from "../../../../components/payment/ShipInfo";
import {
  CheckAllTermsAndConditions,
  CheckBox,
  CheckTerms,
  TermsWrap,
} from "../../../../components/login/CheckCondition";
import axios from "axios";

const MypageInfo = () => {
  const navigate = useNavigate();
  let member = useSelector((state) => state.member);

  const [sex, setSex] = useState(0);
  const [terms, setTerms] = useState({
    marketing: false,
    news: false,
  });
  const [data, setData] = useState("");

  !data &&
    axios
      .get("/api/member/" + member.id)
      .then((res) => setData(res.data.data))
      .catch((e) => alert("다시 시도해주세요.\n" + e));
  console.log(data);

  useEffect(() => {
    data.sex && setSex(data.sex);
    data &&
      setTerms({
        marketing: data.termMarketing === "Y" ? true : false,
        news: data.termNews === "Y" ? true : false,
      });
  }, [data]);

  const updateMemberInfoHandler = async () => {
    if (
      sex === data.sex &&
      terms.marketing === (data.termMarketing === "Y" ? true : false) &&
      terms.news === (data.news === "Y" ? true : false)
    )
      return false;

    await axios
      .put("/api/member", {
        id: member.id,
        sex: sex === "0" ? null : sex,
        termMarketing: terms.marketing ? "Y" : "N",
        termNews: terms.news ? "Y" : "N",
      })
      .then((res) => {
        if (res.data.result === "0000") {
          if (!alert("수정이 완료되었습니다.")) navigate("/mypage");
        } else {
          alert("다시 시도해주세요.");
        }
      })
      .catch((e) => alert("다시 시도해주세요.\n" + e));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="title">회원정보</p>
        <img
          className="mobile-visible"
          alt="←"
          src="/images/leftarrow.svg"
          width={15}
          height={15}
          onClick={() => navigate("/mypage")}
        />
      </div>

      <UserInfoWrap>
        <p>아이디(이메일)</p>
        <SignInput type="email" value={member.email} disabled={true} />
        <p>비밀번호</p>
        <PasswordGrid>
          <SignInput type="password" value={"******"} disabled={true} />
          <Link to={"/mypage/change-password"}>
            <CommonBtn property="var(--main-color)" color="white">
              재설정
            </CommonBtn>
          </Link>
        </PasswordGrid>
        <p>휴대폰 번호</p>
        <SignInput type="phone" value={member.phone} disabled={true} />
        <p>이름</p>
        <SignInput type="name" value={member.memberName} disabled={true} />
        <p>생년월일</p>
        <SignInput type="birth" value={member.birth} disabled={true} />
        <p>성별</p>
        <SignSelect value={sex} set={setSex} />
        <TermsWrap style={{ marginBottom: "40px" }}>
          <CheckAllTermsAndConditions>
            <label htmlFor="all">광고성 정보 수신 동의</label>
          </CheckAllTermsAndConditions>
          <CheckTerms
            onClick={() => setTerms({ ...terms, marketing: !terms.marketing })}
          >
            <CheckBox
              type="checkbox"
              name="marketing1"
              id="marketing1"
              checked={terms.marketing}
            />
            <label
              htmlFor="marketing1"
              style={{ cursor: "pointer", zIndex: "-1" }}
            >
              <label className="checkbox"></label>
              (선택)마케팅 목적의 개인정보처리방침에 동의합니다.
            </label>
          </CheckTerms>
          <CheckTerms onClick={() => setTerms({ ...terms, news: !terms.news })}>
            <CheckBox
              type="checkbox"
              name="news1"
              id="news1"
              checked={terms.news}
            />
            <label htmlFor="news1" style={{ cursor: "pointer", zIndex: "-1" }}>
              <label className="checkbox"></label>
              (선택)콤페토에서 제공하는 소식을 SMS, 이메일로 받겠습니다.
            </label>
          </CheckTerms>
        </TermsWrap>
        <CommonBtn
          property="var(--main-color)"
          color="white"
          onClick={updateMemberInfoHandler}
        >
          수정하기
        </CommonBtn>
      </UserInfoWrap>
    </div>
  );
};

export default MypageInfo;
