import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import { GridInnerDiv } from "../home/BestSeller";

const Recommended = () => {
  const [data, setData] = useState(Array);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    data.length === 0 &&
      axios
        .get("/api/goods?page=0&sort=4&size=5")
        .then((res) => {
          setData(res.data.dto.content);
        })
        .catch((e) => console.log(e));

    setInnerWidth(window.innerWidth);
  }, []);

  return (
    <Wrap>
      <div className="title-flex">
        <p className="title">추천 상품</p>
        <Link to={"/best-seller"}>
          <small>더보기 →</small>
        </Link>
      </div>
      <Swiper
        slidesPerView={innerWidth > 700 ? 5 : 2.5}
        spaceBetween={10}
        className="mySwiper"
      >
        {data.length > 0 &&
          data.map((v, i) => (
            <SwiperSlide
              key={i}
              onClick={() => (window.location.href = `/best-seller/${v.id}`)}
            >
              <GridInnerDiv>
                <div className="img-wrap">
                  <img
                    alt="thumbnail"
                    src={process.env.REACT_APP_DOMAIN + v.thumb_src}
                  />
                </div>
                <p className="title good-name">{v.goodsName}</p>
                <p>
                  ₩ {v.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </GridInnerDiv>
            </SwiperSlide>
          ))}
      </Swiper>
    </Wrap>
  );
};

export default Recommended;

const Wrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 30px auto;
  height: 100%;

  padding: 60px var(--wrapper-padding);

  .title-flex {
    display: flex;
    justify-content: space-between;

    margin-bottom: 20px;

    p {
      font-size: 14pt;
      font-weight: 600;
    }

    small {
      font-weight: 500;
    }
  }

  .mySwiper {
    line-height: 0.9em;

    // img {
    //   position: relative;
    //   width: 100%;
    //   height: 140px;
    //   object-fit: cover;
    //   margin-bottom: 10px;
    // }

    p {
      font-size: var(--font-size-sm);
      font-weight: 600;
      margin-bottom: 3px;
    }

    .title {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 700px) {
    max-width: none;
    margin: 0;
  }
`;
