import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: 0,
    email: '',
    memberName: '',
    birth: '',
    phone: '',
    point: 0,
};

const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        setMember: (state, action) => {
            state.id = action.payload.id;
            state.email = action.payload.email;
            state.memberName = action.payload.memberName;
            state.birth = action.payload.birth;
            state.phone = action.payload.phone;
            state.removeMember = action.payload.removeMember;
        },
        removeMember: (state) => {
            state.id = 0;
            state.email = '';
            state.memberName = '';
            state.birth = '';
            state.phone = '';
            state.phone = 0;
        },
    },
});

export const { setMember, removeMember } = memberSlice.actions;
export default memberSlice.reducer;
