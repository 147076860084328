import React, { useEffect, useState } from "react";

import axios from "axios";

import { BlackBtn } from "../../../components/common/BlackBtn";
import { VerifDiv } from "../../../components/login/LoginComp";
import Timer from "../../../const/Timer";

const Verification = () => {
  const [name, setName] = useState("");
  const [to, setTo] = useState("");
  const [number, setnumber] = useState("");
  const [birth, setBirth] = useState("");
  const [warning, setWarning] = useState(false);
  const [count, setCount] = useState(300);

  const [popup, setPopup] = useState(true);

  useEffect(() => {
    if (count < 1) {
      setnumber("");
      alert("인증 시간이 지났습니다.\n인증번호 다시 받기를 눌러주세요.");
    }
  }, [count]);

  const birthInputHandler = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    let leng = birth.replaceAll("-", "").length;
    console.log("length: " + leng);
    let result = "";

    if (leng > 6) {
      result += value.substring(0, 4);
      result += "-";
      result += value.substring(4, 6);
      result += "-";
      result += value.substring(6);

      if (!checkValidDate(result)) {
        setWarning(true);
      } else {
        setWarning(false);
      }
    } else result = value;

    setBirth(result);
  };

  const checkValidDate = (value) => {
    let result = true;
    try {
      let date = value.split("-");
      let y = parseInt(date[0], 10),
        m = parseInt(date[1], 10),
        d = parseInt(date[2], 10);

      let dateRegex =
        /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
      result = dateRegex.test(d + "-" + m + "-" + y);
    } catch (err) {
      result = false;
    }
    return result;
  };

  const verifFnc = async () => {
    if (warning || !name || !to || !birth) {
      alert("회원정보를 입력해주세요.");
      return false;
    }

    setCount(300);

    let authNum = [];
    for (let i = 1; i <= 6; i++) {
      let random = Math.floor(Math.random() * 9) + 1;

      authNum.push(random);
    }

    console.log(authNum);

    setnumber(authNum.join(""));

    try {
      setPopup(true);
      await axios.post("/api/send-one", {
        from: "01056069803",
        to: to,
        number: authNum.join(""),
      });
    } catch (error) {
      alert("다시 시도해주세요.");
    }
  };

  let focusInput = [];
  const nextFocusFnc = (data, curr, next) => {
    let currInput = document.querySelector("." + curr);
    let nextInput = document.querySelector("." + next);

    if (data) {
      currInput && currInput.classList.remove("on");
      nextInput && nextInput.classList.add("on");
      nextInput && nextInput.focus();
      focusInput.push(data);
    }
  };

  const authNumberCheck = () => {
    if (number != focusInput.join("")) {
      alert("인증번호가 올바르지 않습니다.\n 다시 입력해주세요.");
      const inputList = document.querySelectorAll(".input");
      inputList.forEach((v) => (v.value = ""));
      return false;
    }

    sessionStorage.setItem("name", name);
    sessionStorage.setItem("phone", to);
    sessionStorage.setItem("birth", birth);

    if (sessionStorage.getItem("birth")) window.location.href = "/sign-up";
  };

  return (
    <VerifDiv>
      <p>본인 인증</p>
      <div className="signupTxt">
        <small>
          COMPETO 웹 사이트에서는 고객님의 안전한 거래 및 회원정보 보호를 위해
          본인 인증(최초 1회 가입 시)을 하고 있습니다.
        </small>
        <br />
        <br />
        <small>만 14세 미만 아동은 COMPETO 웹사이트 회원에서 제외됩니다.</small>
      </div>
      <div className="inputDiv">
        <p>이름</p>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="이름을 입력하세요."
        />
        <div className="br"></div>
        <p>생년월일</p>
        <input
          type="text"
          maxLength="10"
          value={birth}
          onChange={birthInputHandler}
          placeholder="YYYY-MM-DD"
        />
        {warning && (
          <small className="warning">유효하지 않은 날짜입니다.</small>
        )}
        <div className="br"></div>
        <p>핸드폰 번호</p>
        <input
          type="text"
          value={to}
          onChange={(e) => setTo(e.target.value.replace(/\D/g, ""))}
          placeholder="핸드폰 번호를 입력해주세요."
        />
        <div className="br"></div>
      </div>
      {popup && (
        <>
          <div className="popup-bg"></div>
          <div className="popup-fixed">
            <div className="popup">
              <div className="flex-between">
                <img className="logo" alt="logo" src="/images/logo.svg" />
                <button type="button" onClick={() => setPopup(false)}>
                  <img
                    className="iconX"
                    alt="icon"
                    src="/images/icon_x.svg"
                    style={{ filter: "brightness(0.3)" }}
                  />
                </button>
              </div>
              <div className="txt">
                <p>카카오톡(SMS) 회원가입 인증</p>
                <small>
                  <b style={{ color: "var(--main-color)" }}>
                    {to.substring(0, 3) +
                      "-" +
                      to.substring(3, 7) +
                      "-" +
                      "*".repeat(to.substring(7).length)}
                  </b>
                  로 전달된 6자리 인증번호를 입력해주세요.
                  <br />
                  인증번호는 카카오톡 혹은 SMS로 발송됩니다.{" "}
                  <Timer count={count} setCount={setCount} />
                </small>
              </div>
              <div className="input">
                <input
                  className="input1 on input"
                  type="text"
                  maxLength={1}
                  minLength={1}
                  onChange={(e) =>
                    nextFocusFnc(e.target.value, "input1", "input2")
                  }
                />
                <input
                  className="input2 input"
                  type="text"
                  maxLength={1}
                  minLength={1}
                  onChange={(e) =>
                    nextFocusFnc(e.target.value, "input2", "input3")
                  }
                />
                <input
                  className="input3 input"
                  type="text"
                  maxLength={1}
                  minLength={1}
                  onChange={(e) =>
                    nextFocusFnc(e.target.value, "input3", "input4")
                  }
                />
                <input
                  className="input4 input"
                  type="text"
                  maxLength={1}
                  minLength={1}
                  onChange={(e) =>
                    nextFocusFnc(e.target.value, "input4", "input5")
                  }
                />
                <input
                  className="input5 input"
                  type="text"
                  maxLength={1}
                  minLength={1}
                  onChange={(e) =>
                    nextFocusFnc(e.target.value, "input5", "input6")
                  }
                />
                <input
                  className="input6 input"
                  type="text"
                  maxLength={1}
                  minLength={1}
                  onChange={(e) =>
                    nextFocusFnc(e.target.value, "input6", "input7")
                  }
                />
                <input
                  className="input7 input"
                  type="text"
                  maxLength={1}
                  minLength={1}
                  style={{ display: "none" }}
                  onChange={(e) =>
                    nextFocusFnc(e.target.value, "input6", "input1")
                  }
                />
              </div>
              <div className="buttonDiv">
                <button
                  style={{ color: "#787878" }}
                  type="text"
                  onClick={verifFnc}
                >
                  <p>인증번호 다시 받기</p>
                </button>
                <button type="text" onClick={authNumberCheck}>
                  인증하기
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="br"></div>
      <BlackBtn type={"button"} onClick={verifFnc}>
        본인 인증하기
      </BlackBtn>
    </VerifDiv>
  );
};

export default Verification;
