import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import history from '../../const/history';

import styled from 'styled-components';
import { CardPrice } from '../cart/Cart';

import CartList from '../cart/CartList';
import CartPrice from '../cart/CartPrice';
import BottomNotice from './BottomNotice';
import PaymentNav from './PaymentNav';

const PaymentLayout = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    let member = useSelector((state) => state.member);
    let cart = useSelector((state) => state.cart);

    const [locationKeys, setLocationKeys] = useState([]);

    useEffect(() => {
        if (member.id < 1) {
            navigator('/login');
        }
    }, [member]);

    useEffect(() => {
        if (cart.legnth < 1) {
            navigator('/all-items');
        }
    }, [cart]);

    useEffect(() => {
        if (history.action === 'POP') {
            if (locationKeys[1] === window.location.key) {
                setLocationKeys(([_, ...keys]) => keys);

                // 앞으로 가기
            } else {
                setLocationKeys((keys) => [window.location.key, ...keys]);

                // 뒤로 가기
                history.push('/');
            }
        }
    }, [history]);

    return (
        <>
            <SubTitle className="mobile-visiable" property={pathname.includes('/complete') ? 'see' : ''}>
                <div>
                    <p>주문이 완료 되었습니다</p>
                </div>
            </SubTitle>
            <PayWrap>
                <PaymentNav path={pathname} />

                <div style={{ position: 'relative', width: '100%', height: 'fit-content' }}>
                    <Outlet />
                    <BottomNotice />
                </div>
                {!pathname.includes('/complete') && (
                    <CartListWrap>
                        <p>주문요약</p>
                        <CartList heigth="545px" payment={true} />
                        <CardPrice>
                            <CartPrice />
                        </CardPrice>
                    </CartListWrap>
                )}
            </PayWrap>
        </>
    );
};

export default PaymentLayout;

const PayWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 120px var(--wrapper-padding);

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 140px;

    @media screen and (max-width: 700px) {
        display: block;

        padding-top: 20px;
    }
`;

export const CartListWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 406px;

    @media screen and (max-width: 700px) {
        display: none;
    }
`;

const SubTitle = styled.div`
    position: relative;
    width: 100%;
    height: 150px;
    background-image: url('/images/payment-title.png');
    background-size: cover;
    background-position: center;

    div {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: grid;
        align-items: center;

        text-align: center;

        color: white;
    }

    p {
        font-weight: 600;
    }

    margin-bottom: -50px;

    display: none;

    @media screen and (max-width: 700px) {
        display: ${(props) => (props.property ? 'block' : 'none')};
    }
`;
