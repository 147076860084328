import { createSlice } from '@reduxjs/toolkit';

const totalPriceSlice = createSlice({
    name: 'price',
    initialState: { price: 0, total: 0, couponName: '', couponPrice: 0 },
    reducers: {
        initialPrice(state, action) {
            state.price = action.payload;
            state.total = action.payload;
        },
        increasePrice(state, action) {
            state.total = state.price - action.payload;
        },
        decreasePrice(state, action) {
            state.total = state.price - action.payload;
        },
        deletePrice(state, action) {
            state.price = 0;
            state.total = 0;
            state.couponName = '';
            state.couponPrice = 0;
        },
        useCouponName(state, action) {
            state.couponName = action.payload;
        },
        useCouponPrice(state, action) {
            state.couponPrice = action.payload;
        },
        unUseCoupon(state, action) {
            state.couponName = '';
            state.couponPrice = 0;
        },
    },
});

export let { initialPrice, increasePrice, decreasePrice, deletePrice, useCouponName, useCouponPrice, unUseCoupon } =
    totalPriceSlice.actions;

export default totalPriceSlice.reducer;
