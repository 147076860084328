import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeAdmin } from '../../store/AdminSlice';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const AHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const adminData = useSelector((state) => state.admin);

    const onLogout = async () => {
        dispatch(removeAdmin());
        adminData.id === 0 && navigate('/');
    };
    return (
        <HeaderWrap>
            <div className="logo">
                <img alt="logo" src="/images/logo.svg" />
            </div>
            <ul>
                {/* <li>
                    <a href="/admin/dashboard">메인</a>
                </li> */}
                <li>
                    <a href="/admin/option">옵션</a>
                </li>
                <li>
                    <a href="/admin/goods">제품</a>
                </li>
                <li>
                    <a href="/admin/coupon">쿠폰</a>
                </li>
                <li>
                    <a href="/admin/banners">배너</a>
                </li>
                <li>
                    <a href="/admin/member">관리자</a>
                </li>
                <li>
                    <a href="/admin/users">회원</a>
                </li>
                <li>
                    <a href="/admin/payment">결제</a>
                </li>
                {/* <li>
                    <a href="/admin/cancel">환불</a>
                </li> */}
                <li>
                    <a onClick={onLogout}>로그아웃</a>
                </li>
            </ul>
        </HeaderWrap>
    );
};

export default AHeader;

const HeaderWrap = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: fit-content;
    height: 100vh;
    z-index: 10;

    padding: 50px 20px;

    background-color: var(--light-color);

    .logo {
        position: relative;
        width: 120px;
        height: fit-content;

        margin-bottom: 20px;

        img {
            position: relative;
            width: 110px;
            height: auto;
            object-fit: contain;
        }
    }

    text-align: center;

    li {
        font-weight: 400;
        padding: 5px 0;
    }
`;
