import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { initialPrice } from "../../store/PaymentPriceSlice";

import formPrice from "../../const/formPrice";
import { FlexDiv } from "./Cart";

const CartPrice = () => {
  let cartList = useSelector((state) => state.cart);
  let price = useSelector((state) => state.price);
  let dispatch = useDispatch();

  const [shipPrice, setShipPrice] = useState(3000);

  let totalPrice = 0;

  cartList[0] && cartList.filter((v) => (totalPrice += v.price * v.count));

  useEffect(() => {
    if (totalPrice < 30000) {
      dispatch(initialPrice(totalPrice + shipPrice));
    } else {
      setShipPrice(0);
      dispatch(initialPrice(totalPrice - shipPrice));
    }
  }, [totalPrice, shipPrice]);

  return (
    <>
      <FlexDiv>
        <small>주문금액</small>
        <small>
          ₩&nbsp;
          {formPrice(totalPrice) || 0}
        </small>
      </FlexDiv>
      {price.couponName && (
        <FlexDiv>
          <small>{price.couponName}</small>
          <small>{price.couponPrice}</small>
        </FlexDiv>
      )}
      <FlexDiv>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <small>배송비</small>
          <FreeDiv>3만 원 이상 구매 시 무료</FreeDiv>
        </div>
        <small>
          ₩&nbsp;
          {formPrice(shipPrice) || 0}
        </small>
      </FlexDiv>
      <FlexDiv>
        <p>총 주문금액</p>
        <p>
          ₩&nbsp;
          {totalPrice > 0 ? formPrice(price.total) : 0}
        </p>
      </FlexDiv>
    </>
  );
};

export default CartPrice;

export const FreeDiv = styled.small`
  position: relative;
  width: fit-content;
  height: fit-content;
  padding: 2px 5px;
  background-color: var(--main-color);
  color: white;

  font-size: var(--font-size-xs) !important;
  font-weight: 500;
  border-radius: 3px;
`;
