import styled from 'styled-components';

export const LoadingDiv = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;

    background-color: white;
    z-index: 52;

    display: grid;
    align-items: center;

    .img {
        position: relative;
        width: fit-content;
        height: auto;
        margin: 0 auto;
        text-align: center;

        p {
            margin-top: 10px;
        }
    }
`;
