import React from "react";

import { LegalNotice } from "./YContentsText";

//쿠키 정책
//제목 - <h2>, 소제목 - <h4>, 내용 - <p>
//조항 있을 경우(숫자로 구분) - <ol>, 조항 안에 조항 있을 경우(알파벳으로 구분) - <ool>
//쿠키와 관련된 개인정보처리(=마지막 문단) 내용에 젠틀몬스터 적혀있어여 ㅋㅋㅋ

const YContentsText2 = () => {
  return (
    <LegalNotice>
      <h2>쿠키 정책</h2>
      <br />

      <h4>쿠키 관리</h4>
      <p>
        이 섹션에서는 competoseoul.com의 쿠키 관리 정책에 대해 설명합니다. 이는
        귀하가 당사의 웹사이트를 방문할 때 처리되는 브라우징 정보의 출처와 사용
        및 귀하의 권리를 알리기 위한 것입니다.
      </p>
      <br />

      <h4>쿠키란?</h4>
      <p>
        쿠키란, 귀하가 당사의 웹사이트를 방문하여 이용한 환경 설정 및 기타
        정보를 담고 있는 작은 데이터&#40;텍스트 파일&#41;로 귀하의 선택에 따라
        귀하의 단말기&#40;PC, 스마트폰, 태플릿 등&#41;에 저장될 수 있습니다.
        쿠키가 유효하거나 저장되는 동안, competoseoul은 쿠키를 사용하여 다음
        방문 시 귀하의 단말기를 식별할 수 있으며 이를 토대로 귀하가 더 나은
        사용자 경험을 할 수 있도록 지원하고 당사 사이트 및 서비스의 품질을
        향상시키기 위해 노력합니다.
      </p>
      <br />

      <h4>COMPETOSEOUL.COM에서 쿠키를 사용하는 이유</h4>
      <p>
        <ol>
          <li>
            귀하가 쇼핑을 계속하거나 사이트의 다른 페이지로 이동 시 장바구니에
            선택한 제품들을 저장하고, 당사 사이트의 보안페이지를 접속할 수 있게
            하는 등 귀하가 당사 사이트의 필수적인 기능들을 이용할 수 있도록
            합니다. 만일 필수 쿠키를 거부하는 경우, 귀하는 당사 사이트를
            쇼핑목적으로 이용할 수 없으며 오직 브라우징만 가능합니다.
          </li>
          <li>
            귀하가 당사 사이트를 어떻게 이용하는지 정보를 수집하여 당사 사이트와
            서비스의 품질을 향상시킬 수 있게 합니다. 귀하의 사이트 이용 정보와
            환경 설정 정보는 구체적으로 다음과 같은 목적을 위하여 활용됩니다.
            <ul className="ul">
              <li>당사 사이트 이용 현황에 대한 통계 제공</li>
              <li>
                귀하가 어디에서 클릭하고 어떤 사이트로부터 당사 사이트에
                방문하게 되었는지를 파악하여 광고 효과를 확인
              </li>
              <li>
                당사가 선정한 제 3자 협력사에게도 당사 방문자가 협력사
                웹사이트를 방문하였다는 피드백 제공&#40;귀하가 구매한 제품에
                대한 상세 내용 포함&#41;
              </li>
              <li>
                귀하의 브라우징 도중 발생하는 오류들을 측정하여 사이트를 개선할
                수 있도록 함
              </li>
              <li>
                귀하의 과거 방문 여부 및 귀하가 어떤 환경설정을 적용하였는지를
                기억하여 귀하의 향후 당사 홈페이지 방문 환경을 향상시키고자
                합니다. 또한 당사는 귀하가 다음 브라우징 시 사이트로 복귀하고자
                하는 경우 귀하의 장바구니 품목들을 복구할 수 있습니다. 이러한
                쿠키는 당사 사이트에서 서비스 제공을 위하여 협력사와 정보를
                공유할 수 있습니다. 공유된 정보는 오직 서비스와 제품, 또는 기능
                제공의 목적으로만 사용되며 그 외 다른 목적으로 쓰이지 않습니다.
              </li>
              <li>
                귀하의 관심에 따라 귀하 개개인에게 맞춤 브라우징 경험을 제공할
                수 있도록 합니다. 이러한 맞춤 브라우징 경험은 제 3자가 제공하는
                서비스로 링크되어 있을 수 있는데, 이들 제3자는 귀하가 당사
                사이트를 방문 하였는지를 인식하기 위하여 이 서비스를 제공합니다.
                이러한 정보는 귀하가 관심을 가질 만한 제품과 서비스에 대한
                정보를 귀하에게 알리는데 사용됩니다. 또한 쿠키는 페이스북과 같은
                소셜네트워크와 링크되어 있거나 광고대행업체에 귀하의 방문 정보를
                제공하여 귀하가 관심을 가질만한 콤페토 제품과 서비스의 광고를
                게시할 수 있도록 합니다.
              </li>
            </ul>
          </li>
        </ol>
      </p>
      <br />

      <h4>쿠키와 관련된 개인정보처리</h4>
      <p>
        쿠키를 이용하여 포착된 모든 정보가 귀하를 식별하는 것은 아니지만 이
        정보를 통하여 귀하를 식별할 수 있게 되어 개인정보에 해당하는 경우,
        competoseoul은 개인정보처리방침에 따라 귀하의 정보를 행태정보로서
        수집∙이용합니다.
      </p>
      <br />
    </LegalNotice>
  );
};
export default YContentsText2;
