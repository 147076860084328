import React, { useState } from 'react';

import styled from 'styled-components';
import sortList from '../../const/SortList';

const CustomSelect = ({ sort, setSort }) => {
    const [show, setShow] = useState(false);

    const handleOnChangeSelectValue = (e) => {
        setSort({
            id: e.target.getAttribute('value'),
            name: e.target.getAttribute('name'),
        });
    };
    return (
        <SelectBox onClick={() => setShow((prev) => !prev)}>
            <Label>{sort.name}</Label>
            <SelectOptions show={show.toString()}>
                {sortList.map((v, i) => (
                    <Option
                        value={v.id}
                        name={v.name}
                        key={i}
                        color={sort.name === v.name ? 'var(--main-color)' : 'var(--light-color)'}
                        onClick={handleOnChangeSelectValue}
                    >
                        {v.name}
                    </Option>
                ))}
            </SelectOptions>
        </SelectBox>
    );
};

export default CustomSelect;

const SelectBox = styled.div`
    position: relative;
    width: fit-content;
    height: 100%;

    display: flex;
    align-items: center;
    gap: 10px;

    padding: 0 20px;
    cursor: pointer;
    z-index: 1;

    text-align: center;
    font-size: var(--font-size-sm);
    font-weight: 600;

    &::before {
        content: '';
        display: block;
        height: 25px;
        width: 25px;
        background-size: 25px 25px;
        background-image: url(/images/icon_solt.svg);
        background-repeat: no-repeat;
    }

    @media screen and (max-width: 700px) {
        gap: 5px;

        padding: 0 10px;

        &::before {
            height: 20px;
            width: 20px;
            background-size: 20px 20px;
        }
    }
`;

const Label = styled.label`
  font-size: font-size: var(--font-size-sm);
  font-weight: 600;
  text-align: center;

  margin-left: 4px;

  cursor: pointer;
`;

const SelectOptions = styled.ul`
    position: absolute;
    transition: all 0.3s ease-in;

    list-style: none;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 120px;
    max-height: ${(props) => (props.show === 'true' ? 'none' : '0')};
    display: ${(props) => (props.show === 'true' ? 'grid' : 'none')};
    padding: 0;
    text-align: center;
    background-color: white;

    border: 1px solid var(--main-color);

    @media screen and (max-width: 700px) {
        height: 110px;
    }
`;

const Option = styled.li`
    font-size: var(--font-size-sm);
    font-weight: 600;

    padding: 6px 8px;
    transition: color 0.2s ease-in;

    color: ${(props) => props.color};

    &:hover {
        color: var(--main-color);
    }
`;
