import React from 'react';
import { useState } from 'react';

import styled from 'styled-components';

const LoginInput = ({ type, data, set }) => {
    const [display, setDisplay] = useState(false);
    const [checkEmail, setCheckEmail] = useState(true);
    return (
        <InputWrap>
            <Input
                type={type === 'password' && !display ? 'password' : 'text'}
                id={type}
                className={type}
                value={data}
                autoComplete={'off'}
                placeholder={type === 'password' ? '비밀번호' : '아이디(이메일)'}
                onChange={(e) =>
                    set(
                        e.target.value.includes('@') && e.target.value.includes('.')
                            ? setCheckEmail(true)
                            : setCheckEmail(false)
                    )
                }
            ></Input>
            {type === 'password' && (
                <IconWrap onClick={() => setDisplay(!display)}>
                    <img alt="logo" src={display ? '/images/icon_display.svg' : '/images/icon_hidden.svg'} />
                </IconWrap>
            )}
            {type === 'username' && !checkEmail && (
                <WarningText>
                    <img alt="icon" src="/images/icon_warning.svg" />
                    이메일 형식에 맞게 입력해 주세요
                </WarningText>
            )}
        </InputWrap>
    );
};

export default LoginInput;

export const LoginWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 80vh;

    max-width: 406px;

    padding: 120px 0;
    margin: 0 auto;

    @media screen and (max-width: 700px) {
        padding: 40px var(--wrapper-padding);
        max-width: 690px;
    }
`;

export const SignupText = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 80px;

    small {
        font-size: var(--font-size-sm);
        font-weight: 500;
    }
`;

const InputWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
`;

export const Input = styled.input`
    position: relative;
    font-weight: 500;
    width: 100%;
    height: 45px;
    border: 1px solid var(--main-color);
    padding: 0 10px;

    background-color: ${(props) => (props.disabled ? '#EBEBEB' : 'rgba(0,0,0,0')};
`;

export const IconWrap = styled.div`
    position: absolute;
    display: grid;
    width: fit-content;
    height: 100%;
    align-items: center;
    top: 0;
    right: 10px;
`;

export const WarningText = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: var(--font-size-xs);
    font-weight: 500;
    margin: 11px 0;

    img {
        position: relative;
        width: 12px;
        height: auto;
    }
`;

export const LoginLinkTag = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 40px;

    font-size: var(--font-size-xs);

    font-weight: 500;

    small {
        font-size: var(--font-size-xs);
        font-weight: 500;
    }

    @media screen and (max-width: 700px) {
        .small-text {
            font-size: 7.5pt;
        }
    }
`;
