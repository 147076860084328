import React, { useState } from 'react';

import styled from 'styled-components';

import { Input, WarningText, IconWrap } from './LoginInput';

const SignInput = ({ type, value, set, disabled }) => {
    const [display, setDisplay] = useState(false);
    const [checkEmail, setCheckEmail] = useState(true);

    let typeForPlaceholder = '';

    switch (type) {
        case 'email':
            typeForPlaceholder = '이메일 입력';
            break;
        case 'password':
            typeForPlaceholder = '8자리 영어, 숫자, 특수문자 입력 가능';
            break;
        case 'checkPassword':
            typeForPlaceholder = '비밀번호를 한번 더 입력';
            break;
        default:
            typeForPlaceholder = '';
            break;
    }

    return (
        <InputWrap>
            <Input
                type={(type === 'password' || type === 'checkPassword') && !display ? 'password' : 'text'}
                id={type}
                className={type}
                value={value}
                disabled={disabled}
                placeholder={typeForPlaceholder}
                onChange={(e) => {
                    e.target.value.includes('@') && e.target.value.includes('.')
                        ? setCheckEmail(true)
                        : setCheckEmail(false);

                    set(e.target.value);
                }}
            ></Input>
            {(type === 'password' || type === 'checkPassword') && (
                <IconWrap onClick={() => setDisplay(!display)}>
                    <img alt="logo" src={display ? '/images/icon_display.svg' : '/images/icon_hidden.svg'} />
                </IconWrap>
            )}
            {type === 'email' && !checkEmail && (
                <WarningText>
                    <img alt="icon" src="/images/icon_warning.svg" />
                    이메일 형식에 맞게 입력해 주세요
                </WarningText>
            )}
        </InputWrap>
    );
};

export default SignInput;

export const SignSelect = ({ value, set }) => {
    return (
        <InputWrap>
            <Selected value={value} onChange={(e) => set(e.target.value)}>
                <option className="ligthText" value={0}>
                    성별을 선택해주세요.
                </option>
                <option value={1}>남자</option>
                <option value={2}>여자</option>
            </Selected>
        </InputWrap>
    );
};

export const BirthSelected = ({ type, value, set }) => {
    const now = new Date();

    let years = [];

    for (let y = now.getFullYear(); y >= 1930; y -= 1) {
        years.push(y.toString());
    }

    let month = [];
    for (let m = 1; m <= 12; m += 1) {
        if (m < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 월에 0을 붙혀준다
            month.push('0' + m.toString());
        } else {
            month.push(m.toString());
        }
    }

    let days = [];
    let date = new Date(value.year, value.month, 0).getDate();
    for (let d = 1; d <= date; d += 1) {
        if (d < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 일에 0을 붙혀준다
            days.push('0' + d.toString());
        } else {
            days.push(d.toString());
        }
    }

    return (
        <BirthWrap>
            <Selected value={value.year} onChange={(e) => set({ ...value, year: e.target.value })}>
                {years.map((item) => (
                    <option value={item} key={item}>
                        {item}
                    </option>
                ))}
            </Selected>
            <Selected value={value.month} onChange={(e) => set({ ...value, month: e.target.value })}>
                {month.map((item) => (
                    <option value={item} key={item}>
                        {item}
                    </option>
                ))}
            </Selected>
            <Selected value={value.day} onChange={(e) => set({ ...value, day: e.target.value })}>
                {days.map((item) => (
                    <option value={item} key={item}>
                        {item}
                    </option>
                ))}
            </Selected>
        </BirthWrap>
    );
};

export const SignWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 40px 0;

    p {
        font-size: var(--font-size-xs) !important;
        font-weight: 500;
        margin-bottom: 10px;
    }

    input[type='text'],
    input[type='password'] {
        position: relative;
        font-weight: 500;
        width: 100%;
        height: 45px;
        border: 1px solid var(--main-color);
        padding: 0 10px;
    }

    @media screen and (max-width: 700px) {
        padding: 20px 0;

        p {
            margin-bottom: 5px;
        }

        input[type='text'],
        input[type='password'] {
            height: 40px;
            font-size: var(--font-size-sm);
        }
    }
`;

const BirthWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
`;

export const InputWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
`;

const Selected = styled.select`
    position: relative;
    width: 100%;
    height: 45px;
    border: 1px solid var(--main-color);
    padding: 0 10px;

    font-weight: 500;

    option {
        font-weight: 500;
        color: var(--main-color);
    }
`;

export const CheckPasswordWrap = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    padding-top: 12px;
    padding-bottom: 22px;
`;

export const CheckPassword = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    gap: 5px;

    color: ${(props) => (props.property === 'true' ? 'var(--main-color)' : 'var(--light-color)')};
`;
