import React, { useState } from "react";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import styled from "styled-components";

import AddcartBtn from "./AddcartBtn";
import Reviews from "../goods/Reviews";
import Recommended from "../goods/Recommended";

const SubDetail = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [count, setCount] = useState(1);
  const [addGoods, setAddGoods] = useState(Array);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    data && (
      <>
        <WrapDiv>
          <div className="mobile-hidden">
            {Array.isArray(data.goodsImgDtos) &&
            data.goodsImgDtos.length === 0 ? (
              <div
                className="mobile-hidden"
                style={{ width: "100%", height: "100%" }}
              >
                <img
                  alt="icon"
                  src={process.env.REACT_APP_DOMAIN + data.thumb_src}
                  width="100%"
                />
                <img
                  alt="icon"
                  src={process.env.REACT_APP_DOMAIN + data.thumb_src}
                  width="100%"
                />
              </div>
            ) : (
              data.goodsImgDtos.map((v, i) => (
                <img
                  key={i}
                  alt="icon"
                  src={process.env.REACT_APP_DOMAIN + v.src}
                  width="100%"
                />
              ))
            )}
          </div>
          <div className="mobile-visible">
            <Swiper
              pagination={{
                type: "progressbar",
              }}
              // navigation={true}
              // scrollbar={true}
              loop={true}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data.goodsImgDtos.map((v, i) => (
                <SwiperSlide key={i}>
                  <img
                    alt="icon"
                    src={process.env.REACT_APP_DOMAIN + v.src}
                    width="100%"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <StickyDiv>
            <p className="title">{data.goodsName}</p>

            <FlexDiv>
              <CountDiv>
                <button
                  onClick={() => (count > 1 ? setCount(count - 1) : null)}
                >
                  -
                </button>
                <p>{count}</p>
                <button onClick={() => setCount(count + 1)}>+</button>
              </CountDiv>
              <p>
                ₩&nbsp;
                {(count * data.price)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
            </FlexDiv>

            <DataContetns>
              <p>{data.contents}</p>
            </DataContetns>

            {data.optCategoryDtos.length > 0 &&
              data.optCategoryDtos.map((v, i) => (
                <OptionDiv property={addGoods} key={i}>
                  <p>{v.categoryName}</p>
                  <select
                    name="opt"
                    onChange={(e) => setAddGoods(...e.target.value)}
                  >
                    <option value={0}>추가상품을 선택해주세요</option>
                    {v.opts.map((d, j) => (
                      <option key={j} value={d.price}>
                        {d.optName}
                      </option>
                    ))}
                  </select>
                </OptionDiv>
              ))}

            <ButtonDiv>
              <AddcartBtn data={data} count={count} />
              <StyleButton onClick={() => alert("준비중입니다.")}>
                구매하기
              </StyleButton>
              <StyleButton merge="y" onClick={() => alert("준비중입니다.")}>
                <img
                  className="naverBtn"
                  alt="button"
                  src="/images/logo_npay.svg"
                />
              </StyleButton>
            </ButtonDiv>
            <DropAndDrop>
              <DetailInfoDiv
                className={`accordion-item ${
                  activeIndex === 0 ? "opened" : "closed"
                }`}
                property="130px"
                onClick={() => handleToggle(0)}
              >
                <TitleButton>
                  <small>제품 세부 정보</small>
                  <img
                    alt="plus"
                    className="plus"
                    src="/images/icon_plus.svg"
                    width={19}
                  />
                  <img
                    alt="min"
                    className="min"
                    src="/images/icon_minus.svg"
                    width={19}
                    style={{ display: "none" }}
                  />
                </TitleButton>
                <DetailContentDiv className="contents">
                  <small>
                    가죽: 소가죽 100%(이탈리아)
                    <br />
                    부자재: 니켈사틴(대한민국)
                    <br />
                    제조자: COMPETO
                    <br />
                    제조국: KOREA
                    <br />
                    크기: L30.9 × H32 × W13.9cm
                  </small>
                </DetailContentDiv>
              </DetailInfoDiv>
              <DetailInfoDiv
                className={`accordion-item ${
                  activeIndex === 1 ? "opened" : "closed"
                }`}
                onClick={() => handleToggle(1)}
              >
                <TitleButton>
                  <small>배송＆선물 포장 서비스 혜택</small>
                  <img
                    alt="plus"
                    className="plus"
                    src="/images/icon_plus.svg"
                    width={19}
                  />
                  <img
                    alt="min"
                    className="min"
                    src="/images/icon_minus.svg"
                    width={19}
                    style={{ display: "none" }}
                  />
                </TitleButton>
                <DetailContentDiv className="contents">
                  <small>
                    콤페토의 모든 제품은 100% 핸드메이드 제품입니다.
                    <br />
                    영업일 기준 1~3일의 제작 기간이 소요됩니다.
                    <br />
                    <br />
                    온라인 고객님들께 블랙 진 파우치 서비스를 제공하고 있습니다.
                    <br />
                    고객님을 위한 특별한 서비스를 경험해 보세요.
                    <br />
                  </small>
                  <img alt="img" src="/images/info.png" />
                </DetailContentDiv>
              </DetailInfoDiv>
              <DetailInfoDiv
                className={`accordion-item ${
                  activeIndex === 2 ? "opened" : "closed"
                }`}
                onClick={() => handleToggle(2)}
                property="80px"
              >
                <TitleButton>
                  <small>우리가 사랑한 블랙</small>
                  <img
                    alt="plus"
                    className="plus"
                    src="/images/icon_plus.svg"
                    width={19}
                  />
                  <img
                    alt="min"
                    className="min"
                    src="/images/icon_minus.svg"
                    width={19}
                    style={{ display: "none" }}
                  />
                </TitleButton>
                <DetailContentDiv className="contents">
                  <small>
                    콤페토의 모든 상품 색상은 검정색으로 구성되어 있습니다.{" "}
                    <br />
                    Our Love for Black.
                  </small>
                </DetailContentDiv>
              </DetailInfoDiv>
            </DropAndDrop>
          </StickyDiv>
        </WrapDiv>
        <Recommended />
        {data.reviewDtos.length > 0 && <Reviews data={data} />}
      </>
    )
  );
};

export default SubDetail;

const WrapDiv = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "100%")};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  .mySwiper {
    position: relative !important;
    width: 100vw;
    height: 600px;

    img {
      width: 100%;
      height: 600px;
      object-fit: cover;
    }

    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      background: var(--main-color);
    }

    .swiper-pagination-progressbar {
      top: auto;
      bottom: 0;
    }
  }
`;
const ButtonDiv = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "100%")};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media screen and (max-width: 700px) {
    padding-top: 20px;

    button {
      font-weight: 600;
    }
  }
`;

const StickyDiv = styled.div`
    position: sticky;
    top: 160px;
    left: 0;
    width: 100%;
    height: fit-content;
    max-width: 573px;
    margin: 0 auto;

    .title {
        margin-bottom: 22px;
    }

    @media screen and (max-width: 1200px) {
      position: relative
      top: 0;
      padding: 0 var(--wrapper-padding);
      margin-top: 30px;
      margin-left: 0;

      max-width: 100vw;

      .title {
          margin-bottom: 18px;
          font-size: 14pt;
      }

      p {
          font-size: var(--font-size-sm);
          font-weight: 600;
      }
    }

`;

const FlexDiv = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;

  @media screen and (max-width: 700px) {
    margin-bottom: 20px;
  }
`;

export const CountDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;

  &.absoluteBtn {
    position: absolute;
    bottom: 5px;
  }

  @media screen and () {
    gap: 20px;
  }
`;

export const StyleButton = styled.button`
  position: relative;
  width: 100%;
  height: 45px;
  display: grid;
  align-items: center;
  text-align: center;
  font-size: var(--font-size-sm);
  border-radius: 3px;

  img {
    margin: 0 auto;
  }

  .naverBtn {
    position: relative;
    width: auto;
    height: 100%;
    max-height: 15px;
    margin: 0 auto;
  }

  @media screen and (max-width: 700px) {
    .naverBtn {
      width: 100%;
    }
  }

  border: 1px solid var(--main-color);
  background: ${(props) => (props.bg ? props.bg : "var(--main-color)")};
  color: ${(props) => (props.bg ? "var(--main-color)" : "white")};

  ${(props) => (props.merge ? "grid-column-start: 1" : "")};
  ${(props) => (props.merge ? "grid-column-end: 3" : "")};
`;

const DropAndDrop = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  margin-top: 80px;

  border-bottom: 1px solid var(--main-color);

  small {
    font-weight: 500;
  }

  @media screen and (max-width: 700px) {
    margin-top: 40px;
  }
`;

const DetailInfoDiv = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  border-top: 1px solid var(--main-color);
  padding: 0 5px;

  .contents {
    max-height: 0px;
    transition: all 0.6s;
    -webkit-transition: all 0.6s;
  }

  &.opened {
    .contents {
      height: fit-content;
      max-height: ${(props) => (props.property ? props.property : "600px")};

      padding-bottom: 20px;
    }
    .min {
      display: block !important;
    }
    .plus {
      display: none;
    }
  }
  &.closed {
    .contents {
      max-height: 0px;
    }

    .min {
      display: none;
    }
    .plus {
      display: block !important;
    }
  }

  @media screen and (max-width: 1200px) {
    &.opened {
      .contents {
        height: fit-content;
        max-height: ${(props) => (props.property ? props.property : "800px")};

        padding-bottom: 20px;
      }
    }
  }
`;

const TitleButton = styled.button`
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DetailContentDiv = styled.div`
  position: relative;
  max-height: 0;
  overflow: hidden;

  transition: all 0.3s ease-in-out;

  img {
    position: relative;
    width: auto;
    height: 300px;
    object-fit: contain;
    margin-top: 10px;
  }
`;

const DataContetns = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  padding-bottom: 40px;

  p {
    font-size: var(--font-size-sm);
    font-weight: 500;
    line-height: 1.5em;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: 20px;
  }
`;

const OptionDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  p {
    font-size: var(--font-size-xs);
    font-weight: 500;
  }

  margin-bottom: 40px;

  select {
    width: 100%;
    height: 34px;
    margin-top: 5px;

    font-size: var(--font-size-xs);
    font-weight: 500;

    color: ${(property) =>
      property.property > 0 ? "var(--main-color)" : "var(--light-color)"};

    padding: 0 5px;
  }

  option {
    color: var(--main-color);
  }

  @media screen and (max-width: 700px) {
    margin-bottom: 10px;
  }
`;
