import styled from 'styled-components';

export const AdminGoodsDiv = styled.div`
    h5 {
        margin-bottom: 30px;
    }

    input,
    select {
        position: relative;
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        padding: 10px;
    }

    textarea {
        position: relative;
        width: 100%;
        height: 80px;
        margin-bottom: 20px;
        resize: none;
        padding: 10px;
    }

    button {
        position: relative;
        width: 100%;
        height: fit-content;
        background-color: var(--main-color);
        color: white;
        text-align: center;
        padding: 15px 0;
        border-radius: 5px;
        font-size: var(--font-size-sm);
    }

    .preview-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        margin-bottom: 20px;
    }

    .preview {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 400px;
        object-fit: cover;
        border-radius: 10px;
    }

    .flex-input {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        div {
            position: relative;
            width: 100%;
            height: 100%;

            label {
                position: relative;
                display: block;
                width: 100%;
                height: fit-content;
                border: 1px solid var(--main-color);
                border-radius: 3px;
                padding: 2px;
                background-color: #eee;

                cursor: pointer;

                p {
                    font-size: smaller;
                }
            }

            input {
                border: none;
            }
        }
    }

    .check-wrap {
        position: relative;
        width: 100%;
        height: 40px;
        display: flex;
        gap: 20px;
    }

    .check {
        position: relative;
        display: flex;
        gap: 5px;

        input {
            width: 12px;
            height: 12px;
            margin-top: 1px;
            z-index: -1;
        }

        label {
            font-size: small;
        }
    }

    @media screen and (max-width: 700px) {
        .preview-wrap,
        .flex-input {
            grid-template-columns: repeat(2, 1fr);
        }

        .preview {
            max-height: 200px;
        }
    }

    @media (min-width: 501px) and (max-width: 1500px) {
        .preview {
            max-height: 300px;
        }
    }
`;

export const AdminGoodsListDiv = styled.div`
    .one-goods {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }
`;

export const AdminListDiv = styled.div`
    p {
        font-size: medium;
        font-weight: 500;
        text-align: center;
    }

    .admin-card {
        position: relative;
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 1fr 1fr 2fr 2fr 2fr 0.5fr;
        border-top: 1px solid var(--light-color);
        border-bottom: 1px solid var(--light-color);
        padding: 15px;

        gap: 10px;

        p {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .admin-goods-card {
        position: relative;
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 0.4fr 1.4fr 0.8fr 0.4fr 0.4fr 0.4fr 0.8fr 0.4fr;
        border-top: 1px solid var(--light-color);
        border-bottom: 1px solid var(--light-color);
        padding: 15px;

        gap: 10px;

        p {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .admin-opt-card {
        position: relative;
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 0.5fr 1fr 1.5fr 1fr;
        border-top: 1px solid var(--light-color);
        border-bottom: 1px solid var(--light-color);
        padding: 15px;

        gap: 10px;

        p {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .admin-opt-inner {
        position: relative;
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 0.5fr 1fr 1.5fr 1fr;
        border-bottom: 1px solid var(--light-color);
        padding: 15px;
    }

    .admin-payment {
        position: relative;
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 1.4fr 0.4fr 0.4fr 0.4fr 0.4fr 1.4fr;
        border-bottom: 1px solid var(--light-color);
        padding: 15px;
        gap: 10px;

        p {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .admin-user-payment {
        position: relative;
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 1.2fr 1.2fr 0.5fr 0.4fr 0.5fr 0.5fr;
        border-bottom: 1px solid var(--light-color);
        padding: 15px;
        gap: 10px;

        p {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: small;
        }
    }

    .admin-banner {
        position: relative;
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 0.3fr 1fr 1fr 1fr;
        border-bottom: 1px solid var(--light-color);
        padding: 15px;

        gap: 10px;

        p {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .red {
        color: red;
    }
`;

export const AdminPostDiv = styled.div`
    .br {
        height: 20px;
    }

    position: relative;
    width: 100%;
    height: 100%;

    p {
        font-size: var(--font-size-xs);
        margin-bottom: 10px;
    }

    input[type='radio'] {
        position: relative;
        width: 14px;
        height: 14px;
        vertical-align: middle;

        appearance: none;

        transition: border 0.2s ease-in-out;
        border: 1px solid (--main-color);
        border-radius: 50%;
    }

    input[type='radio']:checked {
        background-color: var(--main-color);
        border: 3px solid white;
        box-shadow: 0 0 0 1px var(--main-color);
    }

    label {
        postition: relative;
        width: 100%;
        font-size: var(--font-size-sm);
        margin: 0;

        padding-left: 5px;
        padding-right: 10px;

        cursor: pointer;
    }
`;

export const AdminUserListDiv = styled.div`
    .br {
        height: 20px;
    }

    .user-card {
        position: relative;
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 0.5fr 2fr 1fr 1fr 0.5fr 0.5fr 0.5fr;
        border-top: 1px solid var(--light-color);
        border-bottom: 1px solid var(--light-color);
        padding: 15px;

        text-align: center;

        gap: 10px;

        p {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: medium;
            font-weight: 500;
        }
    }
`;
