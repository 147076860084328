import React from 'react';
import { Helmet } from 'react-helmet-async';

export const MainSeo = () => {
    return (
        <Helmet>
            <meta property="og:site_name" content="competo" />
            <meta property="og:title" content="competo" />
            <meta property="og:url" content="" />
            <meta property="og:description" content="" />
        </Helmet>
    );
};

export const AboutSeo = () => {
    return (
        <Helmet>
            <meta property="og:site_name" content="competo" />
            <meta property="og:title" content="competo - about" />
            <meta property="og:url" content="" />
            <meta property="og:description" content="" />
        </Helmet>
    );
};
